import { lazy } from 'react';

// project imports
import MainLayout from 'layout/ProfileLayout';
import GeoFence from 'layout/MainLayout/Geofence';
import AddGeoFence from 'layout/MainLayout/add-geofence';
import Loadable from 'ui-component/Loadable';
import {RequireAuth} from "utils/helpers"

const Dashboard = Loadable(lazy(() => import('views/pages/dashboard')));
const GeoLocation = Loadable(lazy(() => import('views/pages/geo-location/geolocation')));
const DriveSafe = Loadable(lazy(() => import('views/pages/drive-safe')));
const WebFiltering = Loadable(lazy(()=> import('views/pages/web-filtering')))
const CallSms = Loadable(lazy(() => import('views/pages/call-sms')));
const FamilyMap = Loadable(lazy(() => import('views/pages/family-safe')));
const AppFilter = Loadable(lazy(() => import('views/pages/app-filtering')));
const TimeSchedulor  = Loadable(lazy(()=> import('views/pages/time-schedulor')))
const AppUsage  = Loadable(lazy(()=> import('views/pages/app-usage')))
const SamplePage = Loadable(lazy(() => import('views/sample-page')));
const Help = Loadable(lazy(() => import('views/pages/help')));
// const AuthChangePassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/changePassword')));
const AuthContactUs = Loadable(lazy(() => import('views/pages/authentication/authentication3/contactUs')));
const AuthEditProfile = Loadable(lazy(() => import('views/pages/authentication/authentication3/editProfile')));
const AuthVerification = Loadable(lazy(() => import('views/pages/authentication/authentication3/profileVerify')));
const AuthChangePassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/changePassword')));
const AuthProDevice = Loadable(lazy(() => import('views/pages/authentication/authentication3/proDeviceManage')));
const AuthUpgrade = Loadable(lazy(() => import('views/pages/authentication/authentication3/upgradepopup')));

const AuthParentDevice = Loadable(lazy(() => import('views/pages/authentication/authentication3/parentDevice')));
const InatallationManual = Loadable(lazy(() => import('views/pages/installation-manual')));
const InviteParent = Loadable(lazy(() => import('views/pages/authentication/authentication3/inviteparent')));


const RegisterDeviceManual= Loadable(lazy(() => import('views/pages/registration-device-manual')));
const FeatureDeviceManual= Loadable(lazy(() => import('views/pages/feature-device-manual')));
const AndroidFeatureManual= Loadable(lazy(() => import('views/pages/android-feature-manual')));
const IosFeatureManual= Loadable(lazy(() => import('views/pages/ios-feature-manual')));
const WindowsFeatureManual= Loadable(lazy(() => import('views/pages/windows-feature-manual')));
const MacFeatureManual= Loadable(lazy(() => import('views/pages/mac-feature-manual')));
const Mfa = Loadable(lazy(() => import('views/pages/mfa')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/user',
    element: <RequireAuth><MainLayout /></RequireAuth>,
    children: [
        
       
        
        {
            path: '/contact-us',
            element: <AuthContactUs />
        },
        {
            path: '/edit-parent-profile',
            element: <AuthEditProfile />
        },
        {
            path: '/edit-parent-profile/verify',
            element: <AuthVerification />
        },
        {
            path: '/change-password',
            element: <AuthChangePassword />
        },
        {
            path: '/device-manage',
            element: <AuthProDevice />
        },
        {
            path: '/upgrade-plan',
            element: <AuthUpgrade />
        },
        {
            path: '/parent-device',
            element: <AuthParentDevice />
        },
        {
            path: '/invite-parent',
            element: <InviteParent />
        },
        {
            path: '/user-manual',
            element: <Help />
        },
        {
            path: '/installation-manual',
            element: <InatallationManual />
        },

        {
            path: '/registration-manual',
            element: <RegisterDeviceManual />
        },
        {
            path: '/feature-device-manual',
            element: <FeatureDeviceManual />
        },
        {
            path: '/android-feature-manual',
            element: <AndroidFeatureManual />
        },
        {
            path: '/ios-feature-manual',
            element: <IosFeatureManual />
        },
        {
            path: '/windows-feature-manual',
            element: <WindowsFeatureManual />
        },
        {
            path: '/mac-feature-manual',
            element: <MacFeatureManual />
        },
        {
            path: '/mfa',
            element: <Mfa />
        },
    ]

};

export default MainRoutes;
