import {
  GET_WEB_LOGS,
  GET_WEB_LOGS_SUCCESS,
  GET_WEB_LOGS_FAILED,

  GET_CALL_LOGS,
  GET_CALL_LOGS_SUCCESS,
  GET_CALL_LOGS_FAILED,

  GET_BLOCKED_WEBSITES,
  GET_BLOCKED_WEBSITES_SUCCESS,
  GET_BLOCKED_WEBSITES_FAILED,

  GET_APPS,
  GET_APPS_SUCCESS,
  GET_APPS_FAILED,

  POST_BLOCK_WEBSITES,
  POST_BLOCK_WEBSITES_SUCCESS,
  POST_BLOCK_WEBSITES_FAILED,

  POST_BLOCK_APPS,
  POST_BLOCK_APPS_SUCCESS,
  POST_BLOCK_APPS_FAILED,

  FILTER_STATE_RESET_COMPLETED,

  FB_WEB_BLOCK,

  POST_WEBSITE_TO_BLOCK,
  POST_WEBSITE_TO_BLOCK_SUCCESS,
  POST_WEBSITE_TO_BLOCK_FAILED,

  CLEAR_APP_FILTER,
  CLEAR_APP_FILTER_SUCCESS,
  CLEAR_APP_FILTER_FAILED,
  

} from '../types';

import { getCall,postCall , deleteCall} from "./api";
import { database } from '../../firebase'
import moment from 'moment';

export const getWebLogs = (payload) => async (dispatch) => {
  dispatch({ type: GET_WEB_LOGS });
  getCall(`/web/logs?deviceId=${payload}`, true)
    .then((user) => {
      dispatch({ type: GET_WEB_LOGS_SUCCESS, payload: user.data });
    })
    .catch((err) => {
      dispatch({ type: GET_WEB_LOGS_FAILED, payload: err?.response?.data?.message });
    });
};


export const getCallLogs = (payload) => async (dispatch) => {
  dispatch({ type: GET_CALL_LOGS });
  getCall(`/contactlog?deviceId=${payload}`, true)
    .then((user) => {
      dispatch({ type: GET_CALL_LOGS_SUCCESS, payload: user.data });
    })
    .catch((err) => {
      dispatch({ type: GET_CALL_LOGS_FAILED, payload: err?.response?.data?.message });
    });
};

export const getBlockedWebsites = (payload) => async (dispatch) => {
  dispatch({ type: GET_BLOCKED_WEBSITES });
  getCall(`/web/blocked?deviceId=${payload}`, true)
    .then((user) => {
      dispatch({ type: GET_BLOCKED_WEBSITES_SUCCESS, payload: user.data });
    })
    .catch((err) => {
      dispatch({ type: GET_BLOCKED_WEBSITES_FAILED, payload: err?.response?.data?.message });
    });
};

export const getApps = (payload) => async (dispatch) => {
  dispatch({ type: GET_APPS });
  getCall(`/child/device/policy?id=${payload}`, true)
    .then((user) => {
      dispatch({ type: GET_APPS_SUCCESS, payload: user.data });
    })
    .catch((err) => {
      dispatch({ type: GET_APPS_FAILED, payload: err?.response?.data?.message });
    });
};

export const postBlockedSites = (payload) => async (dispatch) => {
  dispatch({ type: POST_BLOCK_WEBSITES });
  postCall("/child/device/policy", payload,true)
   .then((response) => {
     dispatch({ type: POST_BLOCK_WEBSITES_SUCCESS, payload: response?.data });
   })
   .catch((err) => {
     dispatch({ type: POST_BLOCK_WEBSITES_FAILED, payload:err?.response?.data?.message });
   });
};

export const postAppFilters = (payload) => async (dispatch) => {
  dispatch({ type: POST_BLOCK_APPS });
  postCall("/child/device/policy", payload,true)
   .then((response) => {
     dispatch({ type: POST_BLOCK_APPS_SUCCESS, payload: response?.data });
   })
   .catch((err) => {
     dispatch({ type: POST_BLOCK_APPS_FAILED, payload:err?.response?.data?.message });
   });
};

export const addWebsitestoBlock = (payload) => async (dispatch) => {
  dispatch({ type: POST_WEBSITE_TO_BLOCK });
  postCall("/child/device/policy", payload,true)
   .then((response) => {
     dispatch({ type: POST_WEBSITE_TO_BLOCK_SUCCESS, payload: response?.data });
   })
   .catch((err) => {
     dispatch({ type: POST_WEBSITE_TO_BLOCK_FAILED, payload:err?.response?.data?.message });
   });
};

export const clearAppFilter = (id) => (dispatch) => {
  dispatch({ type: CLEAR_APP_FILTER });
  deleteCall(`/child/device/policy?deviceId=${id}`,true).then((res) => {
      dispatch({ type: CLEAR_APP_FILTER_SUCCESS, payload: res.data.data }); 
  })
  .catch((err) => {
    dispatch({ type: CLEAR_APP_FILTER_FAILED });
  });

};

export const resetFilterState = () => async (dispatch) => {
  dispatch({ type: FILTER_STATE_RESET_COMPLETED });
};

export const fbWebBlock = (deviceId,type) => (dispatch) => {
  var m = moment();
  var ms = m.milliseconds() + 1000 * (m.seconds() + 60 * (m.minutes() + 60 * m.hours()));
  let set = type === 'web' ? 'webblock' : type === 'app' ? 'appblock' : type === 'call' ?  'callblock'  : type === 'ts' && 'timeschedule'
  let ref = database.ref(`/${deviceId}/${set}`);
  ref.set(ms.toString())
  ref.on('value', snapshot => {
    dispatch({ type: FB_WEB_BLOCK, payload: snapshot.val()})
  });
};