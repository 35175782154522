import PropTypes from 'prop-types';
import { useState, useRef, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import {
	Avatar, Box, ButtonBase,
	Chip,
	ClickAwayListener,
	Divider,
	Grid,
	Paper,
	Popper,
	Stack,
	Typography,
	useMediaQuery,
	List, ListItem, ListItemText,
	CircularProgress,
	Badge
} from '@mui/material';
import ProfileSection from './ProfileSection';
import { notificationListAction, profileDetailAction, deleteNotificationList, resetGeneralState, deleteNotificationListAll, loadChildrenAction } from 'store/actions';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import moment from "moment";
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import { IconBell } from '@tabler/icons';
import { IconMenu2 } from '@tabler/icons';
import logo from "assets/images/logo-land.png"
import { useSnackbar } from 'notistack';
import Checkbox from '@mui/material/Checkbox';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const Header = ({ handleLeftDrawerToggle, notificationListAction, loadChildrenAction, profileDetailAction, notiList, profileData, deleteNotificationList, resetGeneralState, isDeleted, deleteNotificationListAll, totalNotification, loading }) => {
	const theme = useTheme();
	const anchorRef = useRef(null);
	const { enqueueSnackbar } = useSnackbar();
	const userId = localStorage?.getItem("userId");
	const { userData } = useSelector((state) => state.childrenReducer);
	const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
	const [open, setOpen] = useState(false);
	const [options, setOptions] = useState(false);
	const [data, setData] = useState([]);
	const [notiOptions, setNotiOptions] = useState({ limit: 10, offset: 0 });

	let filteredData = data && data.filter((e) => e?.status === true)

	useEffect(() => {
		notificationListAction(`deviceId=${userId}&limit=${notiOptions.limit}&offset=${notiOptions.offset}`)
		profileDetailAction(userId)

		let interval = setInterval(() => {
			resetGeneralState()
			setNotiOptions({ limit: 10, offset: 0 })
			notificationListAction(`deviceId=${userId}&limit=${notiOptions.limit}&offset=${notiOptions.offset}`)
		}, 5 * 60000);

		return () => clearInterval(interval)
	}, [])

	useEffect(() => {
		if (notiList) {
			setData(notiList)
		}
	}, [notiList])

	useEffect(() => {
		if (isDeleted) {
			const userId = localStorage?.getItem("userId");
			enqueueSnackbar('Notification Cleared !', {
				variant: "success",
			});
			setOptions(false)
			resetGeneralState()
			notificationListAction(`deviceId=${userId}&limit=${notiOptions.limit}&offset=${notiOptions.offset}`)
		}
	}, [isDeleted])

	useEffect(() => {
		if (prevOpen.current === true && open === false) {
			anchorRef.current.focus();
		}
		prevOpen.current = open;
	}, [open]);

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpen(false);
		setOptions(false)
	};

	const prevOpen = useRef(open);

	const deleteAllNotification = () => {
		let userId = localStorage?.getItem("userId");
		let ids = []
		if (filteredData.length) {
			for (let e of data) {
				if (e?.status) {
					ids.push(e?.id)
				}
			}
			let payload = {
				payload: {
					id: ids
				},
				deviceId: userId
			}
			deleteNotificationList(payload)
		} else {
			deleteNotificationListAll(userId)
		}
	}

	const handleChange = (event, index) => {
		let list = [...data]
		list[index]['status'] = event.target.checked
		setData(list)
	};

	const handleScroll = (e) => {
		if (loading || `${notiOptions.offset + 1}0` > totalNotification) return;
		const bottom = Math.abs(e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop) < 1;
		if (bottom) {
			const userId = localStorage?.getItem("userId");
			notificationListAction(`deviceId=${userId}&limit=${notiOptions.limit}&offset=${notiOptions.offset + 1}0`)
			setNotiOptions({ ...notiOptions, offset: notiOptions.offset + 1 })
		}
	}

	return (
		<>
			<Box
				sx={{
					width: 290,
					display: 'flex',
					[theme.breakpoints.down('md')]: {
						width: 'auto'
					}
				}}
			>
				<Box component="span" sx={{ display: { xs: 'none', md: 'flex' }, flexGrow: 1, marginLeft: 1, borderRadius: 4, alignItems: 'center', padding: 0.1 }}>
					<img src={logo} alt="Parental Control" width="220" />
				</Box>
				<ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
					<Avatar
						variant="rounded"
						sx={{
							...theme.typography.commonAvatar,
							...theme.typography.mediumAvatar,
							transition: 'all .2s ease-in-out',
							background: 'white',
							color: '#0e56a6',
							'&:hover': {
								background: theme.palette.secondary.light,
								color: theme.palette.secondary.dark
							}
						}}
						onClick={handleLeftDrawerToggle}
						color="inherit"
					>
						<IconMenu2 stroke={1.5} size="1.3rem" />
					</Avatar>
				</ButtonBase>

			</Box>
			<Box sx={{ flexGrow: 1 }} />
			<Box sx={{ flexGrow: 1 }} />
			<>
				<Box
					sx={{
						ml: 2,
						mr: 3,
						[theme.breakpoints.down('md')]: {
							mr: 2
						}
					}}
				>
					<ButtonBase sx={{ borderRadius: '12px' }}>
						<Avatar
							variant="rounded"
							sx={{
								...theme.typography.commonAvatar,
								...theme.typography.mediumAvatar,
								transition: 'all .2s ease-in-out',
								background: 'white',
								color: '#0e56a6',
								'&[aria-controls="menu-list-grow"],&:hover': {
									background: theme.palette.secondary.light,
									color: theme.palette.secondary.dark
								}
							}}
							ref={anchorRef}
							aria-controls={open ? 'menu-list-grow' : undefined}
							aria-haspopup="true"
							// onClick={handleToggle}
							color="inherit"
						>
							{/* <Badge variant="dot" color="secondary"></Badge> */}
							<Badge color="secondary">
								<IconBell stroke={1.5} size="1.3rem" />
							</Badge>
						</Avatar>

					</ButtonBase>
				</Box>
				<Popper
					placement={matchesXs ? 'bottom' : 'bottom-end'}
					open={open}
					anchorEl={anchorRef.current}
					role={undefined}
					transition
					disablePortal
					popperOptions={{
						modifiers: [
							{
								name: 'offset',
								options: {
									offset: [matchesXs ? 5 : 0, 20]
								}
							}
						]
					}}
				>
					{({ TransitionProps }) => (
						<Transitions position={matchesXs ? 'top' : 'top-right'} in={open} {...TransitionProps} sx={{ width: '21rem' }}>
							<Paper >
								<ClickAwayListener onClickAway={handleClose}>
									<MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]} >
										<Grid container direction="column" spacing={2} >
											<Grid item xs={12}>
												<Grid container alignItems="center" justifyContent="space-between" sx={{ pt: 2, px: 2 }}>
													<Grid item>
														<Stack direction="row" spacing={2} >
															<Typography variant="subtitle1">All Notification</Typography>
															<Chip
																size="small"
																label={totalNotification}
																sx={{
																	color: theme.palette.background.default,
																	bgcolor: theme.palette.secondary.dark
																}}
															/>
														</Stack>
													</Grid>
													{
														data?.length > 0 ? (
															<>
																{options ? <Grid sx={{ display: 'flex' }}> <Grid item sx={{ cursor: 'pointer', color: 'red' }} alignItems="center" onClick={() => deleteAllNotification()} >
																	{filteredData.length > 0 ? 'Clear Selected' : 'Clear All'}
																</Grid> <Grid item sx={{ cursor: 'pointer', color: 'red', marginLeft: 2 }} alignItems="center" onClick={() => setOptions(false)} >
																		Cancel
																	</Grid></Grid> : <Grid item sx={{ cursor: 'pointer', color: 'red' }} alignItems="center" onClick={() => setOptions(true)} >
																	Clear
																</Grid>}</>
														) : ""
													}
												</Grid>
											</Grid>
											<Grid item xs={12}>
												<Grid
													style={{ height: '21rem', overflowY: 'scroll' }}
													onScroll={handleScroll}
												>
													{data && data?.filter((log) => log?.callType !== 'Geofence')?.map((data, index) => {
														return (
															<Box sx={{ paddingLeft: 1.5, paddingRight: 1.5, paddingBottom: 1, display: 'flex', alignItems: 'center' }}>
																{options && <div><Checkbox onChange={(e) => handleChange(e, index)} {...label} size="small" checked={data?.status} /></div>}
																<List sx={{ py: 0 }}>
																	<ListItem alignItems="center" disableGutters sx={{ py: 0 }}>

																		<ListItemText
																			sx={{
																				py: 0,
																				mt: 0.45,
																				mb: 0.45
																			}}
																			primary={<Typography variant="h4">{data?.message}</Typography>}
																			secondary={
																				<>
																					<Typography
																						variant="subtitle2"
																						sx={{
																							color: theme.palette.grey[500],
																							mt: 0.5
																						}}
																					>
																						{data?.title}
																					</Typography>
																					<Typography
																						variant="subtitle3"
																						sx={{
																							color: theme.palette.grey[500],
																							mt: 0.5,
																							fontSize: '10px'
																						}}
																					>
																						{moment(data?.createdAt).format("DD MMM YYYY hh:mm a")}
																					</Typography>
																				</>

																			}

																		/>

																	</ListItem>
																	<Divider />
																</List>

															</Box>
														);
													})}
													{loading && <div className='d-vertically-hori-center'>
														<CircularProgress sx={{ color: '#fe901a' }} size="1.5rem" />
													</div>}
												</Grid>

											</Grid>
										</Grid>
										<Divider />

									</MainCard>
								</ClickAwayListener>
							</Paper>
						</Transitions>
					)}
				</Popper>
			</>
			<ProfileSection userData={JSON.parse(userData)} profileData={profileData} />
		</>
	);
};

Header.propTypes = {
	handleLeftDrawerToggle: PropTypes.func
};


const mapActionsToProps = {
	notificationListAction,
	profileDetailAction,
	deleteNotificationList,
	resetGeneralState,
	deleteNotificationListAll,
	loadChildrenAction
};

const mapStateToProps = ({ filterReducer, childrenReducer, generalReducer }) => ({
	selectedDevice: childrenReducer.selectedDevice,
	notiList: generalReducer.notiList,
	loading: generalReducer?.loading,
	profileData: generalReducer.profileData,
	isDeleted: generalReducer?.isDeleted,
	totalNotification: generalReducer?.totalNotification
});

export default connect(mapStateToProps, mapActionsToProps)(Header);
