// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';

// action - login reducer 
export const LOGIN_USER_INPROGRESS = '@login/LOGIN_USER_INPROGRESS';
export const LOGIN_USER_COMPLETED = '@login/LOGIN_USER_COMPLETED';
export const LOGIN_USER_FAILED = '@login/LOGIN_USER_FAILED';

export const FORGOT_PASSWORD_INPROGRESS = '@forgot/FORGOT_PASSWORD_INPROGRESS';
export const FORGOT_PASSWORD_COMPLETED = '@forgot/FORGOT_PASSWORD_COMPLETED';
export const FORGOT_PASSWORD_FAILED = '@forgot/FORGOT_PASSWORD_FAILED';
// action - register reducer 
export const REGISTER_USER_INPROGRESS = '@register/REGISTER_USER_INPROGRESS';
export const REGISTER_USER_COMPLETED = '@register/REGISTER_USER_COMPLETED';
export const REGISTER_USER_FAILED = '@register/REGISTER_USER_FAILED';

export const FORGOT_OTP_INPROGRESS = '@forgot/FORGOT_OTP_INPROGRESS';
export const FORGOT_OTP_COMPLETED = '@forgot/FORGOT_OTP_COMPLETED';
export const FORGOT_OTP_FAILED = '@forgot/FORGOT_OTP_FAILED';

export const UPDATE_PASSWORD_INPROGRESS = '@update/UPDATE_PASSWORD_INPROGRESS';
export const UPDATE_PASSWORD_COMPLETED = '@update/UPDATE_PASSWORD_COMPLETED';
export const UPDATE_PASSWORD_FAILED = '@update/UPDATE_PASSWORD_FAILED';

// action - otp phone reducer 
export const OTP_PHONE_INPROGRESS  = '@register/OTP_PHONE_INPROGRESS ';
export const OTP_PHONE_COMPLETED = '@register/OTP_PHONE_COMPLETED';
export const OTP_PHONE_FAILED = '@register/OTP_PHONE_FAILED';

// action - otp email reducer 
export const OTP_EMAIL_INPROGRESS  = '@register/OTP_EMAIL_INPROGRESS ';
export const OTP_EMAIL_COMPLETED = '@register/OTP_EMAIL_COMPLETED';
export const OTP_EMAIL_FAILED = '@register/OTP_EMAIL_FAILED';

export const CHANGE_PASSWORD_INPROGRESS = '@change/CHANGE_PASSWORD_INPROGRESS';
export const CHANGE_PASSWORD_COMPLETED = '@change/CHANGE_PASSWORD_COMPLETED';
export const CHANGE_PASSWORD_FAILED = '@change/CHANGE_PASSWORD_FAILED';

export const CONTACTUS_INPROGRESS = '@change/CONTACTUS_INPROGRESS';
export const CONTACTUS_COMPLETED = '@change/CONTACTUS_COMPLETED';
export const CONTACTUS_FAILED = '@change/CONTACTUS_FAILED';

export const INVITE_EMAIL_INPROGRESS = '@email/INVITE_EMAIL_INPROGRESS';
export const INVITE_EMAIL_COMPLETED = '@email/INVITE_EMAIL_COMPLETED';
export const INVITE_EMAIL_FAILED= '@email/INVITE_EMAIL_FAILED';

export const INVITE_PHONE_INPROGRESS = '@phone/INVITE_EMAIL_INPROGRESS';
export const INVITE_PHONE_COMPLETED = '@phone/INVITE_PHONE_COMPLETED';
export const INVITE_PHONE_FAILED= '@phone/INVITE_PHONE_FAILED';

export const AUTH_STATE_RESET_COMPLETED = '@reset/AUTH_STATE_RESET_COMPLETED';
