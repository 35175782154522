import moment from "moment";
import React,{useEffect} from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { date } from "yup";

export function capitalizeFirstLetter(str) {
    // return string?.charAt(0).toUpperCase() + string?.slice(1).toLowerCase(); 
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
    }
    // Directly return the joined string
    return splitStr.join(' ');

  }
  
export function formatAppsCategory(apps){
    let categories = [...new Set(apps.map(app=> app.category))]
    categories = categories.filter(e => e !== 'Web' && e !== 'smsBlockList' && e !== 'callBlockList');
     return categories.reduce((acc, category)=> {
       const allApps = apps.filter(app => app.category === category)
      return [...acc, {category: category, apps: allApps}]
    }, [])
  }

  export function formatDateCategory(apps){
    let categories = [...new Set(apps.map(app=> app.Date ))]
    let uniqueDates = [...new Set(categories)];


   
     return uniqueDates.reduce((acc, Date)=> {
       const allApps = apps.filter(app => moment(app.Date).format("DD-MM-YYYY ") === moment(Date).format("DD-MM-YYYY "))
       console.log(categories, "alll")
       console.log(uniqueDates, "uniwy");
  
      
    
      return [...acc, {category: moment(Date).format("DD-MM-YYYY"), apps: allApps}]
    }, [])
  }

  

  export const RequireAuth = ({ children }) => {
    const navigate = useNavigate();
    const user  = localStorage.getItem('user')

    React.useEffect(() => {
        if (!user) {
            navigate('/');
        }
    }, [user, navigate]);

    return children;
};

export const RequireAuthDashboard = ({ children }) => {
  const navigate = useNavigate();
  const user  = localStorage.getItem('user')

  React.useEffect(() => {
      if (user) {
          navigate('/user/dashboard');
      }
  }, [user, navigate]);

  return children;
}

export const useComponentWillUnmount = (handler) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useEffect(() => handler, []);
};


  export function formatCalendarEvents(allEvents){
    var now = moment();

    let obj = [{
      day:'Sunday',
      datas:[],
      currentDate: now.clone().weekday(0).format('DD-MM-YYYY')
    },{
      day:'Monday',
      datas:[],
      currentDate: now.clone().weekday(1).format('DD-MM-YYYY')
    },{
      day:'Tuesday',
      datas:[],
      currentDate: now.clone().weekday(2).format('DD-MM-YYYY')
    },{
      day:'Wednesday',
      datas:[],
      currentDate: now.clone().weekday(3).format('DD-MM-YYYY')
    },{
      day:'Thursday',
      datas:[],
      currentDate: now.clone().weekday(4).format('DD-MM-YYYY')
    },{
      day:'Friday',
      datas:[],
      currentDate: now.clone().weekday(5).format('DD-MM-YYYY')
    },{
      day:'Saturday',
      datas:[],
      currentDate: now.clone().weekday(6).format('DD-MM-YYYY')
    }]

    let dates = [...new Set(obj.map(e=> e.day))]

    if(allEvents.length > 0){
         for(let data of allEvents){
              if(data?.frequency === 'Daily'){
                  let startDate = moment(new Date(data?.from));
                  let endDate = moment(new Date(data?.to));
                  let date = [];
                  date.push(startDate.format('dddd'));
                  // for (var m = moment(startDate); m.isSameOrBefore(endDate); m.add(1, 'days')) {
                  //      date.push(m.format('DD-MM-YYYY'));
                  //  }
    
                   for(let mDay of date){
                    console.log('datefinded',mDay)
                     let filtered = obj.filter((e)=> e?.day === mDay)
                     if(filtered.length){ 
                      let index = obj.findIndex((e)=> e?.day === mDay)
                      obj[index].datas.push(data)
                     }
                   }

              }else if(data?.frequency === 'Weekly'){
                for(let day of dates){
                for(let week of data?.weekDays){
                  if(week === day){
                    const isAvailable = obj.filter(app => app.day === week)
                    if(isAvailable.length){
                      let index = obj.findIndex(e => e.day === week);
                      obj[index]['datas'].push(data)
                    }
                  }
                }
              }
              }    
         }
         return obj
     }else{
      return obj
     }
  
  }


  export const getImage = (image) => {
    switch (image) {
        case image:
            return <img src={require(`assets/images/icons/${image}.svg`).default} width="27" height="27" style={{marginRight:10}}/>
        default:
            return <img src={require('assets/images/icons/scheduler.svg').default} width="27" height="27" style={{marginRight:10}}/>
    }
}