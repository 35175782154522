 import "firebase/auth"
 import firebase from 'firebase/compat/app';
 import 'firebase/compat/database';
 
 // Initialize Firebase 
 const config = {
    apiKey: "AIzaSyCzeNIa88WyBial6AX-xEZviLrX3xtFpFw",
    databaseURL: "https://parental-control-325012-default-rtdb.firebaseio.com",
    projectId: "parental-control-325012",
    storageBucket: "parental-control-325012.appspot.com",
    messagingSenderId: "525998139789",
    appId: "1:525998139789:ios:c84dec0170a880099635c4",
 };
 
 const firebaseApp = firebase.initializeApp(config);

 const database = firebaseApp.database();
 const TimeStamp = firebaseApp.TimeStamp;

 export {
    database,
    TimeStamp
 };
 