import { CardHeader, Avatar, Card, Grid } from '@mui/material';
import './style.scss';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

export default function ChildMinCard({ name, img, userSelected, handleOnClick, data, handleRemoveData, handleEdit, childActions }) {
    return (
        <Card className=''>
            <Grid container>
                <Grid item xs={12} className=''>
                    <CardHeader style={{padding: "16px"}}
                        className={userSelected ? 'glowing-border' : 'cursor-pointer'}
                        onClick={() => handleOnClick(data)}
                        avatar={<Avatar alt={name} src={img} />}
                        title={name}
                        titleTypographyProps={{color:'rgb(97, 97, 97)' }}
                        subheader={`${data?.devices?.length} devices`}
                        action={
                        childActions && (
                            <div style={{marginTop: '-3px'}}>
                                <IconButton sx={{ backgroundColor: 'transparent', marginTop: '-1px' }}>
                                    <EditIcon sx={{fontSize:'1.3rem'}} onClick={(e) => {
                                            e.stopPropagation();
                                            handleEdit(data)
                                         
                                    }} />       
                                </IconButton>
                                 <IconButton sx={{ backgroundColor: 'transparent' }}>
                                   <DeleteIcon sx={{fontSize:'1.3rem'}} onClick={(e) =>{
                                     e.stopPropagation();
                                     handleRemoveData(data?.childId)
                                   }} />
                                   </IconButton>
                                   </div>
                            )
                        }
                    />

                </Grid>
            </Grid>
        </Card>
    );
}
