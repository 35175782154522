import React from 'react';
import { Box, Typography, Grid } from '@mui/material'
import ClipBoard from 'ui-component/clipboard';

export default function DeviceAdd(props) {
  return(
    <Box sx={{minWidth: '300px', mt: '.5rem'}}>
        <Typography variant="subtitle1">Install the app using the bellow link</Typography>
        <ClipBoard content={props.playStoreURL}/>
        <Typography sx={{ mt: '.5rem'}} variant="subtitle1">Last please enter the security code bellow in your child device</Typography>
        <Grid container >
          <Grid sx={{ margin: 'auto'}}>
            <Typography variant='h1'>
              {props.securityCode}
            </Typography>
          </Grid>
        </Grid>
    </Box>
  )
}