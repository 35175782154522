import {
    GET_ALL_APP_USAGE_LIST,
    GET_ALL_APP_USAGE_LIST_SUCCESS,
    GET_ALL_APP_USAGE_LIST_FAILED,

    DAILY_APP_USAGE_LIST,
    DAILY_APP_USAGE_LIST_SUCCESS,
    DAILY_APP_USAGE_LIST_FAILED,

} from '../types';

export const initialState = {
    loading: false,
    allAppUsage: [],
    dailyUsage: [],
    errMessage: ''
};

const appUsageReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_APP_USAGE_LIST:
            return { ...state, loading: true };
        case GET_ALL_APP_USAGE_LIST_SUCCESS:
            return { ...state, loading: false, allAppUsage: action.payload };
        case GET_ALL_APP_USAGE_LIST_FAILED:
            return { ...state, loading: false, errMessage: action.payload };


        case DAILY_APP_USAGE_LIST:
            return { ...state, loading: true };
        case DAILY_APP_USAGE_LIST_SUCCESS:
            return { ...state, loading: false, dailyUsage: action.payload };
        case DAILY_APP_USAGE_LIST_FAILED:
            return { ...state, loading: false, errMessage: action.payload };

        default:
            return state;
    }
};

export default appUsageReducer;
