// assets
import { IconTypography, IconPalette, IconShadow, IconWindmill, IconDashboard } from '@tabler/icons';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import EditLocationIcon from '@mui/icons-material/EditLocation';
import EmergencyShareIcon from '@mui/icons-material/EmergencyShare';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
import AppsIcon from '@mui/icons-material/Apps';
import LanguageIcon from '@mui/icons-material/Language';
import AppShortcutIcon from '@mui/icons-material/AppShortcut';
import WifiCallingIcon from '@mui/icons-material/WifiCalling';
import DesktopMacIcon from '@mui/icons-material/DesktopMac';
import HomeIcon from '@mui/icons-material/Home';

// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconDashboard,
    AccessTimeIcon,
    AddLocationAltIcon,
    EditLocationIcon,
    EmergencyShareIcon,
    LocationOnIcon,
    WhereToVoteIcon,
    AppsIcon,
    LanguageIcon,
    AppShortcutIcon,
    WifiCallingIcon,
    HomeIcon
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
    id: 'devicemenu',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/user/dashboard',
            icon: 'dashboard',
            breadcrumbs: false
        },

        {
            id: 'location',
            title: 'Location',
            type: 'collapse',
            icon: 'location',
            children: [
                {
                    id: 'geolocation',
                    title: 'Geo Location',
                    type: 'item',
                    url: '/user/geo-location',
                    breadcrumbs: false,
                    icon: 'location',
                },
                {
                    id: 'geofence',
                    title: 'Geo Fencing',
                    type: 'item',
                    url: '/user/geo-fencing',
                    breadcrumbs: false,
                    icon: 'geo-fence',
                },
                {
                    id: 'drivesafe',
                    title: 'Drive Safe ',
                    type: 'item',
                    url: '/user/drive-safe',
                    breadcrumbs: false,
                    icon: 'geo-fence',
                },
                {
                    id: 'familymap',
                    title: 'Child Map',
                    type: 'item',
                    url: '/user/child-map',
                    breadcrumbs: false,
                    icon: 'location',
                },
            ]
        },
        {
            id: 'deskmanage',
            title: 'Desktop Management',
            type: 'item',
            external : 'true',
            target: "blank",
            url: 'https://filter.cybersafefamily.com/login/',
            icon: 'desktop',
            breadcrumbs: false
        },
        {
            id: 'manual',
            title: 'User Manuals',
            type: 'item',
            url: '/user/user-manual',
            icon: 'manual',
            breadcrumbs: false
        },
      
    ]
};

export default utilities;
