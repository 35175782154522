import React, { useState, useRef, useEffect } from "react";
import "./style.scss";
import Dashboard from "assets/windows-manual/dash.jpg";
import LogCode from "assets/windows-manual/log-code-ios.jpg";
import Ios from "assets/windows-manual/ios-install.jpg";
import DnsIos1 from "assets/mobile-manual/dns-ios-1.png";
import DnsIos2 from "assets/mobile-manual/dns-ios-2.png";
import DnsIos3 from "assets/mobile-manual/dns-ios-3.png";
import DnsIos4 from "assets/mobile-manual/dns-ios-4.png";
import DnsIos5 from "assets/mobile-manual/dns-ios-5.png";
import DnsIos6 from "assets/mobile-manual/dns-ios-6.png";
import DnsIos7 from "assets/mobile-manual/dns-ios-7.png";
import AppleLogin from "assets/windows-manual/app-login.jpg";
import Landing from "assets/windows-manual/landing.jpg";
import LogIos from "assets/windows-manual/logcode-ios.jpg";
import Permission1 from "assets/windows-manual/permission1.jpg";
import Permission2 from "assets/windows-manual/permission2.jpg";


import { connect } from 'react-redux';
import { Helmet } from "react-helmet";


const Mobilemanual = ({
  selectedChild,
  selectedDevice,

}) => {

  console.log(selectedChild, "manualchild")

  return (
    <div className="mobile-container container-fluid">
      <Helmet>
        <title>Cyber Safe Family - Registration iOS Manual</title>
        <meta name="description" content="Nested component" />
      </Helmet>
      <div>
        <h1 style={{ color: "rgb(14, 86, 166)", textAlign: 'center' }}>CyberSafeFamily iOS Devices Setup Guide</h1>
        <br></br>
        <div>
          <div className="fontStyles">
            <h3 style={{ color: "rgb(14, 86, 166)" }}>Preparation for Registration</h3>
            <ol>
              <li>Open the SafeDNS Dashboard and navigate to <b>Settings {'>'} Devices</b>.</li>
              <br></br>
              <div>
                <img style={{ width: "100%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={DnsIos1}></img>
              </div>
              <br></br>
              <li>Scroll down to the VPN section, enter any name for a new VPN connection, and click Add.</li>
              <br></br>
              <div>
                Choose a filtering policy before adding a VPN connection, if needed.
              </div>
              <br></br>
              <div>
                <img style={{ width: "100%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={DnsIos2}></img>
              </div>
              <br></br>
              <li>Upon creating the connection, two icons will appear in the "Certificate" column. One is for downloading the Certificate, and the other is for sending it by email. Click the "Letter" icon to send the configuration file to your SafeDNS account email.<br></br><br></br>

                Multiple devices can use the same filtering policy, but <b>each device should use its own VPN certificate</b>.
                <br></br><br></br>
                You can also change the filtering policy of the created VPN connection by clicking on the pencil icon to the right. Please note, that you don't need to redownload your VPN certificate on your mobile device if you change its filtering policy.</li>

              <br></br>
              <div>
                <img style={{ width: "100%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={DnsIos3}></img>
              </div>
              <br></br>
              <li>Install the OpenVPN client from the App Store (the app is free of charge).</li>
              <br></br>
              <li>
                Open the received email on your mobile device and press on the Certificate. Choose a Certificate to open with the OpenVPN app.
              </li>
              <br></br>
              <div>
                <img style={{ width: "35%", display: 'block', margin: 'auto' }} src={DnsIos4}></img>
              </div>
              <br></br>
              <li>
                Press the green plus icon to add the OpenVPN certificate.
              </li>
              <br></br>
              <div>
                <img style={{ width: "55%", display: 'block', margin: 'auto' }} src={DnsIos5}></img>
              </div>
              <br></br>
              <li>
                Activate the "Connection" switch to establish the VPN connection.
              </li>
              <br></br>
              <div>
                <img style={{ width: "50%", display: 'block', margin: 'auto' }} src={DnsIos6}></img>
              </div>
              <br></br>
              <div>
                <img style={{ width: "50%", display: 'block', margin: 'auto' }} src={DnsIos7}></img>
              </div>
              <br></br>
              <div>Your device is now filtered with the SafeDNS filtering policy.</div>
            </ol>
          </div>
        </div>


        <div>
          <div className="fontStyles">
            <h3 style={{ color: "rgb(14, 86, 166)" }}>Parental Application Installation</h3>
            <ol>
              <li>Download the parental app.  </li>
              <br></br>


              <li>Download it from the App Store.</li>
              <br></br>
              <div>
                <img style={{ width: "30%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Ios}></img>
              </div>
              <br></br>
              <li>There are two ways to register, with your smartphone or through the web portal - <a href='https://portal.cybersafefamily.com/'> https://portal.cybersafefamily.com/ </a></li>

            </ol>
          </div>
        </div>

        <div>
          <div className="fontStyles">
            <h3 style={{ color: "rgb(14, 86, 166)" }}>Child Application Installation</h3>
            <ol>
              <li>Download the Child app</li>
              <br></br>

              <li>Download it from the App Store.</li>
              <br></br>
              <div>
                <img style={{ width: "30%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Ios}></img>
              </div>
              <br></br>
            </ol>
          </div>
        </div>

        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Installation</h2>
          <div className="fontStyles">

            <ol>
              <li>Minimum required iOS version is 12 </li>
              <br></br>
              <li>Both parent and child must have iOS apple account ( child must have an child apple account)</li>
              <br></br>
              <li>Child must be added to the parent in Family controler</li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Landing}></img>
              </div>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={AppleLogin}></img>
              </div>
              <br></br>
            </ol>
          </div>
        </div>


        <div id="dashboard">
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Add Child</h2>
          <ul className="fontStyles">
            <li>
              Based on the purchased program, you can manage a minimum of two children’s devices. We recommend that you use nicknames rather than real names.
            </li>
            <br></br>
            <div>
              <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={LogCode}></img>
            </div>
            <br></br>
            <li>Open the CyberSafeFamily app and enter the pairing code you received on the parent phone.</li>
            <div>
              <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={LogIos}></img>
            </div>
            <br></br>
            <li>Enable all the permission on the child’s device. </li>
            <br></br>
            <div>
              <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Permission1}></img>
            </div>
            <br></br>
            <div>
              <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Permission2}></img>
            </div>
            <br></br>
            <li>Added child will be listed </li>
            <br></br>
            <div>
              <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Dashboard}></img>
            </div>
            <br></br>
            
          </ul>
        </div>
      </div>

    </div>
  );
};
const mapStateToProps = ({ childrenReducer, deviceReducer }) => ({

  selectedChild: childrenReducer.selectedChild,
  selectedDevice: childrenReducer.selectedDevice,

});

const mapActionsToProps = {

  // setSelectedChildAction,
  // setSelectedDeviceAction,

};

export default connect(mapStateToProps, mapActionsToProps)(Mobilemanual);

