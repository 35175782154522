import {
    GET_ALL_APP_USAGE_LIST,
    GET_ALL_APP_USAGE_LIST_SUCCESS,
    GET_ALL_APP_USAGE_LIST_FAILED,

    DAILY_APP_USAGE_LIST,
    DAILY_APP_USAGE_LIST_SUCCESS,
    DAILY_APP_USAGE_LIST_FAILED,
} from '../types';
import { getCall } from './api'

export const getAllAppUsage = (payload) => (dispatch) => {
    dispatch({ type: GET_ALL_APP_USAGE_LIST });
    getCall(`/app/Usage?${payload}`, true).then((res) => {
        dispatch({ type: GET_ALL_APP_USAGE_LIST_SUCCESS, payload: res.data.data }); 
    })
    dispatch({ type: GET_ALL_APP_USAGE_LIST_FAILED });
};

export const dailyAppUsage = (payload) => (dispatch) => {
    dispatch({ type: DAILY_APP_USAGE_LIST });
    getCall(`/app/Usage?${payload}`, true).then((res) => {
        dispatch({ type: DAILY_APP_USAGE_LIST_SUCCESS, payload: res.data.data }); 
    })
    dispatch({ type: DAILY_APP_USAGE_LIST_FAILED });
};
