import * as actionTypes from '../actions/actions';

export const initialState = {
    loading: false,
    user: localStorage.getItem('user'),
    forgotValidated: '',
    errMessage: '',
    forgotPasswordMsg: '',
    changepsw: '',
    contactus: '',
    registerUsers: null,
    loginSuccess: false,
    otpPhone: '',
    otpEmail: '',
    errMessageEmail: '',
    verifyOtpId:'',
    inviteEmail: false,
    invitePhone: false,
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOGIN_USER_INPROGRESS:
            return { ...state, loading: true };
        case actionTypes.LOGIN_USER_COMPLETED:
            console.log(action.payload, "actionlog")
            return { ...state, loading: false, user: action.payload, loginSuccess: true };
        case actionTypes.LOGIN_USER_FAILED:
            if(action?.payload.message === 'Please have your OTP verified'){
               state.verifyOtpId = action.payload?.userId
            }
            return { ...state, loading: false, errMessage: action.payload?.message };

        case actionTypes.FORGOT_PASSWORD_INPROGRESS:
            return { ...state, loading: true };
        case actionTypes.FORGOT_PASSWORD_COMPLETED:
            return { ...state, loading: false, forgotPasswordMsg: action.payload };
        case actionTypes.FORGOT_PASSWORD_FAILED:
            return { ...state, loading: false, errMessage: action.payload };

        case actionTypes.FORGOT_OTP_INPROGRESS:
            return { ...state, loading: true };
        case actionTypes.FORGOT_OTP_COMPLETED:
            return { ...state, loading: false, forgotValidated: action.payload };
        case actionTypes.FORGOT_OTP_FAILED:
            return { ...state, loading: false, errMessage: action.payload };

        case actionTypes.REGISTER_USER_INPROGRESS:
            return { ...state, loading: true };
        case actionTypes.REGISTER_USER_COMPLETED:
            return { ...state, loading: false, registerUsers: action.payload };
        case actionTypes.REGISTER_USER_FAILED:
            return { ...state, loading: false, errMessage: action.payload };

        case actionTypes.OTP_PHONE_INPROGRESS:
            return { ...state, loading: true };
        case actionTypes.OTP_PHONE_COMPLETED:
            return { ...state, loading: false, otpPhone: action.payload };
        case actionTypes.OTP_PHONE_FAILED:
            return { ...state, loading: false, errMessage: action.payload };

        case actionTypes.OTP_EMAIL_INPROGRESS:
            return { ...state, loading: true };
        case actionTypes.OTP_EMAIL_COMPLETED:
            return { ...state, loading: false, otpEmail: action.payload };
        case actionTypes.OTP_EMAIL_FAILED:
            return { ...state, loading: false, errMessageEmail: action.payload };

        case actionTypes.UPDATE_PASSWORD_INPROGRESS:
            return { ...state, loading: true };
        case actionTypes.UPDATE_PASSWORD_COMPLETED:
            return { ...state, loading: false, forgotPasswordMsg: action.payload };
        case actionTypes.UPDATE_PASSWORD_FAILED:
            return { ...state, loading: false, errMessage: action.payload };

        case actionTypes.CHANGE_PASSWORD_INPROGRESS:
            return { ...state, loading: true };
        case actionTypes.CHANGE_PASSWORD_COMPLETED:
            return { ...state, loading: false, changepsw: action.payload };
        case actionTypes.CHANGE_PASSWORD_FAILED:
            return { ...state, loading: false, errMessage: action.payload };

        case actionTypes.CONTACTUS_INPROGRESS:
            return { ...state, loading: true };
        case actionTypes.CONTACTUS_COMPLETED:
            return { ...state, loading: false, contactus: action.payload };
        case actionTypes.CONTACTUS_FAILED:
            return { ...state, loading: false, errMessage: action.payload };

            case actionTypes.INVITE_EMAIL_INPROGRESS:
                return { ...state, loading: true };
            case actionTypes.INVITE_EMAIL_COMPLETED:
                return { ...state, loading: false, inviteEmail: action.payload };
            case actionTypes.INVITE_EMAIL_FAILED:
                return { ...state, loading: false, errMessage: action.payload };

                
            case actionTypes.INVITE_PHONE_INPROGRESS:
                return { ...state, loading: true };
            case actionTypes.INVITE_PHONE_COMPLETED:
                return { ...state, loading: false, invitePhone: action.payload };
            case actionTypes.INVITE_PHONE_FAILED:
                return { ...state, loading: false, errMessage: action.payload };

        case actionTypes.AUTH_STATE_RESET_COMPLETED:
            return { ...state, errMessage: '', forgotValidated: '', forgotPasswordMsg: '', loginSuccess: false, changepsw: '', otpEmail: '', otpPhone: '', errMessageEmail: '', registerUsers: null, contactus: '',verifyOtpId:'', inviteEmail: false, invitePhone: false };

        default:
            return state;
    }
};

export default authReducer;
