import React, { useState, useRef, useEffect } from "react";
import "./style.scss";

import Mobile1 from "assets/mobile-manual/m1.jpg";
import Mobile2 from "assets/mobile-manual/m2.jpg";
import Mobile3 from "assets/mobile-manual/m3.jpg";
import Mobile4 from "assets/mobile-manual/m4.jpg";
import Mobile5 from "assets/mobile-manual/m5.png";
import Mobile10 from "assets/mobile-manual/m10.jpg";
import Mobile11 from "assets/mobile-manual/m11.jpg";
import Mobile12 from "assets/mobile-manual/m12.jpg";
import Mobile13 from "assets/mobile-manual/m13.jpg";
import Mobile14 from "assets/mobile-manual/m14.png";
import Mobile15 from "assets/mobile-manual/m82.jpg";
import Mobile16 from "assets/mobile-manual/m83.jpg";
import Mobile17 from "assets/mobile-manual/m84.jpg";
import Mobile18 from "assets/mobile-manual/m85.jpg";
import Mobile19 from "assets/mobile-manual/mobile19.jpg";
import AndroidChild from "assets/windows-manual/androidChild.jpg";
import AndroidParent from "assets/windows-manual/androidParent.jpg";
import SamsungParent from "assets/windows-manual/galaxy-parent.jpg";
import SamsungChild from "assets/windows-manual/galaxy-child.jpg";

import { connect } from 'react-redux';
import { Helmet } from "react-helmet";


const Mobilemanual = ({
  selectedChild,
  selectedDevice,

}) => {

  console.log(selectedChild, "manualchild")

  return (
    <div className="mobile-container container-fluid">
      <Helmet>
        <title>Cyber Safe Family - Android Registration Manual</title>
        <meta name="description" content="Nested component" />
      </Helmet>
      <div>
        <h1 style={{ color: "rgb(14, 86, 166)", textAlign: 'center' }}>CyberSafeFamily Android Devices Setup Guide</h1>
        <br></br>
        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Preparation for Registration</h2>
        </div>

        <div>
          <div className="fontStyles">
            <h3 style={{ color: "rgb(14, 86, 166)" }}>Parental App</h3>
            <ol>
              <li>Download the parental app.  </li>
              <br></br>

              <li> If your device is a Samsung brand phone, download it from the Samsung Galaxy store. </li>
              <br></br>
              <div>
                <img style={{ width: "30%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={SamsungParent}></img>
              </div>
              <br></br>
              <li> Otherwise, download it from the Google Play Store.</li>
              <br></br>
              <div>
                <img style={{ width: "30%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={AndroidParent}></img>
              </div>
              <br></br>
              <li>There are two ways to register, with your smartphone or through the web portal - <a href='https://portal.cybersafefamily.com/' target="blank"> https://portal.cybersafefamily.com/</a> </li>

            </ol>
          </div>
        </div>

        <div>
          <div className="fontStyles">
            <h3 style={{ color: "rgb(14, 86, 166)" }}>Child App</h3>
            <ol>
              <li>Download the Child app</li>
              <br></br>

              <li> If your device is a Samsung brand phone, download it from the Samsung Galaxy store. </li>
              <br></br>
              <div>
                <img style={{ width: "30%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={SamsungChild}></img>
              </div>
              <br></br>
              <li> Otherwise, download it from the Google Play Store.</li>
              <br></br>
              <div>
                <img style={{ width: "30%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={AndroidChild}></img>
              </div>
              <br></br>
            </ol>
          </div>
        </div>

        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to registration</h2>
          <div className="fontStyles">

            <ol>
              <li>Click Signup from the landing page.</li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile1}></img>
              </div>
              <br></br>

              <li>You will be redirected to the registration form page</li>
              <br></br>
              <ol>
                <li>Enter the parent’s first and last name and phone number.</li>
                <li>Enter the email address and password (from now on, your email and the password will be your login in credentials)</li>
                <li>Read the privacy policy and select the button.</li>
                <li>Click signup</li>
              </ol>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile2}></img>
              </div>
              <br></br>
              <li>Once you enter the details and click sign up, you will be redirected to the OTP verification page. In addition, you will receive OTP code on your email and phone.</li>

              <br></br>
              <ol>
                <li>
                  Obtain your code from your email and enter it into the email boxes.
                </li>
                <li>
                  Obtain your code from your phone and enter it into the phone boxes.
                </li>
              </ol>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile3}></img>
              </div>
              <br></br>
              <li>Enter the correct OTP, and you will be redirected to the login page.</li>


              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile4}></img>
              </div>
              <br></br>
            </ol>
          </div>
        </div>


        <div id="dashboard">
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Add Child</h2>
          <ul className="fontStyles">
            <li>
              Based on the purchased program, you can manage a minimum of two children’s devices. We recommend that you use nicknames rather than real names.
            </li>
          </ul>
        </div>

        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to Add Child</h2>

          <div className="fontStyles">
            <ol>
              <li>Click “Add Child”</li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile10}></img>
              </div>
              <br></br>
              <li>
                Enter the name of the child
              </li>
              <li>Enter the age.</li>
              <li>Select the type of device of the Child</li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile13}></img>
              </div>
              <br></br>
              <li>
                On clicking continue, you will be redirected to the Profile image uploading page.

              </li>
              <li>Please upload pictures using a camera or by selecting from the library.</li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile11}></img>
              </div>
              <br></br>
              <li>On clicking continue, you will be redirected to the home page, and the pairing code for the child device will be displayed on a popup screen.</li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile12}></img>
              </div>
              <br></br>
              <li>Download the CyberSafeFamily child app on your child’s phone – see preparation for registration.</li>
              <br></br>
              <li>Open the CyberSafeFamily app and enter the pairing code you received on the parent phone.</li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile15}></img>
              </div>
              <br></br>
              <li>Enable all the permission on the child’s device.</li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile17}></img>
              </div>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile16}></img>
              </div>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile19}></img>
              </div>
              <br></br>

              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile18}></img>
              </div>
              <br></br>
              Note : if any of the permissions are not enabled then the application wont work.
              <br></br>
              <li>After a few seconds, you will see all the apps from the child’s phone on your phone to block and unblock or schedule.</li>
              <br></br>
              <li> Please see the features manual for more information. - <a href="https://portal.cybersafefamily.com/user/feature-device-manual">https://portal.cybersafefamily.com/user/feature-device-manual</a> </li>
              <br></br>
              <li>Adding a second child, click on the plus sign and follow the step 7 instructions.</li>
            </ol>
          </div>
        </div>


        {/* <div >
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Add Device</h2>
          <ul className="fontStyles">
            <li>
              The  device of the child can be synced with the parent device by using the log code from the parent.
            </li>
            <li>
              There are two ways to add device in the application.
            </li>
          </ul>
        </div> */}

        {/* <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to Add Device</h2>
          <div className="fontStyles">
            <ol>
              <li>One way is to use the log code from the popup which is displayed when we add child.</li>


              <li>
                The other way is to use the Add device button near to the child’s name.
              </li>
              <li>On clicking which the logcode popup will be displayed. </li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile14}></img>
              </div>
              <br></br>
            </ol>
          </div>
        </div> */}

      </div>

    </div>
  );
};
const mapStateToProps = ({ childrenReducer, deviceReducer }) => ({

  selectedChild: childrenReducer.selectedChild,
  selectedDevice: childrenReducer.selectedDevice,

});

const mapActionsToProps = {

  // setSelectedChildAction,
  // setSelectedDeviceAction,

};

export default connect(mapStateToProps, mapActionsToProps)(Mobilemanual);

