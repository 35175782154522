import React, { useEffect, useState } from "react";
import { GoogleMap, useJsApiLoader, Circle, Marker } from '@react-google-maps/api';

import Geocode from "react-geocode";

const containerStyle = {
  width: '100%',
  height: '100%'
};

const center = {
  lat: 37.772,
  lng: -122.214
}

const position = {
  lat: 37.772,
  lng: -122.214
}

const options = {
  strokeColor: '#FF0000',
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: '#FF0000',
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  radius: 1000,
  zIndex: 1
}

const optionsGreen = {
  strokeColor: '#008000',
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: '#008000',
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  radius: 1000,
  zIndex: 1
}

function MyComponent(props) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyCSGIpUyG2dUSowKZMv6Wt3_xECq6IpYfU"
  })

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    // const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);
    // setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const [location, setFirstName] = useState('');

 

console.log(location.position, "fst.position")

const onMarkerDragEnd = (coord, index) => {
  const { latLng } = coord;
  const lat = latLng.lat();
  const lng = latLng.lng();
  console.log(lat);
  setFirstName({
      // ...this.state.markers1[index],
      position: { lat, lng }
  });
  // this.setState(prevState => {
  //     const markers1 = [...this.state.markers1];
  //     markers1[index] = { ...markers1[index], position: { lat, lng } };
  //     //return { markers1 };
  // });
};


  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={props?.location}
      zoom={15}
      onLoad={onLoad}
      onUnmount={onUnmount}
      yesIWantToUseGoogleMapApiInternals
   
    >
      <>
        <Marker
          onLoad={onLoad}
          position={ props?.location }
          draggable={props?.draggable}
          onDragEnd={props?.onMarkerDragEnd}
        />
        <Circle
          center={props?.location}
          radius={props?.radius}
          options={props?.radius > 0 ? props?.action === "Allow" ? optionsGreen : options : ""}
        />
      </>
    </GoogleMap>
  ) : <></>
}

export default React.memo(MyComponent)