import React, { useEffect, useState } from 'react';
import { Box} from '@mui/material';
import {styled } from '@mui/material/styles';
import './style.scss';
import EditIcon from '@mui/icons-material/Edit';
import Avatar from '@mui/material/Avatar';

export default function ChildForm(props) {
  const Input = styled("input")({
    display: "none",
  });
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    if(props?.imageUrl){
      setSelectedImage(props?.imageUrl)
    }
    return () => {
      setSelectedImage(null)
    }
  }, [props?.imageUrl])


  return (
    <Box sx={{ minWidth: '300px'}} className="avatar-image-upload">
      {/* <div style={{position:'relative' }}>
      <Avatar alt="C" src={selectedImage}  sx={{height:130,width:130}} /> 
        <label htmlFor="contained-button-file" className='child-addImage-btn'>
          <Input
            accept="image/*"
            id="contained-button-file"
            multiple
            type="file"
            onChange={(event) => {
              setSelectedImage(URL.createObjectURL(event.target.files[0]));
              props?.handleImgChange(event)
            }}
           
          />
             <EditIcon fontSize="small" sx={{color:'white'}} />
        </label>
      </div> */}
    </Box>
  )
}