import React, { useEffect, useState } from "react";
import { Grid, Button, Box, TextField } from '@mui/material';
import { geoLocationLog, addGeoFenceAction, loadGeoFenceListAction, resetLocationState, updateGeoFenceAction } from 'store/actions';
import { connect } from 'react-redux';
import MapComp from "ui-component/google-map/reactmap";
import SubCard from 'ui-component/cards/SubCard';
import Slider from '@mui/material/Slider';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useSnackbar } from 'notistack';
import { useNavigate } from "react-router-dom";
import Geocode from "react-geocode";
import PlacesAutocomplete from "./autocomplete"
import LoadingButton from '@mui/lab/LoadingButton';

export const paramsQuery = (value) => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const data = params.get(value);
  return data;
};


const AddGeoFence = ({ addGeoFenceAction, selectedDevice, addFence, errMessage, loadGeoFenceListAction, fenceList, resetLocationState, updateGeoFenceAction, selectedDeviceDynamicInfo, loading }) => {

  let filtered = fenceList && fenceList.filter((data) => data.fenceId === paramsQuery("id"));
  let location = selectedDeviceDynamicInfo && selectedDeviceDynamicInfo?.lastlocation && JSON.parse(selectedDeviceDynamicInfo?.lastlocation)

  const [fenceText, setFenceName] = useState(filtered && filtered[0]?.fenceName || "");
  const [latLocation, setLocationlat] = useState(location?.lat);
  const [logLocation, setLocationlog] = useState(location?.lng);
  const [enterLoc, setEnterLoc] = useState("");
  const [city, setCity] = useState('')
  const [sliderVal, setSliderVal] = useState(filtered && filtered[0]?.fenceRadius || 0);
  const [action, setAction] = useState(paramsQuery("status") === "allow" ? "Allow" : "Block");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState('');

  useEffect(() => {
    let id = paramsQuery("id");
    if (id) {
      loadGeoFenceListAction(`deviceId=${selectedDevice?.deviceId}`);
      setAction(filtered && filtered[0]?.action)
      setLocationlat(filtered && filtered[0]?.latitude)
      setLocationlog(filtered && filtered[0]?.longitude)

    }

  }, []);

  const onMarkerDragEnd = (coord, index) => {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();
    setLocationlat(lat)
    setLocationlog(lng)

    setFirstName({
      // ...this.state.markers1[index],
      position: { lat, lng }
    });
    // this.setState(prevState => {
    //     const markers1 = [...this.state.markers1];
    //     markers1[index] = { ...markers1[index], position: { lat, lng } };
    //     //return { markers1 };
    // });
  };


  useEffect((latLocation, logLocation) => {
      Geocode.fromLatLng(latLocation, logLocation).then(
        (response) => {
          
          const address = response.results[0].formatted_address;
          let city, state, country;
          for (let i = 0; i < response.results[0].address_components.length; i++) {
            for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
              switch (response.results[0].address_components[i].types[j]) {
                case "locality":
                  city = response.results[0].address_components[i].long_name;
                  break;
                case "administrative_area_level_1":
                  state = response.results[0].address_components[i].long_name;
                  break;
                case "country":
                  country = response.results[0].address_components[i].long_name;
                  break;
              }
            }
          }
          setCity(address)

        },
        (error) => {
          console.error(error);
        }
      );
   
  }, [latLocation, logLocation])


  Geocode.fromLatLng(latLocation, logLocation).then(
    (response) => {
      
      console.log(response, "responce")
      const address = response.results[0].formatted_address;
      let city, state, country;
      for (let i = 0; i < response.results[0].address_components.length; i++) {
        for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
          switch (response.results[0].address_components[i].types[j]) {
            case "locality":
              city = response.results[0].address_components[i].long_name;
              break;
            case "administrative_area_level_1":
              state = response.results[0].address_components[i].long_name;
              break;
            case "country":
              country = response.results[0].address_components[i].long_name;
              break;
          }
        }
      }
      setCity(city + "," + state + ","  + country)
      console.log(city, state, country, "addresstool");
      console.log(address);
    },
    (error) => {
      console.error(error);
    }
  );

  useEffect(() => {
    if (addFence) {
      let id = paramsQuery("id");
      if (id) {
        enqueueSnackbar('Updated Successful', {
          variant: "success",
        });
      } else {
        enqueueSnackbar('Created Successful', {
          variant: "success",
        });
      }


      navigate('/user/geo-fencing')
    }
    if (errMessage) {
      enqueueSnackbar(errMessage, {
        variant: "error",
      })
    }
    resetLocationState()
  }, [addFence, errMessage])

  const handleChangeAllow = () => {
    setSliderVal(0)
    setAction("Allow");
  };

  const handleChangeBlock = () => {
    setSliderVal(0)
    setAction("Block");
  };

  const handleSubmitFormData = () => {
    let id = paramsQuery("id");
    let payload = {
      "latitude": latLocation,
      "longitude": logLocation,
      "fenceRadius": sliderVal,
      "action": action,
      "fenceName": fenceText,
      "deviceId": selectedDevice?.deviceId,
      "address": city
    };

    let editPayload = {
      "latitude": latLocation,
      "longitude": logLocation,
      "fenceRadius": sliderVal,
      "action": action,
      "fenceName": fenceText,
      "address": city
    };

    if(sliderVal <= 0) {
      enqueueSnackbar("Range should be greater than zero.", {
        variant: "error",
      })
    }else {
      if (id) {
        updateGeoFenceAction(id, editPayload)
      } else {
        addGeoFenceAction(payload)
      }
    }
  }

  const milesToMeter = sliderVal * (1609)

  const handleSetSearchCoordinates = (location) =>{
    setLocationlat(location?.lat)
    setLocationlog(location?.lng)
  }

  console.log(milesToMeter, "fenceRad")

  return (
    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
      <Grid item xs={4} sm={8} md={8} >
        <div style={{ height: "60vh" }}>
          <MapComp zoom={13} location={{ lat: latLocation, lng: logLocation }} radius={milesToMeter} action={action} onMarkerDragEnd={onMarkerDragEnd} draggable={true} />
        </div>
      </Grid>
      <Grid item xs={4} sm={8} md={4} >
        <SubCard >
          <Grid item style={{ marginBottom: "1rem" }}>
            <button
              disableElevation
              variant={action === "Allow" ? 'contained' : 'text'}
              size="small"
              className={action === "Allow" ? 'bgGreen btn-switch-active' : ' btn-switch'}

              onClick={(e) => handleChangeAllow(e, false)}
            >
              Allowed
            </button>
            <button
              disableElevation
              variant={action === "Block" ? 'contained' : 'text'}
              size="small"
              className={action === "Block" ? 'bgRed btn-switch-active' : ' btn-switch'}
              onClick={(e) => handleChangeBlock(e, false)}
            >
              Forbidden
            </button>
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              label="Fence Name"
              margin="normal"
              name="firstName"
              type="text"
              defaultValue={filtered && filtered[0]?.fenceName}
              onChange={(event) =>
                setFenceName(event.target.value)
              }
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <PlacesAutocomplete  handleSetSearchCoordinates={handleSetSearchCoordinates} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Slider defaultValue={filtered && filtered[0]?.fenceRadius || 0} valueLabelFormat={value => <div>{(value) + "Miles"}</div>} max={10} min={0} step={0.1} sx={{ width: '99%', marginLeft: '3px', color: '#0e56a6' }} aria-label="Default" onChange={(e, val) => setSliderVal(val)} valueLabelDisplay="auto" />

          </Grid>

          <Box sx={{ mt: 2 }}>
          
              <LoadingButton
                disableElevation
                disabled={loading}
                loading={loading}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                 color="secondary"
                sx={{ backgroundColor: '#1c54a1' }}
                onClick={() => handleSubmitFormData()}
              >
                {paramsQuery("id") ? "Update" : "Add"}
              </LoadingButton>
            
          </Box>

        </SubCard>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = ({ childrenReducer, locationReducer, deviceReducer }) => ({
  selectedDevice: childrenReducer.selectedDevice,
  addFence: locationReducer.addFence,
  errMessage: locationReducer.errMessage,
  fenceList: locationReducer.fenceList,
  selectedDeviceDynamicInfo: deviceReducer?.selectedDeviceDynamicInfo,
  loading: locationReducer.loading,
});

const mapActionsToProps = {
  addGeoFenceAction,
  loadGeoFenceListAction,
  resetLocationState,
  updateGeoFenceAction
};

export default connect(mapStateToProps, mapActionsToProps)(AddGeoFence);