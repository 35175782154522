import { useEffect } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
import "./style.scss"
import React from 'react';
import { Helmet } from "react-helmet";

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery, Grid, Card, Button } from '@mui/material';

// project imports
import Customization from '../../Customization';
import navigation from 'menu-items';
import { drawerWidth } from 'store/constant';
import { SET_MENU } from 'store/actions/actions';
import SubCard from 'ui-component/cards/SubCard';
import { gridSpacing } from 'store/constant';
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
import DeleteIcon from '@mui/icons-material/Delete';
import { loadGeoFenceListAction, deleteFenceAction, resetLocationState } from 'store/actions';
import { useNavigate } from "react-router-dom";
import { useSnackbar } from 'notistack';
import Geocode from "react-geocode";

// assets
import { IconChevronRight } from '@tabler/icons';

Geocode.setApiKey("AIzaSyCSGIpUyG2dUSowKZMv6Wt3_xECq6IpYfU");
Geocode.setLanguage("en");

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = ({ loadGeoFenceListAction, fenceList, selectedDevice, deleteFenceAction, removeFence, errMessage, resetLocationState }) => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));


    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [action, setAction] = React.useState("Allow");
    const [address, setAddress] = React.useState("Allow");

    useEffect(() => {
        dispatch({ type: SET_MENU, opened: !matchDownMd });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd]);

    React.useEffect(() => {
        loadGeoFenceListAction(`deviceId=${selectedDevice?.deviceId}`);
    }, []);

    let allowList = fenceList?.filter(
        (status) => status.action === "Allow"
    );

    let blockList = fenceList?.filter(
        (status) => status.action === "Block"
    );



    const deleteFence = (fenceId) => {
        deleteFenceAction(fenceId)
    }

    console.log(fenceList, "allow")


    useEffect(() => {
        console.log(removeFence, "rem")
        if (removeFence) {
            enqueueSnackbar('Deleted Successful', {
                variant: "success",
            });
            loadGeoFenceListAction(`deviceId=${selectedDevice?.deviceId}`);
        }
        if (errMessage) {
            enqueueSnackbar(errMessage, {
                variant: "error",
            })
        }
        resetLocationState()
    }, [removeFence, errMessage])

    const editFence = (fenceId) => {
        navigate(
            `/user/add-geofence?id=${fenceId}`
        )
    }




    // ==============================||shadow box ||============================== //

    const ShadowBox = ({ shadow, fence }) => (

        <Grid>
            <Card sx={{ mb: 3, boxShadow: shadow, backgroundColor: "green" }}>
                <Box
                    sx={{

                        justifyContent: 'center',
                        alignItems: 'center',
                        py: 2.5,
                        bgcolor: 'primary.light',
                        color: 'grey.800',
                    }}
                >
                    <Grid container spacing={2} >
                        <Grid item xs={1} sm={2} className='cardSpace'>
                            <WhereToVoteIcon style={{ fontSize: "2.3rem", color: "#1e88e5" }} />
                        </Grid>
                        <Grid item xs={6} sm={6} onClick={() => editFence(fence?.fenceId)} style={{ cursor: "pointer" }}>
                            <div className='fenceName'>
                                <div style={{ fontWeight: "600" }}>{fence?.fenceName}</div>
                                <div className='fenseAddress'>{fence?.address}</div>

                                <div>Range : <span style={{ fontWeight: "600" }}>{fence?.fenceRadius} Miles</span></div>
                            </div>
                        </Grid>
                        <Grid item xs={4} sm={4} className='textCenter'>
                            <DeleteIcon style={{ fontSize: "2rem", color: '#e35656', cursor: "pointer" }} onClick={() => deleteFence(fence?.fenceId)} />
                        </Grid>

                    </Grid>
                </Box>
            </Card>
        </Grid>
    );

    ShadowBox.propTypes = {
        shadow: PropTypes.string.isRequired
    };

    const handleChangeAllow = () => {
        setAction("Allow");
    };

    const handleChangeBlock = () => {
        setAction("Block");
    };

    const handleAdd = () => {
        if (action === "Allow") {
            navigate('/user/add-geofence?status=allow')
        }
        else {
            navigate('/user/add-geofence?status=forbidden')
        }
    };

    return (
        <>
            <Grid item className=" floatRight" style={{ display: 'contents', }}>
                <Helmet>
                    <title>Cyber Safe Family - Geo Fencing</title>
                    <meta name="description" content="Nested component" />
                </Helmet>


                <section style={{ direction: 'rtl' }}>
                    <Button variant="contained" style={{ backgroundColor: '#0e56a6', color: 'white' }} onClick={(event) => {
                        handleAdd()
                    }}>
                        Add Geo fence
                    </Button>
                </section>
            </Grid>

            <Box style={{ marginTop: '1rem' }}>

                <Grid item xs={12} style={{ width: "100%" }}>


                    <SubCard >

                        <button
                            disableElevation
                            variant={action === "Allow" ? 'contained' : 'text'}
                            size="small"
                            className={action === "Allow" ? 'bgGreen btn-switch-active' : ' btn-switch'}

                            onClick={(e) => handleChangeAllow(e, false)}
                        >
                            Allowed
                        </button>
                        <button
                            disableElevation
                            variant={action === "Block" ? 'contained' : 'text'}
                            size="small"
                            className={action === "Block" ? 'bgRed btn-switch-active' : 'btn-switch'}
                            onClick={(e) => handleChangeBlock(e, false)}
                        >
                            Forbidden
                        </button>



                        {action === "Allow" && (
                            <Grid container spacing={gridSpacing} style={{ marginTop: '1rem' }}>

                                {allowList && allowList.map((data) => {
                                    console.log(data, "datasType")
                                    return (
                                        <Grid item xs={12} sm={6} md={4} lg={3}>
                                            <ShadowBox shadow="1" fence={data} />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        )

                        }

                        {action === "Block" && (
                            <Grid container spacing={gridSpacing} style={{ marginTop: '1rem' }}>

                                {blockList && blockList.map((data) => {
                                    console.log(data, "datas")
                                    return (
                                        <Grid item xs={12} sm={6} md={4} lg={3}>
                                            <ShadowBox shadow="1" fence={data} />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        )

                        }

                    </SubCard>
                </Grid>
                <Customization />
            </Box>
        </>
    );
};


const mapActionsToProps = {
    loadGeoFenceListAction,
    deleteFenceAction,
    resetLocationState
};

const mapStateToProps = ({ locationReducer, childrenReducer }) => ({
    fenceList: locationReducer.fenceList,
    selectedDevice: childrenReducer.selectedDevice,
    removeFence: locationReducer.removeFence,
    errMessage: locationReducer.errMessage

});

export default connect(mapStateToProps, mapActionsToProps)(MainLayout);
