import {
    LOAD_GEOFENCE,
    LOAD_GEOFENCE_SUCCESS,
    LOAD_GEOFENCE_FAILURE,
    GEO_LOCATION_LOG_INPROGRESS,
    GEO_LOCATION_LOG_COMPLETED,
    GEO_LOCATION_LOG_FAILED,
    ADD_FENCE,
    ADD_FENCE_SUCCESS,
    ADD_FENCE_FAILED,

    REMOVE_FENCE,
    REMOVE_FENCE_SUCCESS,
    REMOVE_FENCE_FAILED,

    LOC_STATE_RESET_COMPLETED,
    ALL_DEVICE_DYNAMIC_DATA

} from '../types';

export const initialState = {
    loading: false,
    locationLog: [],
    fenceList: [],
    addFence: null,
    errMessage: '',
    removeFence: null,
    allCurrentLocation:[]
};

// ==============================|| CHILDREN REDUCER ||============================== //

const customizationReducer = (state = initialState, action) => {
    switch (action.type) {
        case GEO_LOCATION_LOG_INPROGRESS:
            return {
                ...state,
                loading: true,
            };
        case GEO_LOCATION_LOG_COMPLETED:
            return {
                ...state,
                loading: false,
                locationLog: action.payload
            };
        case GEO_LOCATION_LOG_FAILED:
            return {
                ...state,
                loading: false
            };
        case LOAD_GEOFENCE:
            return {
                ...state,
                loading: true
            };
        case LOAD_GEOFENCE_SUCCESS:
            return {
                ...state,
                fenceList: action.payload,
                loading: false
            };
        case LOAD_GEOFENCE_FAILURE:
            return {
                ...state,
                loading: false
            };

        case ADD_FENCE:
            return {
                ...state,
                loading: true
            };
        case ADD_FENCE_SUCCESS:
            return {
                ...state,
                loading: false,
                addFence: action.payload
            };

        case ADD_FENCE_FAILED:
            return {
                ...state,
                loading: false,
                errMessage: action.payload
            };

        case REMOVE_FENCE:
            return {
                ...state,
                loading: true
            };
        case REMOVE_FENCE_SUCCESS:
            return {
                ...state,
                loading: false,
                removeFence: action.payload
            };
        case REMOVE_FENCE_FAILED:
            return {
                ...state,
                loading: false,
                errMessage: action.payload
            };
        case ALL_DEVICE_DYNAMIC_DATA:
            return {
                ...state,
                allCurrentLocation: [...state.allCurrentLocation, ...action.payload],
        };
        case LOC_STATE_RESET_COMPLETED:
                return { ...state, errMessage : '', removeFence:'', addFence:'',allCurrentLocation:[]};

        default:
            return state;
    }
};

export default customizationReducer;
