// assets
import { IconDashboard } from '@tabler/icons';

// constant
const icons = { IconDashboard };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const timeschedule = {
    id: 'timeschedule',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Time Scheduler',
            type: 'item',
            url: '/timeschedule',
            icon: icons.IconDashboard,
            breadcrumbs: false
        }
    ]
};

export default timeschedule;
