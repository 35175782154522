// import React, { useState, useRef, useEffect } from "react";
// import "./style.scss";

// import Mobile1 from "assets/mobile-manual/m1.jpg";
// import Mobile2 from "assets/mobile-manual/m2.jpg";
// import Mobile3 from "assets/mobile-manual/m3.jpg";
// import Mobile4 from "assets/mobile-manual/m4.jpg";
// import Mobile5 from "assets/mobile-manual/m5.png";
// import Mobile6 from "assets/mobile-manual/m6.png";
// import Mobile7 from "assets/mobile-manual/m7.jpg";
// import Mobile8 from "assets/mobile-manual/m8.jpg";
// import Mobile9 from "assets/mobile-manual/m9.jpg";
// import Mobile10 from "assets/mobile-manual/m10.jpg";
// import Mobile11 from "assets/mobile-manual/m11.jpg";
// import Mobile12 from "assets/mobile-manual/m12.jpg";
// import Mobile13 from "assets/mobile-manual/m13.jpg";
// import Mobile14 from "assets/mobile-manual/m14.png";
// import Mobile15 from "assets/mobile-manual/m15.png";
// import Mobile16 from "assets/mobile-manual/m16.jpg";
// import Mobile17 from "assets/mobile-manual/m17.jpg";
// import Mobile18 from "assets/mobile-manual/m18.png";
// import Mobile19 from "assets/mobile-manual/m19.jpg";
// import Mobile20 from "assets/mobile-manual/m20.png";
// import Mobile21 from "assets/mobile-manual/m21.jpg";
// import Mobile22 from "assets/mobile-manual/m22.png";
// import Mobile23 from "assets/mobile-manual/m23.jpg";
// import Mobile35 from "assets/mobile-manual/m37.png";
// import Mobile36 from "assets/mobile-manual/m38.png";
// import Mobile37 from "assets/mobile-manual/m39.png";
// import Mobile38 from "assets/mobile-manual/m40.jpg";
// import Mobile39 from "assets/mobile-manual/m41.png";
// import Mobile40 from "assets/mobile-manual/m42.jpg";
// import Mobile41 from "assets/mobile-manual/m43.png";
// import Mobile42 from "assets/mobile-manual/m44.jpg";
// import Mobile43 from "assets/mobile-manual/m46.png";
// import Mobile44 from "assets/mobile-manual/m47.jpg";
// import Mobile45 from "assets/mobile-manual/m48.jpg";
// import Mobile46 from "assets/mobile-manual/m49.jpg";
// import Mobile47 from "assets/mobile-manual/m50.png";
// import Mobile48 from "assets/mobile-manual/m51.jpg";
// import Mobile49 from "assets/mobile-manual/m52.png";
// import Mobile50 from "assets/mobile-manual/m53.jpg";
// import Mobile60 from "assets/mobile-manual/m63.jpg";
// import Mobile61 from "assets/mobile-manual/m66.png";
// import Mobile62 from "assets/mobile-manual/m67.png";
// import Mobile63 from "assets/mobile-manual/m68.png";
// import Mobile64 from "assets/mobile-manual/m69.jpg";
// import Mobile65 from "assets/mobile-manual/m70.png";
// import Mobile66 from "assets/mobile-manual/m71.png";
// import Mobile67 from "assets/mobile-manual/m72.png";
// import Mobile68 from "assets/mobile-manual/m73.jpg";
// import Mobile69 from "assets/mobile-manual/m74.png";
// import Mobile70 from "assets/mobile-manual/m75.jpg";
// import Mobile71 from "assets/mobile-manual/m76.png";
// import Mobile72 from "assets/mobile-manual/m77.jpg";
// import Mobile73 from "assets/mobile-manual/m78.png";
// import Mobile74 from "assets/mobile-manual/m79.jpg";
// import Mobile75 from "assets/mobile-manual/m80.png";
// import Mobile76 from "assets/mobile-manual/m81.jpg";
// import IosHome from "assets/mobile-manual/iosHome.png";
// import Dashboard from "assets/windows-manual/dash.jpg";
// import GeoMap from "assets/mobile-manual/geoMap.jpg";
// import HistoryList from "assets/mobile-manual/historyList.jpg";
// import ClearHistory from "assets/mobile-manual/clearHis.png";
// import Fence from "assets/mobile-manual/fence-ios.jpg";

// import { connect } from 'react-redux';
// import { fbGetDeviceAction, loadChildrenAction } from 'store/actions';
// import { Helmet } from "react-helmet";



// const Mobilemanual = ({
//   selectedChild,
//   selectedDevice,
//   loadChildrenAction

// }) => {

//   useEffect(() => {
//     loadChildrenAction();
//   }, [])

//   console.log(selectedChild?.selectedChild, "manualchild")

//   return (
//     <div className="mobile-container container-fluid">
//       <Helmet>
//         <title>Cyber Safe Family - Feature iOS Manual</title>
//         <meta name="description" content="Nested component" />
//       </Helmet>
//       <div>
//         <h1 style={{ color: "rgb(14, 86, 166)", textAlign: 'center' }}>Cyber Safe Family Mobile Devices Setup Guide</h1>
//         <br></br>
//         <div>
//           <h2 style={{ color: "rgb(14, 86, 166)" }}>Registration</h2>

//           <div className="fontStyles">

//             <ol>
//               <li>The user will be  able to register themself as a parent using registration by entering their Name, Phone number, Mail id and also a password to login inside the account once created.
//               </li>
//               <li>After Successful verification of OTP from both Mobile and Mail we will be able to login as a parent with the registered credentials.
//               </li>
//             </ol>
//           </div>
//         </div>

//         <div>
//           <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to register</h2>
//           <div className="fontStyles">

//             <ol>
//               <li>Click Signup from the landing page.</li>
//               <br></br>
//               <div>
//                 <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile1}></img>
//               </div>
//               <br></br>

//               <li>You will be redirected to the registration form page.</li>
//               <br></br>
//               <div>
//                 <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile2}></img>
//               </div>
//               <br></br>
//               <li>Once you give the details and click sign up you will be redirected to the OTP verification page.</li>

//               <br></br>
//               <div>
//                 <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile3}></img>
//               </div>
//               <br></br>
//               <li>And when you enter the correct OTP you will be redirected back to the login page.</li>
//               <li>In the login page you can enter the registered login credentials and login inside the application.</li>

//               <br></br>
//               <div>
//                 <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile4}></img>
//               </div>
//               <br></br>
//             </ol>
//           </div>
//         </div>




//         <div>
//           <h2 style={{ color: "rgb(14, 86, 166)" }}>Re-Registering for wrong details entered users</h2>

//           <div className="fontStyles">

//             <ol>
//               <li>While registering if the Email id / Mobile number entered is wrong and if OTP is not verified, in this case we would be able to correct the credentials and be able to register again.

//               </li>
//               <li>But if the OTP is verified already then no changes can be done there.

//               </li>
//             </ol>
//           </div>
//         </div>



//         <div>
//           <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to Re-Register</h2>
//           <div className="fontStyles">

//             <ol>
//               <li>Click Signup from the landing page.</li>
//               <br></br>
//               <div>
//                 <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile1}></img>
//               </div>
//               <br></br>

//               <li>You will be redirected to the registration form page.</li>
//               <br></br>
//               <div>
//                 <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile2}></img>
//               </div>
//               <br></br>
//               <li>Once you give the details and click sign up you will be redirected to the OTP verification page.</li>

//               <br></br>
//               <div>
//                 <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile3}></img>
//               </div>
//               <br></br>
//               <li>By any case, if you have given any wrong information related to phone number or Mail id, You can click the back button at the top left of the application.
//               </li>
//               <li>That will redirect you to be out of the application.</li>
//               <li>And you can Re-Register by doing the same signup process.</li>

//               <br></br>
//               <div>
//                 <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile5}></img>
//               </div>
//               <br></br>
//             </ol>
//           </div>
//         </div>

//         <div>
//           <h2 style={{ color: "rgb(14, 86, 166)" }}>Login</h2>

//           <div className="fontStyles">

//             <ol>
//               <li>If the User is an registered parent, then they can  use their registered credentials to login inside the application.
//               </li>

//             </ol>
//           </div>
//         </div>

//         <div>
//           <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to Login</h2>
//           <div className="fontStyles">

//             <ol>
//               <li>Click on the Login button.</li>
//               <br></br>
//               <div>
//                 <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile1}></img>
//               </div>
//               <br></br>

//               <li>You will be redirected to the login page.</li>
//               <br></br>
//               <div>
//                 <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile4}></img>
//               </div>
//               <br></br>
//               <li>On entering correct registered credentials, you will be successfully logged in inside the application.
//               </li>


//             </ol>
//           </div>
//         </div>

//         <div>
//           <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to Login for non OTP verified accounts</h2>
//           <div className="fontStyles">

//             <ol>
//               <li>Click on the Login button.</li>
//               <br></br>
//               <div>
//                 <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile1}></img>
//               </div>
//               <br></br>

//               <li>You will be redirected to the login page.</li>
//               <li>Enter the credentials which you have used for registration without verifying the OTP.</li>
//               <br></br>
//               <div>
//                 <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile4}></img>
//               </div>
//               <br></br>
//               <li>Once you click login you will be redirected to the OTP screen for verification.

//               </li>
//               <br></br>
//               <div>
//                 <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile3}></img>
//               </div>
//               <br></br>
//               <li>And when you enter the correct OTP you will be redirected back to the login page.</li>
//               <li>In the login page you can enter the registered login credentials and login inside the application.
//               </li>


//             </ol>
//           </div>
//         </div>

//         <div>
//           <h2 style={{ color: "rgb(14, 86, 166)" }}>Forgot Password</h2>

//           <div className="fontStyles">

//             <ul>
//               <li>If the user forgot his login password this option is the only way to change the password and Login into the account.
//               </li>

//             </ul>
//           </div>
//         </div>


//         <div>
//           <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps for Forgot Password</h2>
//           <div className="fontStyles">

//             <ol>
//               <li>Click on Forgot password in the login Screen.</li>
//               <br></br>
//               <div>
//                 <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile6}></img>
//               </div>
//               <br></br>

//               <li>In the Forgot Password page enter the registered Mobile number or Email id
//               </li>
//               <br></br>
//               <div>
//                 <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile7}></img>
//               </div>
//               <br></br>
//               <li>An otp will be send to the entered number or mail id and you will be redirected to the OTP verification page.
//               </li>
//               <li>Enter the received OTP.
//               </li>
//               <br></br>
//               <div>
//                 <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile8}></img>
//               </div>
//               <br></br>
//               <li> <li>There you can update the password.
//               </li></li>
//               <br></br>
//               <div>
//                 <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile9}></img>
//               </div>
//               <br></br>
//               <li>You will be redirected to the Login page.

//               </li>

//               <li>There enter the user name and the changed password to login inside the application.
//               </li>
//               <br></br>
//               <div>
//                 <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile4}></img>
//               </div>
//               <br></br>


//             </ol>
//           </div>
//         </div>

//         <div id="dashboard">
//           <h2 style={{ color: "rgb(14, 86, 166)" }}>Add Child</h2>
//           <ul className="fontStyles">
//             <li>
//               We will be able to create multiple childs with their specific names
//               using this option.
//             </li>
//             <li>
//               Using which we will be able to identify and manage each child along
//               with their devices separately.
//             </li>
//           </ul>
//         </div>

//         <div>
//           <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to Add Child</h2>

//           <div className="fontStyles">
//             <ol>
//               <li>Click “Add Child”</li>
//               <br></br>
//               <div>
//                 <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile10}></img>
//               </div>
//               <br></br>
//               <li>
//                 In the Add Child info page, Add the information about the child.
//               </li>
//               <li>Enter the age.</li>
//               <li>Select the type of device of the Child.</li>
//               <br></br>
//               <div>
//                 <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile13}></img>
//               </div>
//               <br></br>
//               <li>
//                 On clicking continue you will be redirected to the Profile image uploading page.

//               </li>
//               <li>You can upload using camera or by selecting from the library.</li>
//               <br></br>
//               <div>
//                 <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile11}></img>
//               </div>
//               <br></br>
//               <li>After uploading the profile image, and If you click continue you will be redirected to the home page along with a popup displaying the log code for child device syncing.</li>
//               <br></br>
//               <div>
//                 <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile12}></img>
//               </div>
//               <br></br>
//             </ol>
//           </div>
//         </div>


//         <div >
//           <h2 style={{ color: "rgb(14, 86, 166)" }}>Add Device</h2>
//           <ul className="fontStyles">
//             <li>
//               The  device of the child can be synced with the parent device by using the log code from the parent.
//             </li>
//             <li>
//               There are two ways to add device in the application.
//             </li>
//           </ul>
//         </div>

//         <div>
//           <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to Add Device</h2>
//           <div className="fontStyles">
//             <ol>
//               <li>One way is to use the log code from the popup which is displayed when we add child.</li>


//               <li>
//                 The other way is to use the Add device button near to the child’s name.
//               </li>
//               <li>On clicking which the logcode popup will be displayed. </li>
//               <br></br>
//               <div>
//                 <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile14}></img>
//               </div>
//               <br></br>
//             </ol>
//           </div>
//         </div>

//         <div >
//           <h2 style={{ color: "rgb(14, 86, 166)" }}>Edit / Delete Child</h2>
//         </div>

//         <div>
//           <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to Edit Child</h2>
//           <div className="fontStyles">
//             <ol>
//               <li>Click on the edit icon next to add device.</li>
//               <br></br>
//               <div>
//                 <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile15}></img>
//               </div>
//               <br></br>

//               <li>
//                 In the Edit Child screen you will be able to edit the  details of the child and update it.
//               </li>

//               <br></br>
//               <div>
//                 <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile16}></img>
//               </div>
//               <br></br>
//             </ol>
//           </div>
//         </div>

//         <div >
//           <h2 style={{ color: "rgb(14, 86, 166)" }}>Delete Device</h2>
//         </div>

//         <div>
//           <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to Delete Child</h2>
//           <div className="fontStyles">
//             <ol>
//               <li>Click on the delete  icon next to the battery icon.</li>
//               <br></br>
//               <div>
//                 <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile18}></img>
//               </div>
//               <br></br>

//               <li>
//                 In the Edit Child screen you will be able to edit the  details of the child and update it.
//               </li>

//               <br></br>
//               <div>
//                 <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile16}></img>
//               </div>
//               <br></br>
//               <li>On the confirmation popup click yes.</li>
//               <li>The child will be deleted from the account.</li>
//               <br></br>
//               <div>
//                 <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile17}></img>
//               </div>
//               <br></br>
//             </ol>
//           </div>
//         </div>


//         <div>
//           <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to Delete Device</h2>
//           <div className="fontStyles">
//             <ol>
//               <li>Click on the edit icon next to add device.</li>
//               <br></br>
//               <div>
//                 <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile18}></img>
//               </div>
//               <br></br>

//               <li>
//                 On the displayed confirmation popup, click yes.

//               </li>


//               <li>The child will be deleted from the account.</li>
//               <br></br>
//               <div>
//                 <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile19}></img>
//               </div>
//               <br></br>
//             </ol>
//           </div>
//         </div>

//         <div >
//           <h2 style={{ color: "rgb(14, 86, 166)" }}>Clear All - Home</h2>
//         </div>

//         <div>
//           <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to Clear all Child</h2>
//           <div className="fontStyles">
//             <ol>
//               <li>Click on clear all at the top right of the screen.</li>
//               <br></br>
//               <div>
//                 <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile20}></img>
//               </div>
//               <br></br>

//               <li>
//                 On the displayed confirmation popup, click yes.

//               </li>


//               <li>All the child and devices connected / created in the device will be cleared.</li>
//               <br></br>
//               <div>
//                 <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile21}></img>
//               </div>
//               <br></br>
//             </ol>
//           </div>
//         </div>

//         <div >
//           <h2 style={{ color: "rgb(14, 86, 166)" }}>Viewing Feature details</h2>
//         </div>


//         <div>
//           <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to view the features</h2>
//           <div className="fontStyles">
//             <ol>
//               <li>Click on the side arrow to the right of edit icon.</li>
//               <br></br>
//               <div>
//                 <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile22}></img>
//               </div>
//               <br></br>

//               <li>
//                 It will redirect to the screen where the features of the application will be displayed.

//               </li>
//               <br></br>
//               <div>
//                 <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={IosHome}></img>
//               </div>
//               <br></br>
//             </ol>
//           </div>
//         </div>

//         <div id="location">
//           <h2 style={{ color: "rgb(14, 86, 166)" }}>Location</h2>
          
//         </div>


//         <div >
//           <h2 style={{ color: "rgb(14, 86, 166)" }}>Geo Location</h2>
//           <ul className="fontStyles">
//             <li>
//               Under history the various locations travelled by the child will be listed
//             </li>
//           </ul>
//         </div>


//         <div>
//           <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to view History</h2>
//           <div className="fontStyles">
//             <ol>
//               <li>Click on history in the bottom left </li>

//               <br></br>
//               <div>
//                 <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={GeoMap}></img>
//               </div>
//               <br></br>
//               <li>
//                 You will be able to view the locations of the child along with the current location in the map.
//               </li>

//               <br></br>
//               <div>
//                 <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={HistoryList}></img>
//               </div>

//             </ol>
//           </div>
//         </div>

//         <div>
//           <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to clear History</h2>
//           <div className="fontStyles">
//             <ol>
//               <li>Click on history in the bottom left </li>
//               <br></br>
//               <div>
//                 <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={GeoMap}></img>
//               </div>
//               <br></br>
//               <li>
//                 Click on the clear button at the top right.
//               </li>

//               <br></br>
//               <div>
//                 <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={ClearHistory}></img>
//               </div>
//               <br></br>
//               <li>
//                 In the confirmation popup confirm the action.
//               </li>
//               <li>
//                 The history will be cleared.
//               </li>

//               <br></br>
//               <div>
//                 <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile42}></img>
//               </div>
//               <br></br>

//             </ol>
//           </div>
//         </div>

//         <div >
//           <h2 style={{ color: "rgb(14, 86, 166)" }}>Geo Fencing</h2>
//           <ul className="fontStyles">
//             <li>
//               The places where we want our child to be forbidden to be gone and the area where they are only allowed can be marked here.
//             </li>
//             <li>
//               On crossing those areas, the parent will be receiving SMS notifications as well as internal notification.
//             </li>

//           </ul>
//         </div>


//         <div>
//           <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to create Fence</h2>
//           <div className="fontStyles">
//             <ol>
//               <li>Click Create new fence</li>
//               <br></br>
//               <div>
//                 <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Fence}></img>
//               </div>
//               <br></br>
//               <li>
//                 Long press on the desired location.

//               </li>

//               <br></br>
//               <div>
//                 <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile44}></img>
//               </div>
//               <br></br>
//               <li>
//                 Enter the name of the fence.
//               </li>
//               <li>
//                 Mark the fence area.
//               </li>

//               <br></br>
//               <div>
//                 <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile45}></img>
//               </div>
//               <br></br>
//               <li>Click done.</li>
//               <li>The list can be viewed in the respective screens.</li>

//               <br></br>
//               <div>
//                 <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile46}></img>
//               </div>
//               <br></br>
//             </ol>
//           </div>
//         </div>


//         <div>
//           <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to Edit Fence</h2>
//           <div className="fontStyles">
//             <ol>
//               <li>Click the desired fence.</li>
//               <li>
//                 Make the changes

//               </li>
//               <li>Click done</li>
//             </ol>
//           </div>
//         </div>

//         <div>
//           <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to Delete Fence</h2>
//           <div className="fontStyles">
//             <ol>
//               <li>Click on the trash icon in the right of the fence.</li>
//               <li>Confirm the process.</li>
//               <li>The fence will be removed from the list</li>
//             </ol>
//           </div>
//         </div>

//         <div >
//           <h2 style={{ color: "rgb(14, 86, 166)" }}>Drive Safe</h2>
//           <ul className="fontStyles">
//             <li>
//               Under drive safe, you will be able to set the driving speed limit for the child.
//             </li>
//             <li>
//               If the child exceeds the limit, notification will be received to the parent.
//             </li>

//           </ul>
//         </div>





//         <div>
//           <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to set speed limit</h2>
//           <div className="fontStyles">
//             <ol>
//               <li>Click on the arrow to the right of drive safe.</li>
//               <br></br>
//               <div>
//                 <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile47}></img>
//               </div>
//               <br></br>
//               <li>
//                 Set the speed limit
//               </li>

//               <br></br>
//               <div>
//                 <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile48}></img>
//               </div>
//               <br></br>
//               <li>
//                 Click save.

//               </li>

//             </ol>
//           </div>
//         </div>


//         <div >
//           <h2 style={{ color: "rgb(14, 86, 166)" }}>Child Map</h2>
//           <ul className="fontStyles">
//             <li>
//               We will be able to locate all the child devices linked with that particular parent account.
//             </li>
//             <li>
//               Every device will be visible in the map with their profile image.
//             </li>

//           </ul>
//         </div>

//         <div>
//           <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to view Child Map</h2>
//           <div className="fontStyles">
//             <ol>
//               <li>Click on the arrow to the right of Child Map.</li>
//               <br></br>
//               <div>
//                 <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile49}></img>
//               </div>
//               <br></br>
//               <li>
//                 You will be able to view the children’s location along with their profile image in the Map.
//               </li>

//               <br></br>
//               <div>
//                 <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile50}></img>
//               </div>
//               <br></br>
//             </ol>
//           </div>
//         </div>

//         <div id="">
//           <h2 style={{ color: "rgb(14, 86, 166)" }}>Time Scheduler </h2>
//           <ul className="fontStyles">
//            <li>"Only when we go through portal the features are accessible"</li>
//           </ul>
//         </div>
//         <div id="">
//           <h2 style={{ color: "rgb(14, 86, 166)" }}>App filtering</h2>
//           <ul className="fontStyles">
//             <li>
//             "Only when we go through portal the features are accessible"
//             </li>
//           </ul>
//         </div>
//         <div id="">
//           <h2 style={{ color: "rgb(14, 86, 166)" }}>App usage </h2>
//           <ul className="fontStyles">
//             <li>
//             "Only when we go through portal the features are accessible"
//             </li>
//           </ul>
//         </div>
//         <div id="">
//           <h2 style={{ color: "rgb(14, 86, 166)" }}>Web filtering</h2>
//           <ul className="fontStyles">
//             <li>
//             "Only when we go through portal the features are accessible"
//             </li>
//           </ul>
//         </div>

//         <div id="settings">
//           <h2 style={{ color: "rgb(14, 86, 166)" }}>Home</h2>
//           <ul className="fontStyles">
//             <li>
//               On clicking home, it redirects us to the landing page where we can Add child, Add device as well as see battery percentage of the child device.
//             </li>

//             <br></br>
//             <div>
//               <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile60}></img>
//             </div>
//             <br></br>
//           </ul>
//         </div>

//         <div >
//           <h2 style={{ color: "rgb(14, 86, 166)" }}>My Profile</h2>
//           <ul className="fontStyles">
//             <li>
//               On Clicking My profile, You will be able to see the list of items where we will be able to edit the profile, change password and contact with the developer.
//             </li>

//             <br></br>
//             <div>
//               <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile61}></img>
//             </div>
//             <br></br>
//           </ul>
//         </div>


//         <div >
//           <h2 style={{ color: "rgb(14, 86, 166)" }}>My Profile - Edit</h2>
//           <ul className="fontStyles">
//             <li>
//               We will be able to edit  the parents profile details.
//             </li>
//           </ul>
//         </div>

//         <div>
//           <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps for edit parent profile</h2>
//           <div className="fontStyles">
//             <ol>
//               <li>Click on the pencil icon over the profile image.</li>
//               <br></br>
//               <div>
//                 <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile62}></img>
//               </div>
//               <br></br>
//               <li>
//                 Make changes.
//               </li>

//               <br></br>
//               <div>
//                 <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile63}></img>
//               </div>
//               <br></br>
//               <li>Enter the OTP received.</li>
//               <li>The changes will be saved.</li>
//               <br></br>
//               <div>
//                 <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile64}></img>
//               </div>
//               <br></br>

//             </ol>
//           </div>
//         </div>

//         <div >
//           <h2 style={{ color: "rgb(14, 86, 166)" }}>My Profile - Parent Device</h2>
//           <ul className="fontStyles">
//             <li>
//               Click on Parent Profile
//             </li>

//             <br></br>
//             <div>
//               <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile65}></img>
//             </div>
//             <br></br>
//             <li>you will be able to view the list of parents linked with the account.</li>
//             <br></br>
//             <div>
//               <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile66}></img>
//             </div>
//             <br></br>
//           </ul>
//         </div>


//         <div >
//           <h2 style={{ color: "rgb(14, 86, 166)" }}>Adding New Parent</h2>
//           <ul className="fontStyles">
//             <li>
//               We will be able to add new parents in this screens.

//             </li>
//           </ul>
//         </div>


//         <div >
//           <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps for adding new parent </h2>
//           <ol className="fontStyles">
//             <li>
//               Click on the add new parent / the profile icon in the top of home.
//             </li>

//             <br></br>
//             <div>
//               <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile67}></img>
//             </div>
//             <br></br>
//             <li>Enter the email id/phone number.</li>
//             <br></br>
//             <div>
//               <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile68}></img>
//             </div>
//             <br></br>
//             <li>On confirming the invitation link will be shared.</li>
//           </ol>
//         </div>

//         <div >
//           <h2 style={{ color: "rgb(14, 86, 166)" }}>My Profile - Change Password</h2>
//           <ul className="fontStyles">
//             <li>
//               The password of the profile can be changed with this option.
//             </li>
//           </ul>
//         </div>

//         <div >
//           <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to change password</h2>
//           <ol className="fontStyles">
//             <li>
//               Click on Change password.
//             </li>

//             <br></br>
//             <div>
//               <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile69}></img>
//             </div>
//             <br></br>
//             <li>Enter the details</li>
//             <br></br>
//             <div>
//               <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile70}></img>
//             </div>
//             <br></br>
//             <li>The changes will be saved on confirming.</li>
//           </ol>
//         </div>

//         <div >
//           <h2 style={{ color: "rgb(14, 86, 166)" }}>My Profile - Contact With us</h2>
//           <ul className="fontStyles">
//             <li>
//               The Administrator can be contacted using this option.
//             </li>
//           </ul>
//         </div>

//         <div >
//           <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to change password</h2>
//           <ol className="fontStyles">
//             <li>
//               Click on Contact with us.
//             </li>

//             <br></br>
//             <div>
//               <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile71}></img>
//             </div>
//             <br></br>
//             <li>Enter the details</li>
//             <br></br>
//             <div>
//               <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile72}></img>
//             </div>
//             <br></br>
//             <li>On confirming a mail will be send to the administrator with your query.</li>
//           </ol>
//         </div>

//         <div >
//           <h2 style={{ color: "rgb(14, 86, 166)" }}>My Profile - Terms & Privacy Policy </h2>
//           <ul className="fontStyles">
//             <li>
//               On Clicking which you will be able to view the terms and policies of the application.
//             </li>
//           </ul>
//         </div>

//         <div >
//           <h2 style={{ color: "rgb(14, 86, 166)" }}>My Profile - Help</h2>
//           <ul className="fontStyles">
//             <li>
//               On Clicking which you will be able to view the FAQs related to the application.
//             </li>
//           </ul>
//         </div>

//         <div >
//           <h2 style={{ color: "rgb(14, 86, 166)" }}>Notification</h2>
//           <ul className="fontStyles">
//             <li>
//               We will be able to view the notifications  received by the child.
//             </li>
//             <br></br>
//             <div>
//               <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile73}></img>
//             </div>
//             <br></br>
//             <li>Also by clicking Clear All at the top, we will be able to select and delete the notifications.</li>
//             <br></br>
//             <div>
//               <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile74}></img>
//             </div>
//             <br></br>
//           </ul>
//         </div>


//         <div >
//           <h2 style={{ color: "rgb(14, 86, 166)" }}>Logout</h2>
//           <ul className="fontStyles">
//             <li>
//               Click on Log out.
//             </li>
//             <br></br>
//             <div>
//               <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile75}></img>
//             </div>
//             <br></br>
//             <li>On the popup, confirm the action.</li>
//             <br></br>
//             <div>
//               <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile76}></img>
//             </div>
//             <br></br>
//             <li>You will be logged out of the profile.</li>
//           </ul>
//         </div>


//       </div>

//     </div>
//   );
// };
// const mapStateToProps = ({ childrenReducer, deviceReducer }) => ({

//   selectedChild: childrenReducer.selectedChild,
//   selectedDevice: deviceReducer.selectedDevice,

// });

// const mapActionsToProps = {

//   // setSelectedChildAction,
//   // setSelectedDeviceAction,
//   loadChildrenAction

// };

// export default connect(mapStateToProps, mapActionsToProps)(Mobilemanual);



import React, { useState, useRef, useEffect } from "react";
import "./style.scss";
import Register from "assets/manual/register.png";
import RegisterPage from "assets/manual/registerpage.png";
import Verification from "assets/manual/verification.png";
import Login from "assets/manual/login.png";
import ForgotPsw from "assets/manual/forgotpsw.png";
import Forgot from "assets/manual/forgot.png";
import VerifyPsw from "assets/manual/verifypsw.png";
import UpdatePsw from "assets/manual/updatepsw.png";
import NotificationClear from "assets/manual/clearnotify.png";
import Logout from "assets/manual/logout.png";
import LogoutAlert from "assets/manual/logoutalert.png";
import Terms from "assets/manual/terms.png";
import { Helmet } from "react-helmet";


import Portal1 from "assets/mobile-manual/portal1.png";
import Portal2 from "assets/mobile-manual/portal2.png";
import Portal3 from "assets/mobile-manual/portal3.png";
import Portal4 from "assets/mobile-manual/portal4.png";
import Portal5 from "assets/mobile-manual/portal5.png";
import Portal6 from "assets/mobile-manual/portal6.png";
import Portal7 from "assets/mobile-manual/portal7.png";
import Portal8 from "assets/mobile-manual/portal8.png";
import Portal9 from "assets/mobile-manual/portal9.png";
import Portal10 from "assets/mobile-manual/portal10.png";
import Portal11 from "assets/mobile-manual/portal11.png";
import Portal12 from "assets/mobile-manual/portal12.png";
import Portal13 from "assets/mobile-manual/portal13.png";
import Portal14 from "assets/mobile-manual/portal14.png";
import Portal15 from "assets/mobile-manual/portal15.png";
import Portal16 from "assets/mobile-manual/portal16.png";
import Portal17 from "assets/mobile-manual/portal17.png";
import Portal18 from "assets/mobile-manual/portal18.png";
import Portal19 from "assets/mobile-manual/portal19.png";
import Portal20 from "assets/mobile-manual/portal20.png";
import Portal21 from "assets/mobile-manual/portal21.png";
import Portal22 from "assets/mobile-manual/portal22.png";
import Portal23 from "assets/mobile-manual/portal23.png";
import Portal24 from "assets/mobile-manual/portal24.png";
import Portal25 from "assets/mobile-manual/portal25.png";
import Portal26 from "assets/mobile-manual/portal26.png";
import Portal27 from "assets/mobile-manual/portal27.png";
import Portal28 from "assets/mobile-manual/portal28.png";
import Portal29 from "assets/mobile-manual/portal29.png";
import Portal30 from "assets/mobile-manual/portal30.png";
import Portal31 from "assets/mobile-manual/portal31.png";
import Portal32 from "assets/mobile-manual/portal32.png";
import Portal33 from "assets/mobile-manual/portal33.png";
import Portal34 from "assets/mobile-manual/portal34.png";
import Portal35 from "assets/mobile-manual/portal35.png";
import Portal36 from "assets/mobile-manual/portal36.png";
import Portal37 from "assets/mobile-manual/portal37.png";
import Portal38 from "assets/mobile-manual/portal38.png";






export default function Manual() {

  return (
    <div className="manual-container container-fluid">
        <Helmet>
                <title>Cyber Safe Family - Manual</title>
                <meta name="description" content="Nested component" />
            </Helmet>
      <div>
        <h1 style={{ color: "rgb(14, 86, 166)", textAlign: 'center' }}>Cyber Safe Family Setup Guide</h1>
        <div>
          <br></br>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Register</h2>
          <ul className="fontStyles">
            <li>
              The user will be  able to register themself as a parent using registration by entering their Name, Phone number, Mail id and also a password to login inside the account once created.
            </li>
            <li>
              After Successful verification of OTP from both Mobile and Mail we will be able to login as a parent with the registered credentials.
            </li>
          </ul>
        </div>
        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to register</h2>

          <div className="fontStyles">
            <ol>
              <li>Click Register from the landing page.</li>
              <div>
                <img style={{ width: "100%" }} src={Register}></img>
              </div>

              <li>
                You will be redirected to the registration form page.
              </li>
              <div>
                <img style={{ width: "100%" }} src={RegisterPage}></img>
              </div>
              <li>Once you give the details and click sign up you will be redirected to the OTP verification page.</li>
              <div>
                <img style={{ width: "100%" }} src={Verification}></img>
              </div>
              <li>And when you enter the correct OTP you will be redirected back to the login page.</li>

              <div>
                <img style={{ width: "100%" }} src={Login}></img>
              </div>
            </ol>
          </div>
        </div>
        <div>
          <br></br>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Login</h2>
          <ul className="fontStyles">
            <li>
              If the User is an registered parent, then they can  use their registered credentials to login inside the application.
            </li>
          </ul>
        </div>
        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to Login</h2>

          <div className="fontStyles">
            <ol>
              <li>On entering correct registered credentials, you will be successfully logged in inside the application.</li>
              <div>
                <img style={{ width: "100%" }} src={Login}></img>
              </div>
            </ol>
          </div>
        </div>
        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to Login for non OTP verified accounts</h2>

          <div className="fontStyles">
            <ol>
              <li>Enter the credentials which you have used for registration without verifying the OTP in Login.</li>
              <div>
                <img style={{ width: "100%" }} src={Login}></img>
              </div>
              <li>Once you click login you will be redirected to the OTP screen for verification.</li>
              <div>
                <img style={{ width: "100%" }} src={Verification}></img>
              </div>
              <li>And when you enter the correct OTP you will be redirected back to the login page.</li>
              <li>In the login page you can enter the registered login credentials and login inside the application.</li>
              <div>
                <img style={{ width: "100%" }} src={Login}></img>
              </div>
            </ol>
          </div>
        </div>
        <div>
          <br></br>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Forgot Password</h2>
          <ul className="fontStyles">
            <li>
              If the user forgot his login password this option is the only way to change the password and Login into the account.
            </li>
          </ul>
        </div>
        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps for Forgot Password</h2>

          <div className="fontStyles">
            <ol>
              <li>Click on Forgot password in the login Screen.</li>
              <div>
                <img style={{ width: "100%" }} src={ForgotPsw}></img>
              </div>
              <li>In the Forgot Password page enter the registered Mobile number or Email id</li>
              <div>
                <img style={{ width: "100%" }} src={Forgot}></img>
              </div>
              <li>An otp will be send to the entered number or mail id and you will be redirected to the OTP verification page.</li>
              <div>
                <img style={{ width: "100%" }} src={VerifyPsw}></img>
              </div>
              <li>Enter the received OTP.</li>
              <li>You will be redirected to the change password page.</li>
              <div>
                <img style={{ width: "100%" }} src={UpdatePsw}></img>
              </div>
              <li>Once you update the password you will be redirect to Login page</li>
              <li>There enter the user name and the changed password to login inside the application.</li>
              <div>
                <img style={{ width: "100%" }} src={Login}></img>
              </div>
            </ol>
          </div>
        </div>
        <div id="dashboard">
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Add Child</h2>
          <ul className="fontStyles">
            <li>
              We will be able to create multiple childs with their specific names
              using this option.
            </li>
            <li>
              Using which we will be able to identify and manage each child along
              with their devices separately.
            </li>
          </ul>
        </div>
        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to Add Child</h2>

          <div className="fontStyles">
            <ol>
              <li>Click “Add Child”</li>
              <div>
                <img style={{ width: "100%" }} src={Portal1}></img>
              </div>

              <li>
                In the Add Child popup, Add the information about the child.
              </li>
              <li>Enter the age.</li>
              <li>Select the type of device of the Child.</li>

              <div>
                <img style={{ width: "100%" }} src={Portal2}></img>
              </div>
              <li>
                If you click continue you will be able to view a popup displaying
                the log code for child device syncing and the child would have
                been added to the application.
              </li>
              <div>
                <img style={{ width: "100%" }} src={Portal3}></img>
              </div>
            </ol>
          </div>
        </div>
        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to Add Device</h2>

          <div className="fontStyles">
            <ol>
              <li>
                The device of the child can be synced with the parent device by
                using the log code from the parent.
              </li>

              <li>
                One way is to use the log code from the popup which is displayed
                when we add child.
              </li>
              <div>
                <img style={{ width: "100%" }} src={Portal3}></img>
              </div>

              <li>
                The other way is to use the Add device button 
                
              </li>

              <div>
                <img style={{ width: "100%" }} src={Portal4}></img>
              </div>
              <li>
                If you click continue you will be able to view a popup displaying
                the log code for child device syncing and the child would have
                been added to the application.
              </li>
              <li>On clicking which the log code popup will be displayed.</li>
              <div>
                <img style={{ width: "100%" }} src={Portal3}></img>
              </div>
            </ol>
          </div>
        </div>
        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Edit / Delete Child</h2>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to edit child</h2>

          <div className="fontStyles">
            <ol>
              <li>
                Click on the edit icon next to the child’s name.

              </li>
              <div>
                <img style={{ width: "100%" }} src={Portal5}></img>
              </div>

              <li>
                In the Edit Child screen you will be able to edit the  details of the child and update it.
              </li>

              <div>
                <img style={{ width: "100%" }} src={Portal6}></img>
              </div>
            </ol>
          </div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to Delete child</h2>
          <div className="fontStyles">
            <ol>
              <li>
                Click on the trash icon next to edit icon.

              </li>
              <div>
                <img style={{ width: "100%" }} src={Portal7}></img>
              </div>

              <li>
                On the confirmation popup click yes.
              </li>
              <li>The child will be deleted from the account.
              </li>

              <div>
                <img style={{ width: "100%" }} src={Portal8}></img>
              </div>
            </ol>
          </div>
        </div>
        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Delete Device</h2>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to delete device</h2>
          <div className="fontStyles">
            <ol>
              <li>Click on the delete  icon next to the battery icon</li>
              <div>
                <img style={{ width: "100%" }} src={Portal9}></img>
              </div>
              <li>On the displayed confirmation popup, click yes</li>
              <li>The device will be removed from the child.</li>
              <div>
                <img style={{ width: "100%" }} src={Portal10}></img>
              </div>
            </ol>
          </div>
        </div>
        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Clear All - Dashboard</h2>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to Clear all Child</h2>
          <div className="fontStyles">
            <ol>
              <li>Click on clear all at the top right of the screen.</li>
              <div>
                <img style={{ width: "100%" }} src={Portal11}></img>
              </div>
              <li>On the confirmation popup, click yes.</li>
              <li>All the child and devices connected / created in the device will be cleared.</li>
              <div>
                <img style={{ width: "100%" }} src={Portal12}></img>
              </div>
            </ol>
          </div>
        </div>
      </div>

      <div id="location">
        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Location</h2>
          <ul className="fontStyles">
            <li>
              Location is divided into four Subs.
            </li>
            <li>
              Geo Location
            </li>
            <li>
              Geo Fencing
            </li>
            <li>
              Drive Safe
            </li>
            <li>
              Child Map
            </li>
          </ul>
        </div>
        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to view Location Subs</h2>
          <ol className="fontStyles">
            <li>Click on the arrow to the right of the location.</li>
            <div>
              <img style={{ width: "100%" }} src={Portal13}></img>
            </div>
            <li>In the dropdown list you will be able to view the sub categories mentioned.</li>
            <div>
              <img style={{ width: "100%" }} src={Portal14}></img>
            </div>
          </ol>
        </div>
        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Location → Geo Location</h2>
          <div className="fontStyles">
            <ul>
              Under Geo location, we will be able to view the child’s location, wherever they have traveled and also can see the current location.
            </ul>

          </div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to view Geo Location</h2>
          <ol className="fontStyles">
            <li>Click on Geo location.</li>
           
            <li>You will be able to view the locations of the child along with the current location in the map.</li>
            <div>
              <img style={{ width: "100%" }} src={Portal15}></img>
            </div>
            <li>Also you will be able to view the history of the locations at the right of the map.</li>
            <div>
              <img style={{ width: "100%" }} src={Portal16}></img>
            </div>

          </ol>
        </div>
        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Location → Geo Fencing</h2>
          <div >
            <ul className="fontStyles">
              <li>The places where we want our child to be forbidden to be gone and the area where they are only allowed can be marked here</li>
              <li>On crossing those areas, the parent will be receiving SMS notifications as well as internal notification.</li>
            </ul>

            <div>
              <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to create Fence</h2>
              <ol className="fontStyles">
                <li>Click Add Geo fence</li>
                <div>
                  <img style={{ width: "100%" }} src={Portal17}></img>
                </div>
                <li>Enter the name of the fence.</li>
                <li>Enter the name of the place</li>
                <li>Mark the fence area</li>
                <li>Click add.</li>
                <div>
                  <img style={{ width: "100%" }} src={Portal18}></img>
                </div>
                <li>The list can be viewed in the respective screens.</li>
                <div>
                  <img style={{ width: "100%" }} src={Portal19}></img>
                </div>

              </ol>
            </div>

            <div>
              <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to edit Fence</h2>
              <ol className="fontStyles">
                <li>Click the desired fence.</li>
                <div>
                  <img style={{ width: "100%" }} src={Portal20}></img>
                </div>
                <li>Make the changes</li>
                <div>
                  <img style={{ width: "100%" }} src={Portal21}></img>
                </div>
                <li>Click update</li>
                <div>
                  <img style={{ width: "100%" }} src={Portal22}></img>
                </div>

              </ol>
            </div>

            <div>
              <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to Delete Fence</h2>
              <ol className="fontStyles">
                <li>Click on the trash icon in the right of the fence.</li>
                <div>
                  <img style={{ width: "100%" }} src={Portal23}></img>
                </div>

                <li>The fence will be removed from the list</li>
                <div>
                  <img style={{ width: "100%" }} src={Portal17}></img>
                </div>

              </ol>
            </div>
          </div>
        </div>

        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Location → Drive Safe</h2>
          <div >
            <ul className="fontStyles">
              <li>Under drive safe, you will be able to set the driving speed limit for the child.</li>
              <li>If the child exceeds the limit, notification will be received to the parent.</li>
            </ul>

            <div>
              <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to set speed limit</h2>
              <ol className="fontStyles">
                <li>Click on drive safe.</li>
               
                <li>Set the speed limit</li>
                <li>Click Set Limit.</li>
                <div>
                  <img style={{ width: "100%" }} src={Portal24}></img>
                </div>

              </ol>
            </div>

          </div>
        </div>

        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Location → Child Map</h2>
          <div >
            <ul className="fontStyles">
              <li>We will be able to locate all the child devices linked with that particular parent account. </li>
              <li>Every device will be visible in the map with their profile image.</li>
            </ul>

            <div>
              <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to view Child Map</h2>
              <ol className="fontStyles">
                <li>Click on Child Map.</li>
               
                <li>You will be able to view the children’s location along with their profile image in the Map.</li>
                <div>
                  <img style={{ width: "100%" }} src={Portal25}></img>
                </div>
              </ol>
            </div>

          </div>
        </div>

      </div>

      <div id="settings">
        <h2 style={{ color: "rgb(14, 86, 166)" }}>Settings</h2>
        <ul className="fontStyles">
          <li>Inside settings we will be able to view the options like editing parent profile, Changing Password etc.</li>
          <li>We would be able to view them and be able to make changes under those options.</li>
          <div>
            <img style={{ width: "100%" }} src={Portal26}></img>
          </div>
        </ul>
        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Edit Parent Profile</h2>
          <ul className="fontStyles">
            <li>We will be able to edit  the parents profile details.</li>

          </ul>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps for edit parent profile</h2>
          <ol className="fontStyles">
            <li>Select Edit Parent profile.</li>
            <div>
              <img style={{ width: "100%" }} src={Portal27}></img>
            </div>
            <li>Make changes.</li>
            <div>
              <img style={{ width: "100%" }} src={Portal28}></img>
            </div>
            <li>Enter the OTP received.</li>
            <li>The changes will be saved.</li>
            <div>
              <img style={{ width: "100%" }} src={Portal29}></img>
            </div>
          </ol>

        </div>
        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Change Password</h2>
          <ul className="fontStyles">
            <li>The password of the profile can be changed with this option.</li>

          </ul>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to change password</h2>
          <ol className="fontStyles">
            <li>Click on Change password.</li>
            <div>
              <img style={{ width: "100%" }} src={Portal30}></img>
            </div>
            <li>Enter the details</li>
            <div>
              <img style={{ width: "100%" }} src={Portal31}></img>
            </div>
            <li>The changes will be saved on confirming.</li>

          </ol>

        </div>
        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Contact us</h2>
          <ul className="fontStyles">
            <li>The Administrator can be contacted using this option.</li>

          </ul>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to Contact with us</h2>
          <ol className="fontStyles">
            <li>Click on Contact us.</li>
            <div>
              <img style={{ width: "100%" }} src={Portal33}></img>
            </div>
            <li>Enter the details</li>
            <div>
              <img style={{ width: "100%" }} src={Portal32}></img>
            </div>
            <li>On confirming a mail will be send to the administrator with your query.</li>

          </ol>

        </div>
        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Terms & Privacy Policy</h2>
          <ul className="fontStyles">
            <li>On Clicking which you will be able to view the terms and policies of the application.</li>
            <div>
              <img style={{ width: "100%" }} src={Portal34}></img>
            </div>
          </ul>
        </div>
      </div>

      <div id="notification">
        <h2 style={{ color: "rgb(14, 86, 166)" }}>Notification</h2>
        <ol className="fontStyles">
          <li>
            We will be able to view the notifications  received by the child.
          </li>

          <div>
            <img style={{ width: "100%" }} src={Portal35}></img>
          </div>
          <li> Also by clicking Clear All at the top, we will be able to select and delete the notifications. </li>
          <div>
              <img style={{ width: "100%" }} src={Portal36}></img>
            </div>

        </ol>
 
      </div>
      <div id="logout">
        <h2 style={{ color: "rgb(14, 86, 166)" }}>Logout</h2>
        <ol className="fontStyles">
          <li>
          Click on Log out.
          </li>

          <div>
            <img style={{ width: "100%" }} src={Portal37}></img>
          </div>
          <li> On the popup, confirm the action. </li>
          <li>You will be logged out of the profile.</li>
          <div>
              <img style={{ width: "100%" }} src={Portal38}></img>
            </div>

        </ol>
 
      </div>
    </div>
  );
}
