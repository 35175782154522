import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import React from 'react';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery, Grid } from '@mui/material';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
import Customization from '../Customization';
import navigation from 'menu-items';
import { drawerWidth } from 'store/constant';
import { SET_MENU } from 'store/actions/actions';
import { fbGetDeviceAction, loadChildrenAction } from 'store/actions';
import { connect } from 'react-redux';
import './style.scss';
import DeviceMinTile from 'ui-component/devices/deviceMinTile';
import ChildMinCard from 'ui-component/childern/childMinCard';
import Dashboard from 'views/pages/dashboard';


// assets
import { IconChevronRight } from '@tabler/icons';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        marginTop: '103px',
        backgroundColor: 'white',
        minHeight: `calc(100vh - ${103}px)`,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(drawerWidth - 20),
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        backgroundColor: 'white',
        marginTop: '103px',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        minHeight: `calc(100vh - ${103}px)`,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = ({ fbGetDeviceAction, selectedDevice, loadChildrenAction, selectedChild, selectedDeviceDynamicInfo }) => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));

    // Handle left drawer
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const dispatch = useDispatch();
    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    };

    useEffect(() => {
        dispatch({ type: SET_MENU, opened: !matchDownMd });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd]);


    useEffect(() => {
        if (selectedDevice) {
            fbGetDeviceAction(selectedDevice?.deviceId)
        }
    }, [selectedDevice])

    useEffect(() => {
        loadChildrenAction();
    }, [])

    let location = window.location.href.split("/")?.pop()

    return (
        <Box sx={{ display: 'flex', backgroundColor: '#0e56a6' }}>
            <CssBaseline />
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: "#0e56a6",
                    transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
                }}
            >
                <Toolbar>
                    <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
                </Toolbar>
            </AppBar>

            <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />


            <Main theme={theme} open={leftDrawerOpened} className='maintheme'>
                {
                    location === "dashboard" || location === "edit-profile" ? "" : (
                        <Grid container columns={{ xs: 4, sm: 12, md: 12 }}>

                            <Dashboard noActionBtn={true} />

                        </Grid>
                    )
                }

                <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                <Outlet />
            </Main>
            <Customization />
        </Box>
    );
};

const mapStateToProps = ({ childrenReducer, deviceReducer }) => ({
    selectedDevice: childrenReducer.selectedDevice,
    selectedChild: childrenReducer.selectedChild,
    selectedDeviceDynamicInfo: deviceReducer.selectedDeviceDynamicInfo,
});


const mapActionsToProps = {
    fbGetDeviceAction,
    loadChildrenAction
};

export default connect(mapStateToProps, mapActionsToProps)(MainLayout);