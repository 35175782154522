import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    loadChildrenAction, setSelectedChildAction,
    setSelectedDeviceAction, addChildAction,
    addDeviceAction, fbGetDeviceAction, removeDeviceAction, resetDeviceState,
    resetChildState, deleteChildAction, clearChildAction, updateChildDetails,
    loadPlanAction, uploadChildImage, reAuthAction, loginUser,
    resetAuthState
} from 'store/actions';
import {
    Grid, Typography, Button,
    Dialog, DialogContent, DialogTitle,
    Slide,
    Avatar
} from '@mui/material';
import ChildMinCard from 'ui-component/childern/childMinCard';
import ChildMinDash from 'ui-component/childern/childMinDash';
import DeviceMinTile from 'ui-component/devices/deviceMinTile';
import ChildForm from 'ui-component/childern/childForm';
import AuthForm from 'ui-component/childern/authenticationForm';
import ChildImageUpload from 'ui-component/childern/childImageUpload';
import DeviceAdd from 'ui-component/devices/deviceAdd';
import DeviceDelete from 'ui-component/devices/deviceDelete';
import { useSnackbar } from 'notistack';
import BasicModal from 'ui-component/Modal/confirmModal';
import ConfirmInputModal from 'ui-component/Modal/confirmInputModal';
import FormData from 'form-data'
import CancelIcon from '@mui/icons-material/Cancel';
import "./style.scss"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Modal from '@mui/material/Modal';
import { useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { useMediaQuery } from 'react-responsive'
import AddIcon from '@mui/icons-material/Add';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});



const ChildrenManage = ({
    selectedChild,
    loadChildrenAction,
    loadPlanAction,
    reAuthAction,
    childern,
    selectedDeviceDynamicInfo,
    setSelectedChildAction,
    selectedDevice,
    setSelectedDeviceAction,
    addChildAction,
    addDeviceAction,
    fbGetDeviceAction,
    addDevice,
    childLoading,
    removeDeviceAction,
    removeDevice,
    resetDeviceState,
    childAdded,
    isChildUpdate,
    isDeleted,
    isClear,
    resetChildState,
    deleteChildAction,
    clearChildAction,
    errMessage,
    errDevice,
    updateChildDetails,
    uploadChildImage,
    addedChildID,
    imageAdded,
    planDetails,
    reAuthUpdate,
    noActionBtn,
    profileData,
    loginUser,
    loginSuccess,
    resetAuthState
}) => {
    const [addChildOpen, setAddChildOpen] = React.useState(false);
    const [addDeviceOpen, setAddDeviceOpen] = React.useState(false);
    const [addImageOpen, setAddImageOpen] = React.useState(false);
    const [removeDeviceOpen, setRemoveDeviceOpen] = React.useState(null);
    const [openModalClear, setOpenModalClear] = React.useState(false)
    const [openModalDelete, setOpenModalDelete] = React.useState(false)
    const [childIdState, setchildIdState] = React.useState("")
    const [childData, setChildData] = React.useState("")
    const [imageData, setImageData] = React.useState("")
    const [deviceedit, setDeviceEdit] = React.useState(false)
    const [payloadData, setPayloadData] = React.useState("")
    const [reloadDeviceOpen, setReloadDeviceOpen] = React.useState(false);
    const [subscribeOpen, setSubscribeOpen] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const listRef = React.useRef();

    const [width, setWidth] = React.useState(300);

    React.useEffect(() => {
        loadPlanAction()
    }, []);

    React.useEffect(() => {
        if (addDevice) {

            if (!planDetails?.safednsCreated && planDetails?.planDetails?.name.includes('Parents')) {
                if (payloadData?.deviceType === "iOS") {
                    setSubscribeOpen(true)

                }

                else {
                    setAddDeviceOpen(addDevice.uniqueCode)
                    resetDeviceState()
                }

            } else {
                setAddDeviceOpen(addDevice.uniqueCode)
                resetDeviceState()
            }

            // loadChildrenAction();

        }
    }, [addDevice]);

    React.useEffect(() => {
        if (selectedDevice) {
            fbGetDeviceAction(selectedDevice?.deviceId)
        }
    }, [selectedDevice])

    const handleAddChildFormSubmit = (values) => {

        let payload = {
            firstName: values?.firstName,
            age: values?.age,
            deviceType: values.deviceType?.value
        }

        let payloadEdit = {
            firstName: values?.firstName,
            age: values?.age,
            deviceType: values.deviceType?.value
        }

        setPayloadData(payload || payloadEdit)


        if (values?.idss) {
            updateChildDetails(values?.idss, {
                ...payloadEdit,
                firstName: values?.firstName,
                age: values?.age,
                deviceType: values.deviceType?.value,
            }, { ...values?.idss, ...payloadEdit }, enqueueSnackbar)
        }

        else {
            addChildAction(payload)
            setPayloadData(payload)
        }

        setAddChildOpen(false);

    }

    const handleReAuthSubmit = (values) => {

        let payload = {
            password: values?.password,
        }

        {
            reAuthAction(payload)
        }
    }



    const handleAddDevice = () => {
        addDeviceAction({ childId: addedChildID ? addedChildID : selectedChild?.childId })
    }

    const handleChildDelete = () => {
        removeDeviceAction(removeDeviceOpen)
    }

    const handleImageUploadAction = () => {
        let payload = {
            file: imageData,
            id: addedChildID ? addedChildID : childData?.childId
        }
        uploadChildImage(payload)
        setChildData("")
        setImageData("")
    }

    React.useEffect(() => {
        if (childAdded) {
            setOpenModalDelete(false)
            enqueueSnackbar('Child Added Successfuly', {
                variant: "success",
            });
            handleImageUploadAction()
            handleAddDevice();
            resetChildState()
            loadChildrenAction();
        }
    }, [childAdded])


    React.useEffect(() => {
        if (reAuthUpdate) {
            setSubscribeOpen(false)
            enqueueSnackbar('Updated Successfuly', {
                variant: "success",
            });
            setAddDeviceOpen(addDevice.uniqueCode)
            // setReloadDeviceOpen(true)
            resetChildState()
        }
    }, [reAuthUpdate])

    React.useEffect(() => {
        if (isChildUpdate) {
            if (deviceedit) {

                enqueueSnackbar('Device Updated Successfuly', {
                    variant: "success",
                });

                if (!planDetails?.safednsCreated && planDetails?.planDetails?.name.includes('Parents')) {
                    if (payloadData?.deviceType === "iOS") {
                        setSubscribeOpen(true)
                    } else {
                        setAddDeviceOpen(true)
                        resetChildState()
                    }

                } else {
                    setAddDeviceOpen(true)
                    resetChildState()
                }
                handleAddDevice();
                resetChildState()

            } else {
                console.log(isChildUpdate, "childdd")
                setOpenModalDelete(false)
                enqueueSnackbar('Child Updated Successfuly', {
                    variant: "success",
                });
                handleImageUploadAction()
                resetChildState()
                loadChildrenAction();
                setDeviceEdit(false)
            }

        }
    }, [isChildUpdate])

    React.useEffect(() => {
        if (isDeleted) {
            setOpenModalDelete(false)
            enqueueSnackbar('Child Deleted Successfuly', {
                variant: "success",
            });
            resetChildState()
            loadChildrenAction();
        }
    }, [isDeleted])

    React.useEffect(() => {
        if (isClear) {
            setOpenModalClear(false)
            enqueueSnackbar('Child Cleared Successfuly', {
                variant: "success",
            });
            resetChildState()
            loadChildrenAction();
        }

    }, [isClear])

    React.useEffect(() => {
        if (imageAdded) {
            loadChildrenAction();
        }
    }, [imageAdded])



    React.useEffect(() => {
        if (errMessage) {
            setOpenModalDelete(false)
            setOpenModalClear(false)
            resetChildState()
            loadChildrenAction();

        }
    }, [errMessage])

    React.useEffect(() => {

        if (errMessage) {
            enqueueSnackbar(errMessage?.message, {
                variant: "error",
            })
        }


        if (errMessage?.message === "Package has expired") {
            setTimeout(function () {
                localStorage.clear()

                navigate('/');
            }, 2000);

        }
        if (errMessage?.message === "Maximum no of devices reached") {

            // setAddDeviceOpen(false)
            setReloadDeviceOpen(true)

        }




    }, [errMessage])

    React.useEffect(() => {


        if (errDevice?.message === "Maximum no of devices reached") {

            // setAddDeviceOpen(false)
            setReloadDeviceOpen(true)

        }

    }, [errDevice])


    React.useEffect(() => {
        if (removeDevice) {
            enqueueSnackbar('Device Deleted Successfuly', {
                variant: "success",
            });
            resetDeviceState()
            loadChildrenAction();
        }
    }, [removeDevice, selectedDevice])

    const handleClearData = () => {
        setOpenModalClear(true)
    }

    const handleRemoveData = (id) => {
        setchildIdState(id)
        setOpenModalDelete(true)
    }

    const handleEditData = (data) => {
        console.log("data", data)
        setChildData(data)
        setAddChildOpen(true)
        setDeviceEdit(false)
    }

    const handleDeviceEditData = () => {
        setChildData(selectedChild)
        setDeviceEdit(true)
        setAddChildOpen(true)
    }


    let DeselectedList = childern?.filter(
        (status) => status?.childId !== selectedChild?.childId
    );

    // This function calculates width and height of the list
    const getListSize = () => {
        const newWidth = listRef.current?.clientWidth;
        setWidth(newWidth);

    };

    // Get 'width' and 'height' after the initial render and every time the list changes
    React.useEffect(() => {
        getListSize();
    }, [selectedChild]);

    // Update 'width' and 'height' when the window resizes
    React.useEffect(() => {
        window.addEventListener("resize", getListSize);
    }, []);


    const RenderChild = ({ image, label, child, color, options }) => {
        const [anchorEl, setAnchorEl] = React.useState(null);

        const open = Boolean(anchorEl);
        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
            setAnchorEl(null);
        };

        return (
            <Grid item lg={1} md={1} sm={12} xs={3}
            >
                <div className='d-vertically-hori-center' >
                    <div className='cursor-pointer' style={{ position: 'relative', width: 75, height: 75, backgroundColor: color, borderRadius: 100, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        onClick={() => {
                            if (open) return;
                            if (child) {
                                setSelectedChildAction(child)
                            } else {
                                setChildData("");
                                setAddChildOpen(true);
                                setDeviceEdit(false);
                            }
                        }}>
                        {image ? <Avatar src={image} style={{ width: 75, height: 75, objectFit: "cover", borderRadius: 100, border: child?.childId === selectedChild?.childId && '3px solid #fc8c16' }} /> : <AddIcon sx={{ fontSize: 30, color: 'white' }} />}
                        {options &&
                            <Fragment>
                                <div onClick={(event) => {
                                    event.stopPropagation()
                                    handleClick(event)
                                }}
                                    className='d-vertically-hori-center' style={{ position: 'absolute', right: 0, bottom: 0, height: 25, width: 25, backgroundColor: '#0E56A6', borderRadius: 15 }}>
                                    <MoreHorizIcon style={{ color: 'white' }} fontSize="small" />
                                </div>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                >
                                    <MenuItem onClick={(event) => {
                                        handleEditData(child)
                                    }}>
                                        <ListItemIcon>
                                            <EditIcon fontSize="small" sx={{ color: '#0E56A6' }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primaryTypographyProps={{
                                                color: '#0E56A6 !important'
                                            }}
                                        >Edit</ListItemText>
                                    </MenuItem>
                                    <MenuItem onClick={(event) => {
                                        handleRemoveData(child?.childId)
                                    }}>
                                        <ListItemIcon>
                                            <DeleteOutlineIcon fontSize="small" sx={{ color: 'red' }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primaryTypographyProps={{
                                                color: 'red !important'
                                            }} >Delete</ListItemText>
                                    </MenuItem>
                                </Menu></Fragment>}
                    </div>
                </div>
                <div style={{ textAlign: 'center', marginTop: 7, color: 'black', fontSize: 15 }}>{label}</div>
                {child?.devices && <div style={{ textAlign: 'center', fontSize: 13, color: '#0e56a6', }}>{child?.devices?.length} {child?.devices?.length > 1 ? "devices" : "device"}</div>}
            </Grid>
        )
    }

    const renderDevice = (device) => {
        return (
            <Grid item lg={2.3} md={2} sm={12} xs={12} className='d-vertically-center' style={{ height: 55, border: device?.deviceId === selectedDevice?.deviceId && '3px solid #F19432', borderRadius: 4, boxShadow: '0px 0px 4px #9ECAED', cursor: 'pointer' }}
                onClick={() => setSelectedDeviceAction(device)}
            >
                <Grid item lg={3} md={1} sm={4} xs={4} className="d-vertically-hori-center">
                    {device?.deviceType === 'IPad' || device?.deviceType === 'iOS' || device?.deviceType === 'macOS' ?
                        <AppleIcon sx={{ color: '#0E56A6' }} fontSize="medium" />
                        : <AndroidIcon style={{ color: '#0E56A6' }} fontSize="medium" />}
                </Grid>
                <Grid item lg={3.5} md={1} sm={4} xs={4} sx={{ color: 'black' }} className="d-vertically-hori-center">
                    {device?.deviceName}
                </Grid>
                <Grid item lg={2} md={1} sm={4} xs={4} className="d-vertically-hori-center">
                    {device?.deviceId === selectedDevice?.deviceId && selectedDeviceDynamicInfo?.batteryp && <img src={require(`assets/images/icons/BatteryHalf.svg`).default} width="20" height="20" />}
                </Grid>
                <Grid item lg={1} md={1} sm={4} xs={4} sx={{ color: 'black' }} className="d-vertically-hori-center">
                    {device?.deviceId === selectedDevice?.deviceId && selectedDeviceDynamicInfo?.batteryp && `${selectedDeviceDynamicInfo?.batteryp}%`}
                </Grid>
                <Grid item lg={2.5} md={1} sm={4} xs={4} className="d-vertically-hori-center">
                    <img src={require(`assets/images/icons/Trash.svg`).default} width="17" height="17" onClick={(event) => { event.stopPropagation(); setRemoveDeviceOpen(device?.deviceId); }} />
                </Grid>
            </Grid>
        )
    }

    const handleDeleteSubmit = (values) => {
        loginUser({ email: profileData?.email, password: values?.password })
    }


    useEffect(() => {
        if (loginSuccess) {
            deleteChildAction(childIdState)
            resetAuthState()
        }
    }, [loginSuccess])



    return (
        <>
            <Helmet>
                <title>Cyber Safe Family - Dashboard</title>
                <meta name="description" content="Nested component" />
            </Helmet>
            <ConfirmInputModal open={openModalDelete} handleDeleteSubmit={handleDeleteSubmit} handleCloseModal={() => setOpenModalDelete(false)} mainTitle={"Delete Child"} description={`Enter your password to Delete Child`} buttons={[{ title: 'Delete', style: { color: 'white', backgroundColor: 'red', width: 100 } }, { title: 'Cancel', style: { color: 'white', backgroundColor: 'grey', width: 100 }, handleClick: () => setOpenModalDelete(false) }]} />
            <BasicModal open={openModalClear} handleCloseModal={() => setOpenModalClear(false)} mainTitle={"Clear Child"} description={`Are you sure you want to Clear Child?`} buttons={[{ title: 'Clear', style: { color: 'white', backgroundColor: 'red' }, handleClick: () => clearChildAction() }, { title: 'Cancel', style: { color: 'white', backgroundColor: 'grey' }, handleClick: () => setOpenModalClear(false) }]} />
            <Grid container>
                <RenderChild image={''} label="Add Child" child={null} color="#0E56A6" options={false} />
                {childern?.map((child) => (
                    <RenderChild image={child?.profileUrl ? child?.profileUrl : 'none'} label={child?.firstName} child={child} color="#fc8c16" options={true} />
                ))}
            </Grid>
            {selectedChild &&
                <Grid container sx={{ mt: 3 }} gap={2.5}>
                    {selectedChild?.devices?.map((device) => (
                        renderDevice(device)
                    ))}
                    <Grid item lg={2.3} md={2} sm={12} xs={12} className='d-vertically-center' style={{ width: 190, height: 55, borderRadius: 4, backgroundColor: '#0E56A6', cursor: 'pointer' }}
                        onClick={(event) => {
                            event.stopPropagation();
                            if (selectedChild && selectedChild !== [] && selectedChild !== undefined && planDetails?.planDetails?.name?.includes('Parents') && childern.flatMap((e) => e.devices).length < 5 || planDetails?.planDetails?.name?.includes('Premium') && childern.flatMap((e) => e.devices).length < 10) {
                                handleDeviceEditData()
                            } else {
                                navigate('/user/upgrade-plan');
                            }
                        }}>
                        <Grid item lg={4} md={1} sm={4} xs={4} className="d-vertically-hori-center">
                            <AddIcon sx={{ fontSize: 30, color: 'white' }} />
                        </Grid>
                        <Grid item lg={6} md={1} sm={5} xs={5} sx={{ color: 'white' }} className="d-vertically-hori-center">
                            Add Device
                        </Grid>
                    </Grid>
                </Grid>
            }

            {/* <Grid >
                <Grid container >
                    {
                        noActionBtn === true ? " " : (
                            <Grid item lg={12} md={12} sm={12} className="d-flex-end">

                                {selectedChild && selectedChild !== [] && selectedChild !== undefined ? planDetails?.planDetails?.name?.includes('Parents') && childern.flatMap((e) => e.devices).length < 5 || planDetails?.planDetails?.name?.includes('Premium') && childern.flatMap((e) => e.devices).length < 10 ?

                                    (


                                        <Button variant="outlined" color="primary" sx={{ marginRight: '1rem' }} onClick={(event) => {
                                            event.stopPropagation();
                                            handleDeviceEditData()
                                        }}>
                                            Add Device
                                        </Button>

                                    ) : (
                                        <Button variant="outlined" color="primary" sx={{ marginRight: '1rem' }} onClick={(event) => {
                                            event.stopPropagation();
                                            navigate('/user/upgrade-plan');
                                        }}>
                                            Add Device
                                        </Button>
                                    )
                                    :
                                    ""
                                }



                                <Button variant="outlined" color="primary" onClick={() => {
                                    setChildData("")
                                    setAddChildOpen(true)
                                    setDeviceEdit(false)
                                }}>
                                    Add child
                                </Button>
                                <Button variant="outlined" disabled={selectedChild ? false : true} color="primary" onClick={() => handleClearData()} style={{ marginLeft: '1rem' }}>
                                    Clear All
                                </Button>
                            </Grid>
                        )
                    }


                </Grid>
                {
                    isMobile ? (
                        <Grid >
                            <Grid container>
                                <Grid item>
                                    <ChildMinDash
                                        userSelected={selectedChild?.childId}
                                        name={`${selectedChild?.firstName} `}
                                        img={selectedChild?.profileUrl}
                                        handleOnClick={(e) => setSelectedChildAction(e)}
                                        data={selectedChild}
                                        handleRemoveData={(id) => handleRemoveData(id)}
                                        handleEdit={(data) => handleEditData(data)}
                                        childActions={true}

                                    />
                                </Grid>
                            </Grid>
                            {
                                selectedChild?.devices.length > 0 ? (
                                    <Grid sx={{ borderRadius: '5px', width: 'fit-content' }}>
                                        {selectedChild?.devices?.map((device, index) => (
                                            <React.Fragment key={index}>
                                                <DeviceMinTile
                                                    selectedDevice={device?.deviceId === selectedDevice?.deviceId ? selectedDeviceDynamicInfo : setSelectedDeviceAction(device)}
                                                    name={device.deviceName}
                                                    deviceType={device.deviceType}
                                                    handleOnRemoveClick={() => setRemoveDeviceOpen(device.deviceId)}
                                                    handleOnClick={() => setSelectedDeviceAction(device)}
                                                />
                                            </React.Fragment>
                                        ))}
                                    </Grid>
                                ) : ""
                            }

                        </Grid>
                    ) : ""
                }
                {
                    isMobile ? (
                        <Grid container>
                            {DeselectedList.map((child, index) => (
                                <React.Fragment key={index}>
                                    <ChildMinDash

                                        name={`${child?.firstName} `}
                                        img={child?.profileUrl}
                                        handleOnClick={(e) => setSelectedChildAction(e)}
                                        data={child}
                                        handleRemoveData={(id) => handleRemoveData(id)}
                                        handleEdit={(data) => handleEditData(data)}
                                        childActions={true}

                                    />
                                </React.Fragment>
                            ))}
                        </Grid>
                    ) : <Grid container ref={listRef} sx={{ width: 'fit-content' }}>
                        {childern.map((child, index) => (
                            <React.Fragment key={index}>
                                <ChildMinDash
                                    userSelected={child?.childId === selectedChild?.childId}
                                    name={`${child?.firstName} `}
                                    img={child?.profileUrl}
                                    handleOnClick={(e) => setSelectedChildAction(e)}
                                    data={child}
                                    handleRemoveData={(id) => handleRemoveData(id)}
                                    handleEdit={(data) => handleEditData(data)}
                                    childActions={true}

                                />
                            </React.Fragment>
                        ))}
                    </Grid>
                }

                {
                    isMobile ? "" : (
                        <Grid item xs={12} className={` ${selectedChild?.devices?.length > 0 ? "glowBoxShadow" : ""}`} sx={{ width: width < 300 ? 300 : width }}>
                            <Grid container sx={{ borderRadius: '5px', width: 'fit-content' }}>
                                {selectedChild?.devices?.map((device, index) => (
                                    <React.Fragment key={index}>
                                        <DeviceMinTile
                                            selectedDevice={device?.deviceId === selectedDevice?.deviceId && selectedDeviceDynamicInfo}
                                            name={device.deviceName}
                                            deviceType={device.deviceType}
                                            handleOnRemoveClick={() => setRemoveDeviceOpen(device.deviceId)}
                                            handleOnClick={() => setSelectedDeviceAction(device)}
                                        />
                                    </React.Fragment>
                                ))}
                            </Grid>

                        </Grid>
                    )
                }

            </Grid> */}

            <Dialog
                open={addChildOpen}
                TransitionComponent={Transition}
                keepMounted={false}
                disableBackdropClick

                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>
                    <ChildForm
                        handleAddImageData={(data) => setImageData(data)}
                        onFormSubmit={handleAddChildFormSubmit}
                        handleClose={() => {
                            setAddChildOpen(false)
                            // setDeviceEdit(false)
                        }}
                        childData={childData}
                        deviceEdit={deviceedit}
                        planDetails={planDetails?.planDetails?.name}
                        selectedDeviceLength={selectedChild?.devices.length}
                        totalDevices={childern.flatMap((e) => e.devices).length || 0}
                    />

                </DialogContent>
            </Dialog>

            <Dialog
                open={addDeviceOpen}
                TransitionComponent={Transition}
                keepMounted
                disableBackdropClick
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle sx={{ pb: '.5rem', }}>

                    <Grid container spacing={2}>
                        <Grid item xs={10}>
                            <Typography variant="h2" >
                                Add Device
                            </Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ textAlign: "right" }}>
                            <CancelIcon sx={{ cursor: 'pointer' }} onClick={() => {
                                resetDeviceState()
                                setAddDeviceOpen(false)
                                setReloadDeviceOpen(true)


                            }} />
                        </Grid>

                    </Grid>

                </DialogTitle>
                <DialogContent>
                    <DeviceAdd playStoreURL={payloadData?.deviceType === "Tablet" || payloadData?.deviceType === "Android" ? 'https://play.google.com/store/apps/details?id=com.cybersafefamily.child' : payloadData?.deviceType === "Windows Desktop" || payloadData?.deviceType === "chrome" ? 'Microsoft Store Url' : 'App store URL'} securityCode={addDeviceOpen} />
                </DialogContent>
            </Dialog>

            <Dialog
                open={reloadDeviceOpen}
                TransitionComponent={Transition}
                keepMounted

                disableBackdropClick
                aria-describedby="alert-dialog-slide-description"
                className="child-alert"
            >
                <DialogTitle sx={{ pb: '.5rem' }}>
                    <CheckCircleIcon sx={{ fontSize: '4rem', fill: "#0e56a6" }} />
                    <div style={{ display: 'flex' }}>

                        <Typography sx={{ fontSize: '1rem' }} >
                            {
                                errDevice?.message === "Maximum no of devices reached" || errMessage?.message === "Maximum no of devices reached" ? " Maximum no of devices reached " : "  Please refresh the application once, the child application setup is done."
                            }

                        </Typography>

                    </div>

                </DialogTitle>
                <DialogContent >
                    <Button variant={'contained'} size="small" onClick={() => {
                        setReloadDeviceOpen(false)
                        resetDeviceState()
                    }} type={'button'} sx={{ background: '#0e56a6', color: 'white', width: '5rem' }} color={'secondary'}>OK</Button>
                </DialogContent>
            </Dialog>

            <Dialog
                open={subscribeOpen}
                TransitionComponent={Transition}
                keepMounted

                disableBackdropClick
                aria-describedby="alert-dialog-slide-description"


            >
                <DialogTitle sx={{ pb: '.5rem' }}>
                    <div style={{ display: 'flex' }}>

                        <Typography sx={{ fontSize: '1rem' }} >
                            {
                                "Please authenticate yourself"
                            }
                        </Typography>

                    </div>

                </DialogTitle>
                <DialogContent>
                    <AuthForm
                        onFormSubmit={handleReAuthSubmit}
                        handleClose={() => {
                            setSubscribeOpen(false)
                        }}

                    />
                </DialogContent>
            </Dialog>

            <Dialog
                open={addImageOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => {
                    setImageData("")
                    setAddImageOpen(false)
                }}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle sx={{ pb: '.5rem' }}>
                    <Typography variant="h2" >
                        Add child Image
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <ChildImageUpload playStoreURL={'Playstore URL'} />
                </DialogContent>
            </Dialog>

            <Dialog
                open={removeDeviceOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setRemoveDeviceOpen(null)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>
                    <DeviceDelete onConfirmation={() => {
                        setRemoveDeviceOpen(null)
                        handleChildDelete()
                    }
                    } />
                </DialogContent>
            </Dialog>
        </>
    );
};
const mapStateToProps = ({ childrenReducer, deviceReducer, generalReducer, auth }) => ({
    childLoading: childrenReducer.addChildLoading,
    childern: childrenReducer.children,
    selectedChild: childrenReducer.selectedChild,
    selectedDevice: childrenReducer.selectedDevice,
    addDevice: deviceReducer.addDevice,
    selectedDeviceDynamicInfo: deviceReducer.selectedDeviceDynamicInfo,
    removeDevice: deviceReducer.removeDevice,
    childAdded: childrenReducer?.childAdded,
    isDeleted: childrenReducer?.isDeleted,
    isClear: childrenReducer?.isClear,
    errMessage: childrenReducer?.errMessage,
    isChildUpdate: childrenReducer?.isChildUpdate,
    addedChildID: childrenReducer?.addedChildID,
    imageAdded: childrenReducer?.imageAdded,
    errDevice: deviceReducer?.errMessage,
    planDetails: childrenReducer?.planDetails,
    reAuthUpdate: childrenReducer?.reAuthUpdate,
    profileData: generalReducer.profileData,
    loginSuccess: auth.loginSuccess,
});

const mapActionsToProps = {
    loadChildrenAction,
    setSelectedChildAction,
    setSelectedDeviceAction,
    addChildAction,
    addDeviceAction,
    fbGetDeviceAction,
    removeDeviceAction,
    resetDeviceState,
    resetChildState,
    deleteChildAction,
    clearChildAction,
    updateChildDetails,
    uploadChildImage,
    loadPlanAction,
    reAuthAction,
    loginUser,
    resetAuthState
};

export default connect(mapStateToProps, mapActionsToProps)(ChildrenManage);
