import { Card, Grid, Typography } from '@mui/material';
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import TabletMacIcon from '@mui/icons-material/TabletMac';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Battery0BarIcon from '@mui/icons-material/Battery0Bar'; // 0%
import Battery1BarIcon from '@mui/icons-material/Battery1Bar';// 0 - 10%
import Battery2BarIcon from '@mui/icons-material/Battery2Bar'; // 10 -20%
import Battery3BarIcon from '@mui/icons-material/Battery3Bar'; // 20 - 30%
import Battery4BarIcon from '@mui/icons-material/Battery4Bar'; // 30 -50%
import Battery5BarIcon from '@mui/icons-material/Battery5Bar'; // 50 -70%
import Battery6BarIcon from '@mui/icons-material/Battery6Bar'; // 70 -90%
import BatteryFullIcon from '@mui/icons-material/BatteryFull'; // 90 -100%
import './style.css';

export default function DeviceMinTile({ name, selectedDevice, deviceType, handleOnClick, handleOnRemoveClick }) {

    let BatteryIcon = BatteryFullIcon;

    switch (true) {
        case selectedDevice?.batteryp === 0:
            BatteryIcon = Battery0BarIcon;
            break;
        case selectedDevice?.batteryp > 0 && selectedDevice?.batteryp <= 10:
            BatteryIcon = Battery1BarIcon;
            break;
        case selectedDevice?.batteryp > 10 && selectedDevice?.batteryp <= 20:
            BatteryIcon = Battery2BarIcon;
            break;
        case selectedDevice?.batteryp > 20 && selectedDevice?.batteryp <= 30:
            BatteryIcon = Battery3BarIcon;
            break;
        case selectedDevice?.batteryp > 30 && selectedDevice?.batteryp <= 60:
            BatteryIcon = Battery4BarIcon;
            break;
        case selectedDevice?.batteryp > 60 && selectedDevice?.batteryp <= 70:
            BatteryIcon = Battery5BarIcon;
            break;
        case selectedDevice?.batteryp > 70 && selectedDevice?.batteryp <= 90:
            BatteryIcon = Battery6BarIcon;
            break;
        case selectedDevice?.batteryp > 90 && selectedDevice?.batteryp <= 100:
            BatteryIcon = BatteryFullIcon;
            break;
        default:
            BatteryIcon = BatteryFullIcon;
            break;
    }


    return (
        <Card
            sx={{ boxShadow: 2, padding: '.5rem', marginRight: '.8rem', marginBottom: '.5rem', border: selectedDevice ? '3px solid #1e88e5' : '', borderRadius: 1 }}
            onClick={(event) => {
                event.stopPropagation();
                handleOnClick();
            }}
        >
            <Grid container alignItems="center">
                <Grid item className="d-vertically-center">
                    {deviceType === 'IPad' || deviceType === 'iOS' || deviceType === 'macOS' ? (
                        <AppleIcon fontSize="large" sx={{ color: selectedDevice ? '#fff' : '', fill: 'grey' }} />
                    ) : (
                        <AndroidIcon style={{ width: 30, height: 30, marginRight: 10 }} sx={{ color: selectedDevice ? '' : '', fill: 'grey' }} />
                    )}
                </Grid>
                <Grid item className="d-vertically-center">
                    <Typography variant="h4" sx={{ color: selectedDevice ? '' : '' }}>
                        {name}
                    </Typography>
                </Grid>
                <Grid item sx={{ ml: '.5rem' }} className={'d-vertically-center'}>
                    <Grid container className=''>
                        <Grid item className="d-vertically-center" >
                            <BatteryIcon sx={{ color: selectedDevice ? '' : 'white', fill: 'grey' }} />
                        </Grid>
                        <Grid item className="d-vertically-center">
                            <Typography className='batteryPercent' sx={{ color: selectedDevice ? '' : 'white' }} variant="span">{`${selectedDevice?.batteryp || 0} %`}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {
                    handleOnRemoveClick && (
                        <Grid item>
                            <IconButton aria-label="delete" size="large">
                                <DeleteIcon fontSize="inherit" onClick={(e) => { e.stopPropagation(); handleOnRemoveClick() }} sx={{ color: selectedDevice ? '' : '' }} />
                            </IconButton>
                        </Grid>
                    )
                }

            </Grid>
          
        </Card>
    );
}
