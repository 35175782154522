import { useState, useRef, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import "./style.scss"

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    InputAdornment,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    OutlinedInput,
    Paper,
    Popper,
    Stack,
    Switch,
    Typography
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import UpgradePlanCard from './UpgradePlanCard';
import User1 from 'assets/images/users/user-round.svg';


// assets
import { IconLogout, IconSearch, IconSettings, IconUser } from '@tabler/icons';
import ImportantDevicesIcon from '@mui/icons-material/ImportantDevices';
import LockIcon from '@mui/icons-material/Lock';
import ContactsIcon from '@mui/icons-material/Contacts';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LogoutIcon from '@mui/icons-material/Logout';
import EditIcon from '@mui/icons-material/Edit';
import DevicesIcon from '@mui/icons-material/Devices';

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = (props) => {
    const theme = useTheme();
    const customization = useSelector((state) => state.customization);
    const navigate = useNavigate();

    const [sdm, setSdm] = useState(true);
    const [value, setValue] = useState('');
    const [notification, setNotification] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [open, setOpen] = useState(false);
    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);

    const handleChangePsw = async () => {
        navigate('/user/change-password');
        setOpen(false);
    };

    const handleContactUs = async () => {
        navigate('/user/contact-us');
        setOpen(false);
    };

    const handleParent = async () => {
        navigate('/user/parent-device');
        setOpen(false);
    };

    const handleEditProfile = async () => {
        navigate('/user/edit-parent-profile');
        setOpen(false);
    };

    const handleClose = (event) => {
      
        setOpen(false);
    };

    const handleListItemClick = (event, index, route = '') => {
        setSelectedIndex(index);
        handleClose(event);

        if (route && route !== '') {
            navigate(route);
        }
    };

    const prevOpen = useRef(open);

    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current?.focus();
        }

        // prevOpen.current = open;
    }, [open]);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    function capitalizeFirstLetter(string) {
        return string?.charAt(0)?.toUpperCase() + string?.slice(1);
    }


    return (
        <>
            <Chip
                sx={{
                    height: '48px',
                    alignItems: 'center',
                    borderRadius: '27px',
                    transition: 'all .2s ease-in-out',
                    borderColor: 'white',
                    backgroundColor: 'white',
                    '& svg': {
                        stroke: theme.palette.primary.dark
                    },
                    '&[aria-controls="menu-list-grow"], &:hover': {
                        borderColor: theme.palette.primary.main,
                        background: `${theme.palette.primary.main}!important`,
                        color: theme.palette.primary.light,
                        '& svg': {
                            stroke: theme.palette.primary.light
                        }
                    },
                    '& .MuiChip-label': {
                        lineHeight: 0
                    }
                }}
                className={"checkingClass"}
                icon={
                    <Avatar
                        src={props?.profileData?.profileUrl}
                        sx={{
                            ...theme.typography.mediumAvatar,
                            margin: '8px 0 8px 8px !important',
                            cursor: 'pointer',
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        color="inherit"
                    />
                }
                label={<IconSettings stroke={1.5} size="1.5rem" color={'white'} />}
                variant="outlined"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="primary"
            />
            <Popper
                className="popperclass"
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef}
                // sx={{
                //     position : 'absolute',
                //     transform : 'translate(-24px, 83px)',
                //     inset: '0px 0px auto auto',
                //     margin: '0px'

                // }}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                    <Box sx={{ p: 2 }}>
                                        <Avatar
                                            src={props?.profileData?.profileUrl}
                                            sx={{
                                                ...theme.typography.mediumAvatar,

                                                // cursor: 'pointer',
                                                width: '100px',
                                                height: ' 100px',
                                                alignSelf: 'center',
                                                marginBottom: '5px'

                                            }}
                                            ref={anchorRef}

                                            aria-haspopup="true"
                                            color="inherit"
                                        />

                                        <Stack>
                                            <Stack direction="row" spacing={0.5} alignItems="center">
                                                <Typography variant="h4">{capitalizeFirstLetter(props?.profileData?.firstName)} {props?.profileData?.lastName}</Typography>

                                            </Stack>
                                            <Typography variant="subtitle2">{props?.profileData?.email}</Typography>
                                        </Stack>
                                        {/* <OutlinedInput
                                            sx={{ width: '100%', pr: 1, pl: 2, my: 2 }}
                                            id="input-search-profile"
                                            value={value}
                                            onChange={(e) => setValue(e.target.value)}
                                            placeholder="Search profile options"
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <IconSearch stroke={1.5} size="1rem" color={theme.palette.grey[500]} />
                                                </InputAdornment>
                                            }
                                            aria-describedby="search-helper-text"
                                            inputProps={{
                                                'aria-label': 'weight'
                                            }}
                                        />
                                        <Divider /> */}
                                    </Box>
                                    {/* <PerfectScrollbar style={{ height: '100%', maxHeight: 'calc(100vh - 250px)', overflowX: 'hidden' }}> */}
                                    <Box sx={{ p: 2 }}>
                                        {/* <UpgradePlanCard /> */}
                                        {/* <Divider /> */}
                                        {/* <Card
                                                sx={{
                                                    bgcolor: theme.palette.primary.light,
                                                    my: 2
                                                }}
                                            >
                                                <CardContent>
                                                    <Grid container spacing={3} direction="column">
                                                        <Grid item>
                                                            <Grid item container alignItems="center" justifyContent="space-between">
                                                                <Grid item>
                                                                    <Typography variant="subtitle1">Start DND Mode</Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Switch
                                                                        color="primary"
                                                                        checked={sdm}
                                                                        onChange={(e) => setSdm(e.target.checked)}
                                                                        name="sdm"
                                                                        size="small"
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid item container alignItems="center" justifyContent="space-between">
                                                                <Grid item>
                                                                    <Typography variant="subtitle1">Allow Notifications</Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Switch
                                                                        checked={notification}
                                                                        onChange={(e) => setNotification(e.target.checked)}
                                                                        name="sdm"
                                                                        size="small"
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card> */}
                                        <Divider />
                                        <List
                                            component="nav"
                                            sx={{
                                                width: '100%',
                                                maxWidth: 350,
                                                minWidth: 300,
                                                backgroundColor: theme.palette.background.paper,
                                                borderRadius: '10px',
                                                [theme.breakpoints.down('md')]: {
                                                    minWidth: '100%'
                                                },
                                                '& .MuiListItemButton-root': {
                                                    mt: 0.5
                                                }
                                            }}
                                        >
                                               <ListItemButton
                                                sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                selected={selectedIndex === 0}
                                                onClick={handleParent}
                                            >
                                                <ListItemIcon>
                                                    <DevicesIcon stroke={1.5} size="1.3rem"sx={{fill : "rgb(97, 97, 97)"}} />
                                                </ListItemIcon>
                                                <ListItemText primary={<Typography variant="body2" sx={{color: 'rgb(97, 97, 97)'}}>Parent Device</Typography>} />
                                            </ListItemButton>
                                            <ListItemButton
                                                sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                selected={selectedIndex === 0}
                                                onClick={handleEditProfile}
                                            >
                                                <ListItemIcon>
                                                    <EditIcon stroke={1.5} size="1.3rem"sx={{fill : "rgb(97, 97, 97)"}} />
                                                </ListItemIcon>
                                                <ListItemText primary={<Typography variant="body2" sx={{color: 'rgb(97, 97, 97)'}}>Edit Parent Profile</Typography>} />
                                            </ListItemButton>

                                            <ListItemButton
                                                sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                selected={selectedIndex === 1}
                                                onClick={handleChangePsw}
                                            >
                                                <ListItemIcon>
                                                    <LockIcon stroke={1.5} size="1.3rem" sx={{fill : "rgb(97, 97, 97)"}}/>
                                                </ListItemIcon>
                                                <ListItemText primary={<Typography variant="body2" sx={{color: 'rgb(97, 97, 97)'}}>Change Password</Typography>} />
                                            </ListItemButton>

                                            <ListItemButton
                                                sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                selected={selectedIndex === 2}
                                                onClick ={handleContactUs}
                                            >
                                                <ListItemIcon>
                                                    <ContactsIcon stroke={1.5} size="1.3rem" sx={{fill : "rgb(97, 97, 97)"}}/>
                                                </ListItemIcon>
                                                <ListItemText primary={<Typography variant="body2" sx={{color: 'rgb(97, 97, 97)'}}>Contact us</Typography>} />
                                            </ListItemButton>

                                            <ListItemButton
                                                sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                selected={selectedIndex === 3}
                                                 
                                            >
                                                <ListItemIcon>
                                                    <PrivacyTipIcon stroke={1.5} size="1.3rem" sx={{fill : "rgb(97, 97, 97)"}}/>
                                                </ListItemIcon>
                                                <ListItemText primary={<a variant="body2" id="textDec"sx={{color: 'rgb(97, 97, 97)!important', textDecoration: 'none'}} target="_blank" href="https://cybersafefamily.com/privacy-policy-3/">Terms and Privacy policy</a>} />
                                            </ListItemButton>

                                            {/* <ListItemButton
                                                sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                selected={selectedIndex === 4}
                                                onClick ={handleHelp}
                                            >
                                                <ListItemIcon>
                                                    <HelpOutlineIcon stroke={1.5} size="1.3rem" sx={{fill : "rgb(97, 97, 97)"}}/>
                                                </ListItemIcon>
                                                <ListItemText primary={<Typography variant="body2" sx={{color: 'rgb(97, 97, 97)'}}>Help</Typography>} />
                                            </ListItemButton> */}

                                            {/* <ListItemButton
                                                sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                selected={selectedIndex === 4}
                                                onClick={handleLogout}
                                            >
                                                <ListItemIcon>
                                                    <LogoutIcon stroke={1.5} size="1.3rem" />
                                                </ListItemIcon>
                                                <ListItemText primary={<Typography variant="body2">Logout</Typography>} />
                                            </ListItemButton> */}
                                        </List>
                                    </Box>
                                    {/* </PerfectScrollbar> */}
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
};

export default ProfileSection;
