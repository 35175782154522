// assets
import { IconTypography, IconPalette, IconShadow, IconWindmill, IconDashboard } from '@tabler/icons';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import EditLocationIcon from '@mui/icons-material/EditLocation';
import EmergencyShareIcon from '@mui/icons-material/EmergencyShare';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
import AppsIcon from '@mui/icons-material/Apps';
import LanguageIcon from '@mui/icons-material/Language';
import AppShortcutIcon from '@mui/icons-material/AppShortcut';
import WifiCallingIcon from '@mui/icons-material/WifiCalling';
import DesktopMacIcon from '@mui/icons-material/DesktopMac';
import HomeIcon from '@mui/icons-material/Home';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { useSelector } from 'react-redux';

// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconDashboard,
    AccessTimeIcon,
    AddLocationAltIcon,
    EditLocationIcon,
    EmergencyShareIcon,
    LocationOnIcon,
    WhereToVoteIcon,
    AppsIcon,
    LanguageIcon,
    AppShortcutIcon,
    WifiCallingIcon,
    HomeIcon
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

// const checkProPlan = (planName,selectedChild) =>{
//     return planDetails?.planDetails?.name?.includes('Parents') && selectedChild?.devices?.length && selectedChild?.deviceType !== 'iOS' ? true : false
// }

// const checkPremiumPlan = (planName,selectedChild) =>{
//     return planDetails?.planDetails?.name?.includes('Premium') && selectedChild?.devices?.length  && selectedChild?.deviceType !== 'iOS'  ? true : false
// }

const checkDeviceType = (selectedDevice) => {

    let check = selectedDevice?.deviceType !== 'iOS' && selectedDevice?.deviceType !== 'iPad' && selectedDevice?.deviceType !== 'macOS' ? true : false
    return check
}

const utilities = {
    id: 'utilities',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: () => '/user/dashboard',
            icon: 'dashboard',
            breadcrumbs: false,
            show: () => true
        },
        {
            id: 'timeschedule',
            title: 'Time Scheduler',
            type: 'item',
            url: () => '/user/time-scheduler',
            icon: 'scheduler',
            breadcrumbs: false,
            show: (planDetails, selectedChild, selectedDevice) => planDetails?.planDetails?.name?.includes('Parents') && selectedDevice && checkDeviceType(selectedDevice) || planDetails?.planDetails?.name?.includes('Premium') && selectedDevice && checkDeviceType(selectedDevice) ? true : false
        },
        {
            id: 'location',
            title: 'Location',
            type: 'collapse',
            icon: 'location',
            showMenu: (planDetails, selectedChild, selectedDevice) => planDetails?.planDetails?.name?.includes('Parents') && selectedDevice || planDetails?.planDetails?.name?.includes('Premium') && selectedDevice ? true : false,
            children: [
                {
                    id: 'geolocation',
                    title: 'Location History',
                    type: 'item',
                    url: () => '/user/geo-location',
                    breadcrumbs: false,
                    icon: 'location',
                    show: (planDetails, selectedChild) => planDetails?.planDetails?.name?.includes('Parents') || planDetails?.planDetails?.name?.includes('Premium') ? true : false

                },
                {
                    id: 'geofence',
                    title: 'Geo Fencing',
                    type: 'item',
                    url: () => '/user/geo-fencing',
                    breadcrumbs: false,
                    icon: 'geo-fence',
                    show: (planDetails, selectedChild) => planDetails?.planDetails?.name?.includes('Parents') || planDetails?.planDetails?.name?.includes('Premium') ? true : false

                },
                {
                    id: 'drivesafe',
                    title: 'Drive Safe ',
                    type: 'item',
                    url: () => '/user/drive-safe',
                    breadcrumbs: false,
                    icon: 'geo-fence',
                    show: (planDetails, selectedChild) => planDetails?.planDetails?.name?.includes('Parents') || planDetails?.planDetails?.name?.includes('Premium') ? true : false

                },
                {
                    id: 'familymap',
                    title: 'Child Map',
                    type: 'item',
                    url: () => '/user/child-map',
                    breadcrumbs: false,
                    icon: 'location',
                    show: (planDetails, selectedChild,) => planDetails?.planDetails?.name?.includes('Parents') || planDetails?.planDetails?.name?.includes('Premium') ? true : false

                },
            ]
        },
        {
            id: 'appfilter',
            title: 'App Filtering',
            type: 'item',
            url: () => '/user/app-filtering',
            icon: 'app-filter',
            breadcrumbs: false,
            show: (planDetails, selectedChild, selectedDevice) => planDetails?.planDetails?.name?.includes('Parents') && selectedDevice && checkDeviceType(selectedDevice) || planDetails?.planDetails?.name?.includes('Premium') && selectedDevice && checkDeviceType(selectedDevice) ? true : false
        },
        {
            id: 'webfilter',
            title: 'Web Filtering',
            type: 'item',
            url: () => '/user/web-filtering',
            icon: 'web-filter',
            breadcrumbs: false,
            show: (planDetails, selectedChild, selectedDevice) => planDetails?.planDetails?.name?.includes('Parents') && selectedDevice && checkDeviceType(selectedDevice) || planDetails?.planDetails?.name?.includes('Premium') && selectedDevice && checkDeviceType(selectedDevice) ? true : false
        },
        {
            id: 'appusage',
            title: 'App Usage',
            type: 'item',
            url: () => '/user/app-usage',
            icon: 'app-usage',
            breadcrumbs: false,
            show: (planDetails, selectedChild, selectedDevice) => planDetails?.planDetails?.name?.includes('Parents') && selectedDevice && checkDeviceType(selectedDevice) || planDetails?.planDetails?.name?.includes('Premium') && selectedDevice && checkDeviceType(selectedDevice) ? true : false
        },
        {
            id: 'callandsms',
            title: 'Call and SMS',
            type: 'item',
            url: () => '/user/call-sms',
            icon: 'call',
            breadcrumbs: false,
            show: (planDetails, selectedChild, selectedDevice) => planDetails?.planDetails?.name?.includes('Parents') && selectedDevice && checkDeviceType(selectedDevice) || planDetails?.planDetails?.name?.includes('Premium') && selectedDevice && checkDeviceType(selectedDevice) ? true : false
        },
        {
            id: 'advanceFilters',
            title: 'Advance Filters',
            type: 'collapse',
            icon: 'adv-filter',
            showMenu: (planDetails, selectedChild, selectedDevice) => true,
            children: [
                {
                    id: 'webFiltering',
                    title: 'Advance Web Filtering',
                    type: 'item',
                    external: 'true',
                    target: "blank",
                    url: (planDetails) => planDetails?.safednsCreated &&  planDetails?.planDetails?.name?.includes('Premium')  ? 'https://filter.cybersafefamily.com/login/' : '/user/device-manage',
                    breadcrumbs: false,
                    icon: 'web-filter',
                    show: (planDetails, selectedChild) => true

                },
                {
                    id: 'appFiltering',
                    title: 'Advance App Filtering',
                    type: 'item',
                    external: 'true',
                    target: "blank",
                    url: (planDetails) => planDetails?.safednsCreated &&  planDetails?.planDetails?.name?.includes('Premium')  ? 'https://filter.cybersafefamily.com/login/' : '/user/device-manage',
                    breadcrumbs: false,
                    icon: 'app-filter',
                    show: (planDetails, selectedChild) => true

                },
            ]
        },
        {
            id: 'deskmanage',
            title: 'Desktop Management',
            type: 'item',
            external: 'true',
            target: "blank",
            url: (planDetails) => planDetails?.safednsCreated &&  planDetails?.planDetails?.name?.includes('Premium')  ? 'https://filter.cybersafefamily.com/login/' : '/user/device-manage',
            icon: 'desktop',
            breadcrumbs: false,
            show: (planDetails, selectedChild) => true
        },
        {
            id: 'mfa',
            title: 'MFA',
            type: 'item',
            url: () => '/user/mfa',
            icon: 'password',
            breadcrumbs: false,
            show: (planDetails, selectedChild) => true
        },
        {
            id: 'manual',
            title: 'User Manuals',
            type: 'item',
            url: () => '/user/user-manual',
            icon: 'manual',
            breadcrumbs: false,
            show: (planDetails, selectedChild) => true
        },

    ]
};

export default utilities;
