import {
  GET_NOTIFICATION,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAILED,

  GET_PROFILE_DETAILS,
  GET_PROFILE_DETAILS_SUCCESS,
  GET_PROFILE_DETAILS_FAILED,

  UPDATE_PROFILE_DETAILS,
  UPDATE_PROFILE_DETAILS_SUCCESS,
  UPDATE_PROFILE_DETAILS_FAILED,

  GENERAL_STATE_RESET_COMPLETED,

  DELETE_NOTIFICATION,
  DELETE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_FAILED,

  GET_PARENT,
  GET_PARENT_SUCCESS,
  GET_PARENT_FAILED,

  GET_ALL_NOTIFICATION,
  GET_ALL_NOTIFICATION_SUCCESS,
  GET_ALL_NOTIFICATION_FAILED,

} from '../types';
import { deleteCall, deleteCallWithPaylod, getCall, putCall } from './api';
import { database } from '../../firebase'


export const notificationListAction = (query) => (dispatch) => {
  dispatch({ type: GET_NOTIFICATION });
  getCall(`/notification?${query}`, true).then(res => {
    dispatch({ type: GET_NOTIFICATION_SUCCESS, payload: res.data.data?.data });
  }).catch(err => {
    dispatch({ type: GET_NOTIFICATION_FAILED });
  })
};

export const AllnotificationListAction = (query) => (dispatch) => {
  dispatch({ type: GET_ALL_NOTIFICATION });
  getCall(`/notification?${query}`, true).then(res => {
    dispatch({ type: GET_ALL_NOTIFICATION_SUCCESS, payload: res.data.data?.data });
  }).catch(err => {
    dispatch({ type: GET_ALL_NOTIFICATION_FAILED });
  })
};



export const profileDetailAction = (userId) => (dispatch) => {
  dispatch({ type: GET_PROFILE_DETAILS });
  getCall(`/parent/${userId}`, true).then(res => {
    dispatch({ type: GET_PROFILE_DETAILS_SUCCESS, payload: res.data.data });
  }).catch(err => {
    dispatch({ type: GET_PROFILE_DETAILS_FAILED });
  })
};

export const updateProfile = (payload, id) => (dispatch) => {
  dispatch({ type: UPDATE_PROFILE_DETAILS });
  putCall(`/parent`, payload, true).then((res) => {
    dispatch({ type: UPDATE_PROFILE_DETAILS_SUCCESS, payload: res.data.data });
  }).catch(err => {
    dispatch({ type: UPDATE_PROFILE_DETAILS_FAILED });
  })

};

export const deleteNotificationList = (payload) => (dispatch) => {
  dispatch({ type: DELETE_NOTIFICATION });
  deleteCallWithPaylod(`/notification?deviceId=${payload?.payload ? payload?.deviceId : payload}`, payload?.payload ? payload?.payload : '', true).then(res => {
    dispatch({ type: DELETE_NOTIFICATION_SUCCESS, payload: res.data.data });
  }).catch(err => {
    dispatch({ type: DELETE_NOTIFICATION_FAILED });
  })
};

export const deleteNotificationListAll = (deviceId) => (dispatch) => {
  dispatch({ type: DELETE_NOTIFICATION });
  deleteCall(`/notification?deviceId=${deviceId}`, true).then(res => {
    dispatch({ type: DELETE_NOTIFICATION_SUCCESS, payload: res.data.data });
  }).catch(err => {
    dispatch({ type: DELETE_NOTIFICATION_FAILED });
  })
};

export const parentDetailsAction = (deviceId) => (dispatch) => {
  dispatch({ type: GET_PARENT });
  getCall(`/parent`, true).then(res => {
    dispatch({ type: GET_PARENT_SUCCESS, payload: res.data.data });
  }).catch(err => {
    dispatch({ type: GET_PARENT_FAILED });
  })
};

export const resetGeneralState = () => async (dispatch) => {
  dispatch({ type: GENERAL_STATE_RESET_COMPLETED });
};

