import React,{useState} from "react";
import { TextField } from '@mui/material';
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
  } from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";

const PlacesAutocomplete = (props) => {
    const {
      ready,
      value,
      suggestions: { status, data },
      setValue,
      clearSuggestions,
    } = usePlacesAutocomplete({
      requestOptions: {
        /* Define search scope here */
      },
      debounce: 300,
    });
    const [locationData, setLocData] = useState("");
    const ref = useOnclickOutside(() => {
      // When user clicks outside of the component, we can dismiss
      // the searched suggestions by calling this method

    });

    const handleInput = (e) => {
      // Update the keyword of the input element
      setValue(e.target.value);
      setLocData(e.target.value)
    };

    const handleSelect =
      ({ description }) =>
        () => {
          // When user selects a place, we can replace the keyword without request data from API
          // by setting the second parameter to "false"
          setValue(description,false);
          clearSuggestions();

          setLocData(description)
          // Get latitude and longitude via utility functions
          getGeocode({ address: description }).then((results) => {
            try {
              const { lat, lng } = getLatLng(results[0]);
              console.log("📍 Coordinates: ", { lat, lng });
              props?.handleSetSearchCoordinates({lat:lat,lng:lng})
              setLocData(description)
            } catch (error) {
              console.log("😱 Error: ", error);
            }
          });
        };

    console.log(locationData, "values")

    const renderSuggestions = () =>
      data.map((suggestion) => {
        const {
          place_id,
          structured_formatting: { main_text, secondary_text },
        } = suggestion;

        return (
          <li key={place_id} onClick={handleSelect(suggestion)} style={{color: 'black'}}>
            <strong>{main_text}</strong> <small>{secondary_text}</small>
          </li>
        );
      });




    return (
      <div ref={ref}>

        <TextField
          fullWidth
          margin="normal"
          name="firstName"
          value={value}
          onChange={handleInput}
          disabled={!ready}
          placeholder="Enter a Location"
        />
        {/* We can use the "status" to decide whether we should display the dropdown or not */}
        {status === "OK" && <ul>{renderSuggestions()}</ul>}
      </div>
    );
  };

  export default PlacesAutocomplete