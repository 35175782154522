import React, { useState, useRef, useEffect } from "react";
import "./style.scss";

import Mobile1 from "assets/mobile-manual/m1.jpg";
import Mobile2 from "assets/mobile-manual/m2.jpg";
import Mobile3 from "assets/mobile-manual/m3.jpg";
import Mobile4 from "assets/mobile-manual/m4.jpg";
import Mobile5 from "assets/mobile-manual/m5.png";
import Mobile6 from "assets/mobile-manual/m6.png";
import Mobile7 from "assets/mobile-manual/m7.jpg";
import Mobile8 from "assets/mobile-manual/m8.jpg";
import Mobile9 from "assets/mobile-manual/m9.jpg";
import Mobile10 from "assets/mobile-manual/m10.jpg";
import Mobile11 from "assets/mobile-manual/m11.jpg";
import Mobile12 from "assets/mobile-manual/m12.jpg";
import Mobile13 from "assets/mobile-manual/m13.jpg";
import Mobile14 from "assets/mobile-manual/m14.png";
import Mobile15 from "assets/mobile-manual/m15.png";
import Mobile16 from "assets/mobile-manual/m16.jpg";
import Mobile17 from "assets/mobile-manual/m17.jpg";
import Mobile18 from "assets/mobile-manual/m18.png";
import Mobile19 from "assets/mobile-manual/m19.jpg";
import Mobile20 from "assets/mobile-manual/m20.png";
import Mobile21 from "assets/mobile-manual/m21.jpg";
import Mobile22 from "assets/mobile-manual/m22.png";
import Mobile23 from "assets/mobile-manual/m23.jpg";
import Mobile24 from "assets/mobile-manual/m24.png";
import Mobile25 from "assets/mobile-manual/m27.jpg";
import Mobile26 from "assets/mobile-manual/m29.jpg";
import Mobile27 from "assets/mobile-manual/m26.jpg";
import Mobile28 from "assets/mobile-manual/m30.jpg";
import Mobile29 from "assets/mobile-manual/m31.png";
import Mobile30 from "assets/mobile-manual/m32.jpg";
import Mobile31 from "assets/mobile-manual/m33.png";
import Mobile32 from "assets/mobile-manual/m34.jpg";
import Mobile33 from "assets/mobile-manual/m35.jpg";
import Mobile34 from "assets/mobile-manual/m36.jpg";
import Mobile35 from "assets/mobile-manual/m37.png";
import Mobile36 from "assets/mobile-manual/m38.png";
import Mobile37 from "assets/mobile-manual/m39.png";
import Mobile38 from "assets/mobile-manual/m40.jpg";
import Mobile39 from "assets/mobile-manual/m41.png";
import Mobile40 from "assets/mobile-manual/m42.jpg";
import Mobile41 from "assets/mobile-manual/m43.png";
import Mobile42 from "assets/mobile-manual/m44.jpg";
import Mobile43 from "assets/mobile-manual/m46.png";
import Mobile44 from "assets/mobile-manual/m47.jpg";
import Mobile45 from "assets/mobile-manual/m48.jpg";
import Mobile46 from "assets/mobile-manual/m49.jpg";
import Mobile47 from "assets/mobile-manual/m50.png";
import Mobile48 from "assets/mobile-manual/m51.jpg";
import Mobile49 from "assets/mobile-manual/m52.png";
import Mobile50 from "assets/mobile-manual/m53.jpg";
import Mobile51 from "assets/mobile-manual/m54.png";
import Mobile52 from "assets/mobile-manual/m55.jpg";
import Mobile53 from "assets/mobile-manual/m56.jpg";
import Mobile54 from "assets/mobile-manual/m57.png";
import Mobile55 from "assets/mobile-manual/m58.jpg";
import Mobile56 from "assets/mobile-manual/m59.jpg";
import Mobile57 from "assets/mobile-manual/m60.png";
import Mobile58 from "assets/mobile-manual/m61.jpg";
import Mobile59 from "assets/mobile-manual/m62.jpg";
import Mobile60 from "assets/mobile-manual/m63.jpg";
import Mobile61 from "assets/mobile-manual/m66.png";
import Mobile62 from "assets/mobile-manual/m67.png";
import Mobile63 from "assets/mobile-manual/m68.png";
import Mobile64 from "assets/mobile-manual/m69.jpg";
import Mobile65 from "assets/mobile-manual/m70.png";
import Mobile66 from "assets/mobile-manual/m71.png";
import Mobile67 from "assets/mobile-manual/m72.png";
import Mobile68 from "assets/mobile-manual/m73.jpg";
import Mobile69 from "assets/mobile-manual/m74.png";
import Mobile70 from "assets/mobile-manual/m75.jpg";
import Mobile71 from "assets/mobile-manual/m76.png";
import Mobile72 from "assets/mobile-manual/m77.jpg";
import Mobile73 from "assets/mobile-manual/m78.png";
import Mobile74 from "assets/mobile-manual/m79.jpg";
import Mobile75 from "assets/mobile-manual/m80.png";
import Mobile76 from "assets/mobile-manual/m81.jpg";
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";


const Mobilemanual = ({
  selectedChild,
  selectedDevice,

}) => {

  console.log(selectedChild, "manualchild")

  return (
    <div className="mobile-container container-fluid">
            <Helmet>
        <title>Cyber Safe Family - Manual</title>
        <meta name="description" content="Nested component" />
      </Helmet>
      <div>
        <h1 style={{ color: "rgb(14, 86, 166)", textAlign: 'center' }}>Cyber Safe Family Mobile Devices Setup Guide</h1>
        <br></br>
        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Registration</h2>

          <div className="fontStyles">

            <ol>
              <li>The user will be  able to register themself as a parent using registration by entering their Name, Phone number, Mail id and also a password to login inside the account once created.
              </li>
              <li>After Successful verification of OTP from both Mobile and Mail we will be able to login as a parent with the registered credentials.
              </li>
            </ol>
          </div>
        </div>

        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to register</h2>
          <div className="fontStyles">

            <ol>
              <li>Click Signup from the landing page.</li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile1}></img>
              </div>
              <br></br>

              <li>You will be redirected to the registration form page.</li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile2}></img>
              </div>
              <br></br>
              <li>Once you give the details and click sign up you will be redirected to the OTP verification page.</li>

              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile3}></img>
              </div>
              <br></br>
              <li>And when you enter the correct OTP you will be redirected back to the login page.</li>
              <li>In the login page you can enter the registered login credentials and login inside the application.</li>

              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile4}></img>
              </div>
              <br></br>
            </ol>
          </div>
        </div>




        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Re-Registering for wrong details entered users</h2>

          <div className="fontStyles">

            <ol>
              <li>While registering if the Email id / Mobile number entered is wrong and if OTP is not verified, in this case we would be able to correct the credentials and be able to register again.

              </li>
              <li>But if the OTP is verified already then no changes can be done there.

              </li>
            </ol>
          </div>
        </div>



        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to Re-Register</h2>
          <div className="fontStyles">

            <ol>
              <li>Click Signup from the landing page.</li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile1}></img>
              </div>
              <br></br>

              <li>You will be redirected to the registration form page.</li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile2}></img>
              </div>
              <br></br>
              <li>Once you give the details and click sign up you will be redirected to the OTP verification page.</li>

              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile3}></img>
              </div>
              <br></br>
              <li>By any case, if you have given any wrong information related to phone number or Mail id, You can click the back button at the top left of the application.
              </li>
              <li>That will redirect you to be out of the application.</li>
              <li>And you can Re-Register by doing the same signup process.</li>

              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile5}></img>
              </div>
              <br></br>
            </ol>
          </div>
        </div>

        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Login</h2>

          <div className="fontStyles">

            <ol>
              <li>If the User is an registered parent, then they can  use their registered credentials to login inside the application.
              </li>

            </ol>
          </div>
        </div>

        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to Login</h2>
          <div className="fontStyles">

            <ol>
              <li>Click on the Login button.</li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile1}></img>
              </div>
              <br></br>

              <li>You will be redirected to the login page.</li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile4}></img>
              </div>
              <br></br>
              <li>On entering correct registered credentials, you will be successfully logged in inside the application.
              </li>


            </ol>
          </div>
        </div>

        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to Login for non OTP verified accounts</h2>
          <div className="fontStyles">

            <ol>
              <li>Click on the Login button.</li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile1}></img>
              </div>
              <br></br>

              <li>You will be redirected to the login page.</li>
              <li>Enter the credentials which you have used for registration without verifying the OTP.</li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile4}></img>
              </div>
              <br></br>
              <li>Once you click login you will be redirected to the OTP screen for verification.

              </li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile3}></img>
              </div>
              <br></br>
              <li>And when you enter the correct OTP you will be redirected back to the login page.</li>
              <li>In the login page you can enter the registered login credentials and login inside the application.
              </li>


            </ol>
          </div>
        </div>

        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Forgot Password</h2>

          <div className="fontStyles">

            <ul>
              <li>If the user forgot his login password this option is the only way to change the password and Login into the account.
              </li>

            </ul>
          </div>
        </div>


        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps for Forgot Password</h2>
          <div className="fontStyles">

            <ol>
              <li>Click on Forgot password in the login Screen.</li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile6}></img>
              </div>
              <br></br>

              <li>In the Forgot Password page enter the registered Mobile number or Email id
              </li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile7}></img>
              </div>
              <br></br>
              <li>An otp will be send to the entered number or mail id and you will be redirected to the OTP verification page.
              </li>
              <li>Enter the received OTP.
              </li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile8}></img>
              </div>
              <br></br>
              <li> <li>There you can update the password.
              </li></li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile9}></img>
              </div>
              <br></br>
              <li>You will be redirected to the Login page.

              </li>

              <li>There enter the user name and the changed password to login inside the application.
              </li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile4}></img>
              </div>
              <br></br>


            </ol>
          </div>
        </div>

        <div id="dashboard">
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Add Child</h2>
          <ul className="fontStyles">
            <li>
              We will be able to create multiple childs with their specific names
              using this option.
            </li>
            <li>
              Using which we will be able to identify and manage each child along
              with their devices separately.
            </li>
          </ul>
        </div>

        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to Add Child</h2>

          <div className="fontStyles">
            <ol>
              <li>Click “Add Child”</li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile10}></img>
              </div>
              <br></br>
              <li>
                In the Add Child info page, Add the information about the child.
              </li>
              <li>Enter the age.</li>
              <li>Select the type of device of the Child.</li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile13}></img>
              </div>
              <br></br>
              <li>
                On clicking continue you will be redirected to the Profile image uploading page.

              </li>
              <li>You can upload using camera or by selecting from the library.</li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile11}></img>
              </div>
              <br></br>
              <li>After uploading the profile image, and If you click continue you will be redirected to the home page along with a popup displaying the log code for child device syncing.</li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile12}></img>
              </div>
              <br></br>
            </ol>
          </div>
        </div>


        <div >
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Add Device</h2>
          <ul className="fontStyles">
            <li>
              The  device of the child can be synced with the parent device by using the log code from the parent.
            </li>
            <li>
              There are two ways to add device in the application.
            </li>
          </ul>
        </div>

        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to Add Device</h2>
          <div className="fontStyles">
            <ol>
              <li>One way is to use the log code from the popup which is displayed when we add child.</li>


              <li>
                The other way is to use the Add device button near to the child’s name.
              </li>
              <li>On clicking which the logcode popup will be displayed. </li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile14}></img>
              </div>
              <br></br>
            </ol>
          </div>
        </div>

        <div >
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Edit / Delete Child</h2>
        </div>

        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to Edit Child</h2>
          <div className="fontStyles">
            <ol>
              <li>Click on the edit icon next to add device.</li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile15}></img>
              </div>
              <br></br>

              <li>
                In the Edit Child screen you will be able to edit the  details of the child and update it.
              </li>

              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile16}></img>
              </div>
              <br></br>
            </ol>
          </div>
        </div>

        <div >
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Delete Device</h2>
        </div>

        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to Delete Child</h2>
          <div className="fontStyles">
            <ol>
              <li>Click on the delete  icon next to the battery icon.</li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile18}></img>
              </div>
              <br></br>

              <li>
                In the Edit Child screen you will be able to edit the  details of the child and update it.
              </li>

              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile16}></img>
              </div>
              <br></br>
              <li>On the confirmation popup click yes.</li>
              <li>The child will be deleted from the account.</li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile17}></img>
              </div>
              <br></br>
            </ol>
          </div>
        </div>


        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to Delete Device</h2>
          <div className="fontStyles">
            <ol>
              <li>Click on the edit icon next to add device.</li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile18}></img>
              </div>
              <br></br>

              <li>
                On the displayed confirmation popup, click yes.

              </li>


              <li>The child will be deleted from the account.</li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile19}></img>
              </div>
              <br></br>
            </ol>
          </div>
        </div>

        <div >
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Clear All - Home</h2>
        </div>

        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to Clear all Child</h2>
          <div className="fontStyles">
            <ol>
              <li>Click on clear all at the top right of the screen.</li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile20}></img>
              </div>
              <br></br>

              <li>
                On the displayed confirmation popup, click yes.

              </li>


              <li>All the child and devices connected / created in the device will be cleared.</li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile21}></img>
              </div>
              <br></br>
            </ol>
          </div>
        </div>

        <div >
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Viewing Feature details</h2>
        </div>


        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to view the features</h2>
          <div className="fontStyles">
            <ol>
              <li>Click on the side arrow to the right of edit icon.</li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile22}></img>
              </div>
              <br></br>

              <li>
                It will redirect to the screen where the features of the application will be displayed.

              </li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile23}></img>
              </div>
              <br></br>
            </ol>
          </div>
        </div>

        <div id="timescheduler">
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Time Scheduler</h2>
          <ul className="fontStyles">
            <li>
              In Time scheduler, We will be able to block the apps for a certain period of time.
            </li>
            <li>Once the applications are selected the time limit along with the day / week for which that application need to blocked can be selected.
              There are two ways to add device in the application.
            </li>
            <li>Once saved the selected application will be blocked for that specific time limit.</li>
          </ul>
        </div>

        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps for Time Scheduler</h2>
          <div className="fontStyles">
            <ol>
              <li>Click on the Side arrow button</li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile24}></img>
              </div>
              <br></br>

              <li>
                In the screen click the + button.
              </li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile25}></img>
              </div>
              <br></br>
              <li>
                In the redirected screen select the applications you wish to block.
              </li>
              <li>Click continue.</li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile26}></img>
              </div>
              <br></br>
              <li>
                Select Start time and end time of the blocking time along with the days.
              </li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile27}></img>
              </div>
              <br></br>
              <li>
                On confirming the blocking, the application will be blocked for the fixed time.
              </li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile28}></img>
              </div>
              <br></br>
            </ol>
          </div>
        </div>

        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to clear all Time scheduler</h2>
          <div className="fontStyles">
            <ol>
              <li>Click on the clear all button on the top right</li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile29}></img>
              </div>
              <br></br>

              <li>
                Click yes in the confirmation popup.
              </li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile30}></img>
              </div>
              <br></br>
              <li>
                All the schedulers scheduled will be removed from the list.

              </li>

              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile25}></img>
              </div>
              <br></br>


            </ol>
          </div>
        </div>


        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to Update Time Scheduler</h2>
          <div className="fontStyles">
            <ol>
              <li>Click on the Scheduled timer</li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile31}></img>
              </div>
              <br></br>

              <li>
                In the displayed screen make the changes.
              </li>
              <li>Click continue.</li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile26}></img>
              </div>
              <br></br>

              <li>Select Start time and end time of the blocking time along with the days.</li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile27}></img>
              </div>
              <br></br>


              <li>Click confirmation.</li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile28}></img>
              </div>
              <br></br>
              <li>The scheduler will be updated accordingly.</li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile32}></img>
              </div>
              <br></br>


            </ol>
          </div>
        </div>

        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to Delete Time Scheduler</h2>
          <div className="fontStyles">
            <ol>
              <li>Click on the Scheduled timer</li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile31}></img>
              </div>
              <br></br>

              <li>
                In the displayed screen change all the blocked application to Allow.
              </li>

              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile33}></img>
              </div>
              <br></br>

              <li>Click confirmation.</li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile34}></img>
              </div>
              <br></br>
              <li>The scheduler will be deleted.</li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile25}></img>
              </div>
              <br></br>


            </ol>
          </div>
        </div>

        <div id="location">
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Location</h2>
          <ul className="fontStyles">
            <li>
              Location is divided into four Subs.
            </li>
            <li>Geo Location
            </li>
            <li>Geo Fencing</li>
            <li>Drive Safe</li>
            <li>Child Map
            </li>
          </ul>
        </div>



        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to view Location Subs</h2>
          <div className="fontStyles">
            <ol>
              <li>Click on the arrow to the right of the location.</li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile35}></img>
              </div>
              <br></br>

              <li>
                In the dropdown list you will be able to view the sub categories mentioned.
              </li>

              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile36}></img>
              </div>
              <br></br>

              <li>Under Geo location, we will be able to view the child’s location, wherever they have traveled and also can see the current location.</li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile37}></img>
              </div>

            </ol>
          </div>
        </div>

        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to view Geo Location</h2>
          <div className="fontStyles">
            <ol>
              <li>Click on the arrow to the right of Geo location.</li>


              <li>
                You will be able to view the locations of the child along with the current location in the map.
              </li>



              <li>Under Geo location, we will be able to view the child’s location, wherever they have traveled and also can see the current location.</li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile38}></img>
              </div>

            </ol>
          </div>
        </div>

        <div >
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Geo Location</h2>
          <ul className="fontStyles">
            <li>
              Under history the various locations travelled by the child will be listed
            </li>
          </ul>
        </div>


        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to view History</h2>
          <div className="fontStyles">
            <ol>
              <li>Click on history in the bottom left </li>

              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile39}></img>
              </div>
              <br></br>
              <li>
                You will be able to view the locations of the child along with the current location in the map.
              </li>

              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile40}></img>
              </div>

            </ol>
          </div>
        </div>

        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to clear History</h2>
          <div className="fontStyles">
            <ol>
              <li>Click on history in the bottom left </li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile39}></img>
              </div>
              <br></br>
              <li>
                Click on the clear button at the top right.
              </li>

              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile41}></img>
              </div>
              <br></br>
              <li>
                In the confirmation popup confirm the action.
              </li>
              <li>
                The history will be cleared.
              </li>

              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile42}></img>
              </div>
              <br></br>

            </ol>
          </div>
        </div>

        <div >
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Geo Fencing</h2>
          <ul className="fontStyles">
            <li>
              The places where we want our child to be forbidden to be gone and the area where they are only allowed can be marked here.
            </li>
            <li>
              On crossing those areas, the parent will be receiving SMS notifications as well as internal notification.
            </li>

          </ul>
        </div>


        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to create Fence</h2>
          <div className="fontStyles">
            <ol>
              <li>Click Create new fence</li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile43}></img>
              </div>
              <br></br>
              <li>
                Long press on the desired location.

              </li>

              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile44}></img>
              </div>
              <br></br>
              <li>
                Enter the name of the fence.
              </li>
              <li>
                Mark the fence area.
              </li>

              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile45}></img>
              </div>
              <br></br>
              <li>Click done.</li>
              <li>The list can be viewed in the respective screens.</li>

              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile46}></img>
              </div>
              <br></br>
            </ol>
          </div>
        </div>


        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to Edit Fence</h2>
          <div className="fontStyles">
            <ol>
              <li>Click the desired fence.</li>
              <li>
                Make the changes

              </li>
              <li>Click done</li>
            </ol>
          </div>
        </div>

        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to Delete Fence</h2>
          <div className="fontStyles">
            <ol>
              <li>Click on the trash icon in the right of the fence.</li>
              <li>Confirm the process.</li>
              <li>The fence will be removed from the list</li>
            </ol>
          </div>
        </div>

        <div >
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Drive Safe</h2>
          <ul className="fontStyles">
            <li>
              Under drive safe, you will be able to set the driving speed limit for the child.
            </li>
            <li>
              If the child exceeds the limit, notification will be received to the parent.
            </li>

          </ul>
        </div>





        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to set speed limit</h2>
          <div className="fontStyles">
            <ol>
              <li>Click on the arrow to the right of drive safe.</li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile47}></img>
              </div>
              <br></br>
              <li>
                Set the speed limit
              </li>

              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile48}></img>
              </div>
              <br></br>
              <li>
                Click save.

              </li>

            </ol>
          </div>
        </div>


        <div >
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Child Map</h2>
          <ul className="fontStyles">
            <li>
              We will be able to locate all the child devices linked with that particular parent account.
            </li>
            <li>
              Every device will be visible in the map with their profile image.
            </li>

          </ul>
        </div>

        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to view Child Map</h2>
          <div className="fontStyles">
            <ol>
              <li>Click on the arrow to the right of Child Map.</li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile49}></img>
              </div>
              <br></br>
              <li>
                You will be able to view the children’s location along with their profile image in the Map.
              </li>

              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile50}></img>
              </div>
              <br></br>
            </ol>
          </div>
        </div>

        <div id="appfilter">
          <h2 style={{ color: "rgb(14, 86, 166)" }}>App Filtering</h2>
          <ul className="fontStyles">
            <li>
              We will be able to block any app for no desired time limit.
            </li>
            <li>
              Until we allow the application the child won't be able to use the application.
            </li>

          </ul>
        </div>


        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps for app filtering</h2>
          <div className="fontStyles">
            <ol>
              <li>Click on the arrow to the right of App filtering.</li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile51}></img>
              </div>
              <br></br>
              <li>
                Select the app you want to block.
              </li>

              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile52}></img>
              </div>
              <br></br>
              <li>On confirming the app will be blocked.</li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile53}></img>
              </div>
              <br></br>
              <li>On clicking time scheduler, you will be redirected to time scheduler.</li>
            </ol>
          </div>
        </div>

        <div id="webfilter">
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Web Filtering</h2>
          <ul className="fontStyles">
            <li>
              We will be able to block any URL by either pasting it or from the history list.
            </li>
            <li>
              Until we allow the URL the child won't be able to use that URL.

            </li>

          </ul>
        </div>



        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps for web filtering</h2>
          <div className="fontStyles">
            <ol>
              <li>Click on the arrow to the right of Web filtering.</li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile54}></img>
              </div>
              <br></br>
              <li>
                Select the URL you want to block / Paste the URL you want to block.
              </li>

              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile55}></img>
              </div>
              <br></br>
              <li>On confirming the URL will be blocked.</li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile56}></img>
              </div>
              <br></br>

            </ol>
          </div>
        </div>

        <div id="appusage">
          <h2 style={{ color: "rgb(14, 86, 166)" }}>App Usage</h2>
          <ul className="fontStyles">
            <li>
              We will be able to view the time spend by the child on each application.
            </li>
            <li>
              Also the overall summary for per day usage can also be seen.

            </li>

          </ul>
        </div>



        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps for App usage</h2>
          <div className="fontStyles">
            <ol>
              <li>Click on the arrow to the right of App usage.</li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile57}></img>
              </div>
              <br></br>
              <li>
                The summary screen will be seen.
              </li>

              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile58}></img>
              </div>
              <br></br>
              <li>Click on the application to view individual timing.
              </li>
              <li>You can also use the calendar at the top right to view past timings.</li>

              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile59}></img>
              </div>
              <br></br>

            </ol>
          </div>
        </div>

        <div id="settings">
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Home</h2>
          <ul className="fontStyles">
            <li>
              On clicking home, it redirects us to the landing page where we can Add child, Add device as well as see battery percentage of the child device.
            </li>

            <br></br>
            <div>
              <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile60}></img>
            </div>
            <br></br>
          </ul>
        </div>

        <div >
          <h2 style={{ color: "rgb(14, 86, 166)" }}>My Profile</h2>
          <ul className="fontStyles">
            <li>
              On Clicking My profile, You will be able to see the list of items where we will be able to edit the profile, change password and contact with the developer.
            </li>

            <br></br>
            <div>
              <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile61}></img>
            </div>
            <br></br>
          </ul>
        </div>


        <div >
          <h2 style={{ color: "rgb(14, 86, 166)" }}>My Profile - Edit</h2>
          <ul className="fontStyles">
            <li>
              We will be able to edit  the parents profile details.
            </li>
          </ul>
        </div>

        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps for edit parent profile</h2>
          <div className="fontStyles">
            <ol>
              <li>Click on the pencil icon over the profile image.</li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile62}></img>
              </div>
              <br></br>
              <li>
                Make changes.
              </li>

              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile63}></img>
              </div>
              <br></br>
              <li>Enter the OTP received.</li>
              <li>The changes will be saved.</li>
              <br></br>
              <div>
                <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile64}></img>
              </div>
              <br></br>

            </ol>
          </div>
        </div>

        <div >
          <h2 style={{ color: "rgb(14, 86, 166)" }}>My Profile - Parent Device</h2>
          <ul className="fontStyles">
            <li>
              Click on Parent Profile
            </li>

            <br></br>
            <div>
              <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile65}></img>
            </div>
            <br></br>
            <li>you will be able to view the list of parents linked with the account.</li>
            <br></br>
            <div>
              <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile66}></img>
            </div>
            <br></br>
          </ul>
        </div>


        <div >
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Adding New Parent</h2>
          <ul className="fontStyles">
            <li>
            We will be able to add new parents in this screens.

            </li>
          </ul>
        </div>


        <div >
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps for adding new parent </h2>
          <ol className="fontStyles">
            <li>
            Click on the add new parent / the profile icon in the top of home.
            </li>

            <br></br>
            <div>
              <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile67}></img>
            </div>
            <br></br>
            <li>Enter the email id/phone number.</li>
            <br></br>
            <div>
              <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile68}></img>
            </div>
            <br></br>
            <li>On confirming the invitation link will be shared.</li>
          </ol>
        </div>

        <div >
          <h2 style={{ color: "rgb(14, 86, 166)" }}>My Profile - Change Password</h2>
          <ul className="fontStyles">
            <li>
            The password of the profile can be changed with this option.
            </li>
          </ul>
        </div>

        <div >
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to change password</h2>
          <ol className="fontStyles">
            <li>
            Click on Change password.
            </li>

            <br></br>
            <div>
              <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile69}></img>
            </div>
            <br></br>
            <li>Enter the details</li>
            <br></br>
            <div>
              <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile70}></img>
            </div>
            <br></br>
            <li>The changes will be saved on confirming.</li>
          </ol>
        </div>

        <div >
          <h2 style={{ color: "rgb(14, 86, 166)" }}>My Profile - Contact With us</h2>
          <ul className="fontStyles">
            <li>
            The Administrator can be contacted using this option.
            </li>
          </ul>
        </div>

        <div >
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to change password</h2>
          <ol className="fontStyles">
            <li>
            Click on Contact with us.
            </li>

            <br></br>
            <div>
              <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile71}></img>
            </div>
            <br></br>
            <li>Enter the details</li>
            <br></br>
            <div>
              <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile72}></img>
            </div>
            <br></br>
            <li>On confirming a mail will be send to the administrator with your query.</li>
          </ol>
        </div>

        <div >
          <h2 style={{ color: "rgb(14, 86, 166)" }}>My Profile - Terms & Privacy Policy </h2>
          <ul className="fontStyles">
            <li>
            On Clicking which you will be able to view the terms and policies of the application.
            </li>
          </ul>
        </div>

        <div >
          <h2 style={{ color: "rgb(14, 86, 166)" }}>My Profile - Help</h2>
          <ul className="fontStyles">
            <li>
            On Clicking which you will be able to view the FAQs related to the application.
            </li>
          </ul>
        </div>

        <div >
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Notification</h2>
          <ul className="fontStyles">
            <li>
            We will be able to view the notifications  received by the child.
            </li>
            <br></br>
            <div>
              <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile73}></img>
            </div>
            <br></br>
            <li>Also by clicking Clear All at the top, we will be able to select and delete the notifications.</li>
            <br></br>
            <div>
              <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile74}></img>
            </div>
            <br></br>
          </ul>
        </div>


        <div >
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Logout</h2>
          <ul className="fontStyles">
            <li>
            Click on Log out.
            </li>
            <br></br>
            <div>
              <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile75}></img>
            </div>
            <br></br>
            <li>On the popup, confirm the action.</li>
            <br></br>
            <div>
              <img style={{ width: "25%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile76}></img>
            </div>
            <br></br>
            <li>You will be logged out of the profile.</li>
          </ul>
        </div>


      </div>

    </div>
  );
};
const mapStateToProps = ({ childrenReducer, deviceReducer }) => ({

  selectedChild: childrenReducer.selectedChild,
  selectedDevice: childrenReducer.selectedDevice,

});

const mapActionsToProps = {

  // setSelectedChildAction,
  // setSelectedDeviceAction,

};

export default connect(mapStateToProps, mapActionsToProps)(Mobilemanual);

