import {
    GET_NOTIFICATION,
    GET_NOTIFICATION_SUCCESS,
    GET_NOTIFICATION_FAILED,

    GET_PROFILE_DETAILS,
    GET_PROFILE_DETAILS_SUCCESS,
    GET_PROFILE_DETAILS_FAILED,

    UPDATE_PROFILE_DETAILS,
    UPDATE_PROFILE_DETAILS_SUCCESS,
    UPDATE_PROFILE_DETAILS_FAILED,

    DELETE_NOTIFICATION,
    DELETE_NOTIFICATION_SUCCESS,
    DELETE_NOTIFICATION_FAILED,

    GET_PARENT,
    GET_PARENT_SUCCESS,
    GET_PARENT_FAILED,

    GET_ALL_NOTIFICATION,
    GET_ALL_NOTIFICATION_SUCCESS,
    GET_ALL_NOTIFICATION_FAILED,

    GENERAL_STATE_RESET_COMPLETED
} from '../types';

export const initialState = {
    loading: false,
    notiList: [],
    profileData: [],
    selectedDeviceDynamicInfo: null,
    isUpdated: false,
    isDeleted: false,
    parentData: [],
    totalNotification: 0,
    allnotiList: [],
    alltotalCount: 0
};

// ==============================|| DEVICE REDUCER ||============================== //

const generalReducer = (state = initialState, action) => {

    switch (action.type) {

        case GET_NOTIFICATION:
            return {
                ...state,
                loading: true
            };
        case GET_NOTIFICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                notiList: [...state.notiList, ...action.payload?.data],
                totalNotification: action.payload.total
            };
        case GET_NOTIFICATION_FAILED:
            return {
                ...state,
                loading: false
            };


        case GET_PROFILE_DETAILS:
            return {
                ...state,
                loading: true
            };
        case GET_PROFILE_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                profileData: action.payload
            };
        case GET_PROFILE_DETAILS_FAILED:
            return {
                ...state,
                loading: false
            };

        case UPDATE_PROFILE_DETAILS:
            return { ...state, loading: true };
        case UPDATE_PROFILE_DETAILS_SUCCESS:
            return { ...state, loading: false, isUpdated: true };
        case UPDATE_PROFILE_DETAILS_FAILED:
            return { ...state, loading: false, errMessage: action.payload };

        case DELETE_NOTIFICATION:
            return { ...state, loading: true };
        case DELETE_NOTIFICATION_SUCCESS:
            return { ...state, loading: false, isDeleted: true };
        case DELETE_NOTIFICATION_FAILED:
            return { ...state, loading: false, errMessage: action.payload };

        case GENERAL_STATE_RESET_COMPLETED:
            return { ...state, errMessage: '', isUpdated: false, isDeleted: false, notiList: [] };

        case GET_PARENT:
            return {
                ...state,
                loading: true
            };
        case GET_PARENT_SUCCESS:
            return {
                ...state,
                loading: false,
                parentData: action.payload
            };
        case GET_PARENT_FAILED:
            return {
                ...state,
                loading: false
            };

        case GET_ALL_NOTIFICATION:
            return {
                ...state,
                loading: true
            };
        case GET_ALL_NOTIFICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                allnotiList: action.payload?.data,
                alltotalCount: action.payload.total
            };
        case GET_ALL_NOTIFICATION_FAILED:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
};

export default generalReducer;
