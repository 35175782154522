import {
  ADD_DEVICE,
  ADD_DEVICE_SUCCESS,
  ADD_DEVICE_FAILED,
  REMOVE_DEVICE,
  REMOVE_DEVICE_SUCCESS,
  REMOVE_DEVICE_FAILED,

  DEIVCE_DYNAMIC_DATA,
  DEVICE_STATE_RESET_COMPLETED,
} from '../types';
import { postCall, deleteCall } from './api';
import { database } from '../../firebase'
import moment from 'moment';

// export const addDeviceAction = (payload) => (dispatch) => {
//   dispatch({ type: ADD_DEVICE });
//   postCall('/child/addDevice', payload, true).then((res) => {
//       dispatch({ type: ADD_DEVICE_SUCCESS, payload: res.data }); 
//   })
//   dispatch({ type: ADD_DEVICE_FAILED });
// };

export const addDeviceAction = (payload) => async (dispatch) => {
    dispatch({ type: ADD_DEVICE });
    postCall('/child/addDevice', payload, true)
      .then((res) => {
        dispatch({ type: ADD_DEVICE_SUCCESS,payload: res.data });
      })
      .catch((err) => {
        console.log(err, "errormsgrec")
        dispatch({ type: ADD_DEVICE_FAILED,payload:err?.response?.data });
      });
  };

export const removeDeviceAction = (deviceId) => (dispatch) => {
  dispatch({ type: REMOVE_DEVICE });
  deleteCall(`/child/device/${deviceId}`, true).then((res) => {
      dispatch({ type: REMOVE_DEVICE_SUCCESS, payload: res.data }); 
  })
  dispatch({ type: REMOVE_DEVICE_FAILED });
};

export const fbGetDeviceAction = (deviceId) => (dispatch) => {
  let ref = database.ref(`/${deviceId}`);
  var m = moment();

  var ms = m.milliseconds() + 1000 * (m.seconds() + 60 * (m.minutes() + 60 * m.hours()));

  ref.child('getlocation').set(ms.toString())
  ref.on('value', snapshot => {
    dispatch({ type: DEIVCE_DYNAMIC_DATA, payload: snapshot.val()})
  });
};

export const resetDeviceState = () => async (dispatch) => {
  dispatch({ type: DEVICE_STATE_RESET_COMPLETED });
};
