import * as actionTypes from "../actions/actions";
import { postCall, putCall } from "./api";

export const loginUser = (payload) => async (dispatch) => {
  dispatch({ type: actionTypes.LOGIN_USER_INPROGRESS });
  postCall('/login', payload)
    .then((user) => {
      dispatch({ type: actionTypes.LOGIN_USER_COMPLETED, payload: user.data });
    })
    .catch((err) => {
      dispatch({ type: actionTypes.LOGIN_USER_FAILED,payload:err?.response?.data });
    });
};

export const forgotPassword = (payload) => async (dispatch) => {
  dispatch({ type: actionTypes.FORGOT_PASSWORD_INPROGRESS });
  postCall('/forgotPassword', payload)
    .then((user) => {
      dispatch({ type: actionTypes.FORGOT_PASSWORD_COMPLETED, payload: user?.data?.message });
    })
    .catch((err) => {
      dispatch({ type: actionTypes.FORGOT_PASSWORD_FAILED,payload:err?.response?.data?.message });
    });
  };
    
export const registerUser = (payload) => async (dispatch) => {
  dispatch({ type: actionTypes.REGISTER_USER_INPROGRESS });
  postCall("/signUp", payload)
    .then((user) => {
      dispatch({ type: actionTypes.REGISTER_USER_COMPLETED, payload: user?.data});
    })
    .catch((err) => {
      dispatch({ type: actionTypes.REGISTER_USER_FAILED,payload:err?.response?.data?.message });
    });
};

export const forgotOTPValidate = (payload) => async (dispatch) => {
  dispatch({ type: actionTypes.FORGOT_OTP_INPROGRESS});
  postCall('/forgotPassword/validateOtp',payload)
    .then((user) => {
      dispatch({ type: actionTypes.FORGOT_OTP_COMPLETED,payload:payload });
    })
    .catch((err) => {
      dispatch({ type: actionTypes.FORGOT_OTP_FAILED,payload:err?.response?.data?.message});  
    });
  };

  export const updatePassword = (payload) => async (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_PASSWORD_INPROGRESS});
    putCall('/forgotPassword',payload)
      .then((user) => {
        dispatch({ type: actionTypes.UPDATE_PASSWORD_COMPLETED,payload:user?.data?.message });
      })
      .catch((err) => {
        dispatch({ type: actionTypes.UPDATE_PASSWORD_FAILED,payload:err?.response?.data?.message});  
      });
   };

  export const verifyOtpPhone = (payload) => async (dispatch) => {
   dispatch({ type: actionTypes.OTP_PHONE_INPROGRESS });
   postCall("/validateOtp?phone=true", payload)
    .then((user) => {
      dispatch({ type: actionTypes.OTP_PHONE_COMPLETED, payload: user?.data?.message });
    })
    .catch((err) => {
      dispatch({ type: actionTypes.OTP_PHONE_FAILED, payload:err?.response?.data?.message });
    });
};

export const resendOtpPhone = (payload) => async (dispatch) => {
  postCall(`/resendOtp?phone=true&userId=${payload}`)
   .then((user) => {
   })
   .catch((err) => {
   });
};

export const resendEmailPhone = (payload) => async (dispatch) => {
  postCall(`/resendOtp?email=true&userId=${payload}`)
   .then((user) => {
   })
   .catch((err) => {
   });
};

export const verifyOtpEmail = (payload) => async (dispatch) => {
  dispatch({ type: actionTypes.OTP_EMAIL_INPROGRESS});
  postCall("/validateOtp?email=true", payload)
    .then((user) => {
      dispatch({ type: actionTypes.OTP_EMAIL_COMPLETED, payload: user?.data?.message });
    })
    .catch((err) => {
      dispatch({ type: actionTypes.OTP_EMAIL_FAILED, payload:err?.response?.data?.message });
    });
};

export const changePassword = (payload) => async (dispatch) => {
  dispatch({ type: actionTypes.CHANGE_PASSWORD_INPROGRESS});
  postCall('/update/password',payload, true)
    .then((user) => {
      dispatch({ type: actionTypes.CHANGE_PASSWORD_COMPLETED,payload:user?.data?.message });
    })
    .catch((err) => {
      dispatch({ type: actionTypes.CHANGE_PASSWORD_FAILED,payload:err?.response?.data?.message});  
    });
 };

 export const contactUs = (payload) => async (dispatch) => {
  dispatch({ type: actionTypes.CONTACTUS_INPROGRESS});
  postCall('/contactus',payload, true)
    .then((user) => {
      dispatch({ type: actionTypes.CONTACTUS_COMPLETED,payload:user?.data?.message });
    })
    .catch((err) => {
      dispatch({ type: actionTypes.CONTACTUS_FAILED,payload:err?.response?.data?.message});  
    });
 };

 export const verifyProfileOtp = (payload) => async (dispatch) => {
  dispatch({ type: actionTypes.OTP_EMAIL_INPROGRESS});
  putCall("/parent/verifyUpdate", payload, true)
    .then((user) => {
      dispatch({ type: actionTypes.OTP_EMAIL_COMPLETED, payload: user?.data?.message });
    })
    .catch((err) => {
      dispatch({ type: actionTypes.OTP_EMAIL_FAILED, payload:err?.response?.data?.message });
    });
};

export const inviteByEmail = (payload) => async (dispatch) => {
  dispatch({ type: actionTypes.INVITE_EMAIL_INPROGRESS});
  postCall('/emailInvite',payload, true)
    .then((user) => {
      dispatch({ type: actionTypes.INVITE_EMAIL_COMPLETED,payload:user?.data?.message });
    })
    .catch((err) => {
      dispatch({ type: actionTypes.INVITE_EMAIL_FAILED,payload:err?.response?.data?.message});  
    });
 };

 export const inviteByPhone = (payload) => async (dispatch) => {
  dispatch({ type: actionTypes.INVITE_PHONE_INPROGRESS});
  postCall('/mobileInvite',payload, true)
    .then((user) => {
      dispatch({ type: actionTypes.INVITE_PHONE_COMPLETED,payload:user?.data?.message });
    })
    .catch((err) => {
      dispatch({ type: actionTypes.INVITE_PHONE_FAILED,payload:err?.response?.data?.message});  
    });
 };




export const resetAuthState = () => async (dispatch) => {
  dispatch({ type: actionTypes.AUTH_STATE_RESET_COMPLETED });
};