import * as React from 'react';
import Box from '@mui/material/Box';
import LoadingButton,{loadingButtonClasses}  from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import "./styles.scss"

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius:2,
  p: 3,
};

const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
    [`&.${loadingButtonClasses.loadingIndicator}`]: {
      color: 'white !important',
    },
  }));
  

export default function BasicModal(props) {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => props?.handleCloseModal(false)

  React.useEffect(()=>{
    setOpen(props?.open)
  },[props?.open])

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h2" component="h2" sx={{textAlign:'center', }}>
            {props?.mainTitle}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2.5,textAlign:'center' , color:'black'}}>
            {props?.description}
          </Typography>
          <Stack direction="row" spacing={2} justifyContent="center" sx={{mt:2.5}}>
              {props?.buttons && props?.buttons?.map((btn)=>(
                  <StyledLoadingButton loading={btn?.loading} style={btn?.style} onClick={btn?.handleClick}>{btn?.loading ? '' : btn?.title}</StyledLoadingButton >
              ))}
          </Stack>
        </Box>
      
      </Modal>
    </div>
  );
}