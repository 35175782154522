import { Typography } from "@mui/material";

// project imports
import NavGroup from "./NavGroup";
import menuItem from "menu-items";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import React from "react";

// material-ui
import { styled, useTheme } from "@mui/material/styles";
import {
  AppBar,
  Box,
  CssBaseline,
  Toolbar,
  useMediaQuery,
  Grid,
} from "@mui/material";

import {
  fbGetDeviceAction,
  loadChildrenAction,
  loadPlanAction,
} from "store/actions";
import { connect } from "react-redux";



// styles


const proUtiItems = menuItem?.proUti.map((item) => {
  switch (item.type) {
    case "group":
      return <NavGroup key={item.id} item={item} />;
    default:
      return (
        <Typography key={item.id} variant="h6" color="error" align="center">
          Menu Items Error
        </Typography>
      );
  }
});

const navItemsApple = menuItem?.iosItem.map((item) => {
  switch (item.type) {
    case "group":
      return <NavGroup key={item.id} item={item} />;
    default:
      return (
        <Typography key={item.id} variant="h6" color="error" align="center">
          Menu Items Error
        </Typography>
      );
  }
});

const navItemsProIos = menuItem?.proiosItem.map((item) => {
  switch (item.type) {
    case "group":
      return <NavGroup key={item.id} item={item} />;
    default:
      return (
        <Typography key={item.id} variant="h6" color="error" align="center">
          Menu Items Error
        </Typography>
      );
  }
});

const navItemsNoType = menuItem?.childItem.map((item) => {
  switch (item.type) {
    case "group":
      return <NavGroup key={item.id} item={item} />;
    default:
      return (
        <Typography key={item.id} variant="h6" color="error" align="center">
          Menu Items Error
        </Typography>
      );
  }
});

const navProNoType = menuItem?.proNoItem.map((item) => {
  switch (item.type) {
    case "group":
      return <NavGroup key={item.id} item={item} />;
    default:
      return (
        <Typography key={item.id} variant="h6" color="error" align="center">
          Menu Items Error
        </Typography>
      );
  }
});

// ==============================|| MAIN LAYOUT ||============================== //

const MenuList = ({
  fbGetDeviceAction,
  selectedDevice,
  loadChildrenAction,
  selectedChild,
  loadPlanAction,
  planDetails,
  selectedDeviceDynamicInfo,
}) => {
  const theme = useTheme();

  useEffect(() => {
    if (selectedDevice) {
      fbGetDeviceAction(selectedDevice?.deviceId);
    }
  }, [selectedDevice]);

  useEffect(() => {
    loadPlanAction();
  }, []);

  const navItems = (planName) => {
   return menuItem?.items.map((item) => {
      switch (item.type) {
        case "group":
          return <NavGroup key={item.id} item={item} planName={planName} selectedChild={selectedChild} selectedDevice={selectedDevice} />;
        default:
          return (
            <Typography key={item.id} variant="h6" color="error" align="center">
              Menu Items Error
            </Typography>
          );
      }
    
  } );
}
  
  return (
    <>
    {navItems(planDetails)}
      {/* {
        planDetails?.planDetails?.name.includes('Parents') || planDetails?.planDetails?.name.includes('Premium') ? (
          selectedChild === null ||
            selectedChild === undefined || !selectedChild.devices.length
            ? navProNoType
            : selectedChild?.deviceType === "Android" ||
              selectedChild?.deviceType === "Tablet" ||
              selectedChild?.deviceType === "Windows Desktop"
              ? proUtiItems
              : navItemsProIos
        ) : (
          selectedChild?.selectedChild === null ||
            selectedChild?.selectedChild === undefined || !selectedChild.devices.length
            ? navItemsNoType
            : selectedChild?.deviceType === "Android" ||
              selectedChild?.deviceType === "Tablet" ||
              selectedChild?.deviceType === "Windows Desktop"
              ? navItems
              : navItemsApple
        )
      } */}
    </>
  );
};

const mapStateToProps = ({ childrenReducer, deviceReducer }) => ({
  selectedDevice: childrenReducer.selectedDevice,
  selectedChild: childrenReducer.selectedChild,
  selectedDeviceDynamicInfo: deviceReducer.selectedDeviceDynamicInfo,
  planDetails: childrenReducer?.planDetails,
});

const mapActionsToProps = {
  fbGetDeviceAction,
  loadChildrenAction,
  loadPlanAction,
};

export default connect(mapStateToProps, mapActionsToProps)(MenuList);
