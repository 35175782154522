import {
    GET_SCHEDULAR_LIST,
    GET_SCHEDULAR_LIST_SUCCESS,
    GET_SCHEDULAR_LIST_FAILED,
    
    GET_SCHEDULAR_APP_LIST,
    GET_SCHEDULAR_APP_LIST_SUCCESS,
    GET_SCHEDULAR_APP_LIST_FAILED,

    CREATE_SCHEDULE,
    CREATE_SCHEDULE_SUCCESS,
    CREATE_SCHEDULE_FAILED,

    UPDATE_SCHEDULE,
    UPDATE_SCHEDULE_SUCCESS,
    UPDATE_SCHEDULE_FAILED,

    DELETE_SCHEDULE,
    DELETE_SCHEDULE_SUCCESS,
    DELETE_SCHEDULE_FAILED,

    CLEAR_SCHEDULE,
    CLEAR_SCHEDULE_SUCCESS,
    CLEAR_SCHEDULE_FAILED,

} from '../types';

import { deleteCall, getCall, postCall, putCall } from './api'

export const getAllSchedularList = (payload) => (dispatch) => {
    dispatch({ type: GET_SCHEDULAR_LIST });
    getCall(`/schedule?deviceId=${payload}`, true).then((res) => {
        dispatch({ type: GET_SCHEDULAR_LIST_SUCCESS, payload: res.data.data }); 
    })
    dispatch({ type: GET_SCHEDULAR_LIST_FAILED });
};

export const getAllAppsSchedular = (payload) => (dispatch) => {
    dispatch({ type: GET_SCHEDULAR_APP_LIST });
    getCall(`/application?deviceId=${payload}`, true).then((res) => {
        dispatch({ type: GET_SCHEDULAR_APP_LIST_SUCCESS, payload: res.data.data }); 
    })
    dispatch({ type: GET_SCHEDULAR_APP_LIST_FAILED });
};

export const createSchedule = (payload) => (dispatch) => {
    dispatch({ type: CREATE_SCHEDULE });
    postCall(`/schedule`, payload,true).then((res) => {
        dispatch({ type: CREATE_SCHEDULE_SUCCESS, payload: res.data.data }); 
    })
    dispatch({ type: CREATE_SCHEDULE_FAILED });
};

export const updateSchedule = (payload,id) => (dispatch) => {
    dispatch({ type: UPDATE_SCHEDULE });
    putCall(`/schedule/${id}`, payload,true).then((res) => {
        dispatch({ type: UPDATE_SCHEDULE_SUCCESS, payload: res.data.data }); 
    })
    dispatch({ type: UPDATE_SCHEDULE_FAILED });
};

export const deleteSchedule = (id) => (dispatch) => {
    dispatch({ type: DELETE_SCHEDULE });
    deleteCall(`/schedule/${id}`,true).then((res) => {
        dispatch({ type: DELETE_SCHEDULE_SUCCESS, payload: res.data.data }); 
    })
    dispatch({ type: DELETE_SCHEDULE_FAILED });
};

export const clearSchedule = (id) => (dispatch) => {
    dispatch({ type: CLEAR_SCHEDULE });
    deleteCall(`/schedule/clear?deviceId=${id}`,true).then((res) => {
        dispatch({ type: CLEAR_SCHEDULE_SUCCESS, payload: res.data.data }); 
    })
    dispatch({ type: CLEAR_SCHEDULE_FAILED });
};



