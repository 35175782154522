import React from 'react';
import { Box, Typography, Grid, Button } from '@mui/material'

export default function DeviceDelete(props) {
  return(
    <Box sx={{minWidth: '300px', mt: '.5rem'}}>
      <Grid container>
        <Typography sx={{margin: 'auto'}} variant="h1">Remove Device</Typography>
      </Grid>
      <Typography sx={{marginTop: '1rem'}} variant="sbutitle2">Are you sure want to remove this device from this child?</Typography>
      <Grid container sx={{mt: '1rem'}}>
        <Button color="error" variant={'contained'} onClick={props.onConfirmation} sx={{margin: 'auto'}}>
          <b>Yes, Remove now</b>
        </Button>
      </Grid>
    </Box>
  )
}