import config from "config";
import axios from "axios";

export const getCall = (query, header) => {
    return new Promise((resolve, reject) => {
      axios
        .get(config.baseUrl + query, header ? {headers: {Authorization: localStorage.getItem('authToken')}} : {})
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);  
        });
    });
  };

  export const postCall = (query, payload, header) => {
    return new Promise((resolve, reject) => {
      axios
        .post(config.baseUrl + query, payload, header ? {headers: {Authorization: localStorage.getItem('authToken')}} : {})
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  
  export const postImage = (query, payload, header) => {
    const formData = new FormData();
    formData.append('image', payload);

    return new Promise((resolve, reject) => {
      axios
        .post(config.baseUrl + query, formData, header ? {headers: {Authorization: localStorage.getItem('authToken'),'content-type': 'multipart/form-data'}} : {})
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  
  
  
  export const putCall = (query, payload, header) => {
    return new Promise((resolve, reject) => {
      axios
        .put(config.baseUrl + query, payload, header ? {headers: {Authorization: localStorage.getItem('authToken')}} : {})
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  
  export const deleteCall = (query, header) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(config.baseUrl + query, header ? {headers: {Authorization: localStorage.getItem('authToken')}} : {})
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  export const deleteCallWithPaylod = (query, payload,header) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(config.baseUrl + query, header ? {data: payload ,headers: {Authorization: localStorage.getItem('authToken')}} : {})
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  
  
  