import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import GeoFence from 'layout/MainLayout/Geofence';
import AddGeoFence from 'layout/MainLayout/add-geofence';
import Loadable from 'ui-component/Loadable';
import {RequireAuth} from "utils/helpers"

const Dashboard = Loadable(lazy(() => import('views/pages/dashboard')));
const GeoLocation = Loadable(lazy(() => import('views/pages/geo-location/geolocation')));
const DriveSafe = Loadable(lazy(() => import('views/pages/drive-safe')));
const WebFiltering = Loadable(lazy(()=> import('views/pages/web-filtering')))
const CallSms = Loadable(lazy(() => import('views/pages/call-sms')));
const FamilyMap = Loadable(lazy(() => import('views/pages/family-safe')));
const AppFilter = Loadable(lazy(() => import('views/pages/app-filtering')));
const TimeSchedulor  = Loadable(lazy(()=> import('views/pages/time-schedulor')))
const AppUsage  = Loadable(lazy(()=> import('views/pages/app-usage')))
const SamplePage = Loadable(lazy(() => import('views/sample-page')));
const Help = Loadable(lazy(() => import('views/pages/help')));

// const AuthChangePassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/changePassword')));
const AuthContactUs = Loadable(lazy(() => import('views/pages/authentication/authentication3/contactUs')));
const AuthEditProfile = Loadable(lazy(() => import('views/pages/authentication/authentication3/editProfile')));
const AuthVerification = Loadable(lazy(() => import('views/pages/authentication/authentication3/profileVerify')));
const AuthChangePassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/changePassword')));


// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/user',
    element: <RequireAuth><MainLayout /></RequireAuth>,
    children: [
        {
            path: '/dashboard',
            element:<Dashboard/>,
        },
        {
            path: '/geo-location',
            element: <GeoLocation />
        },
        {    
            path: '/geo-fencing',
            element: <GeoFence />
        },
        {    
            path: '/drive-safe',
            element: <DriveSafe />
        },
        {
            path: '/add-geofence',
            element: <AddGeoFence />
        },
        {
            path: '/call-sms',
            element: <CallSms />
        },
        {
            path: '/web-filtering',
            element: <WebFiltering />
        },
        {
            path: '/child-map',
            element: <FamilyMap />
        },
        {
            path: '/app-filtering',
            element: <AppFilter />
        },
        {
            path: '/time-scheduler',
            element: <TimeSchedulor />
        },
        {
            path: '/sample-page',
            element: <SamplePage />
        },
        {
            path: '/app-usage',
            element: <AppUsage />
        },

        // {
        //     path: '/contact-us',
        //     element: <AuthContactUs />
        // },
        // {
        //     path: '/edit-profile',
        //     element: <AuthEditProfile />
        // },
        // {
        //     path: '/verify',
        //     element: <AuthVerification />
        // },
        // {
        //     path: '/change-password',
        //     element: <AuthChangePassword />
        // },
        // {
        //     path: '/help',
        //     element: <Help />
        // }
    ]
};

export default MainRoutes;
