import React, { useState, useRef, useEffect } from "react";
import "./style.scss";


import R1 from "assets/windows-manual/r1.png";
import R2 from "assets/windows-manual/r2.png";
import R3 from "assets/windows-manual/r3.png";
import R4 from "assets/windows-manual/r4.png";
import R5 from "assets/windows-manual/r5.png";
import R6 from "assets/windows-manual/r6.png";
import R7 from "assets/windows-manual/r7.png";
import R8 from "assets/windows-manual/r8.png";
import R9 from "assets/windows-manual/r9.png";
import R10 from "assets/windows-manual/r10.png";
import R11 from "assets/windows-manual/r11.png";
import R12 from "assets/windows-manual/r12.png";
import { Helmet } from "react-helmet";

export default function Routermanual() {

  return (
    <div className="router-container container-fluid">
      <Helmet>
        <title>Cyber Safe Family - Router Installation Manual</title>
        <meta name="description" content="Nested component" />
      </Helmet>
      <div>
        <h1 style={{ color: "rgb(14, 86, 166)", textAlign: 'center' }}>Cyber Safe Family Router Setup Guide (IPv4)</h1>
        <div>
          <br></br>
          <p className="fontStyles">
            The first step is the same for both Static and Dynamic IP address configurations: change your router’s DNS servers to the addresses - <b>195.46.39.39</b> and <b>195.46.39.40</b>
          </p>
          <div>
            <img style={{ width: "100%" }} src={R1}></img>
          </div>
        </div>
        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Router with Static IP address configuration</h2>
          <br></br>
          <iframe width="80%" display='block' marginLeft='auto' marginRight='auto' height="500" allowfullscreen src="https://www.youtube.com/embed/zzLLldNPnyU"></iframe>
          <br></br>
          <div className="fontStyles">

            Go to <b> Dashboard - Settings - Devices </b> and copy your IP address to the <b>"IP addresses / DynDNS"</b> box, choose a policy, and click <b>"Add".</b>
            <br></br>
            <div>
              <img style={{ width: "100%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={R2}></img>
            </div>
            <br></br>
            <b>
              You have successfully configured your router. <br></br>
              !Please note that settings take 5-7 minutes to apply.<br></br>
              !Stats and filtering status update hourly.
            </b>
          </div>
        </div>

        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Router with Dynamic IP address configuration</h2>
          <br></br>
          <iframe width="80%" height="500" allowfullscreen src="https://www.youtube.com/embed/XuY-UjPULXw"></iframe>
          <br></br>

          <div className="fontStyles">

            In case you have a Dynamic IP address, you need to configure the DynDNS/DDNS on the router using a third-party Dynamic DNS service.
            <ol>
              <li>
                In the router setting, go to DynDNS/DDNS settings,
              </li>
              <br></br>
              <div>
                <img style={{ width: "100%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={R3}></img>
              </div>
              <br></br>
              <li>Check the DynDNS service available for your router. If available, we recommend using the No IP DynDNS service provider.</li>
              <li>Go to the website noip.com and sign up.</li>
              <br></br>
              <div>
                <img style={{ width: "100%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={R4}></img>
              </div>
              <br></br>
              <li>In your No-IP account, create a hostname (any name you can think of).</li>
              <br></br>
              <div>
                <img style={{ width: "100%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={R5}></img>
              </div>
              <br></br>
              <div>
                <img style={{ width: "100%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={R6}></img>
              </div>
              <br></br>
              <div>
                <img style={{ width: "100%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={R7}></img>
              </div>
              <br></br>
              <li>Go back to your router and configure DDNS/DynDNS.</li>

              <ul>
                <li>Select No-IP as <b>"Service provider".</b></li>
                <li>Type in the created hostname in the <b>"Hostname"</b> field.</li>
                <li>Type in your No-IP account credentials as "Username" and "Password", and click "Save".</li>
              </ul>
              <br></br>
              <div>
                <img style={{ width: "100%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={R8}></img>
              </div>
              <br></br>
              If everything is correct, DynDNS/DDNS settings will apply.
              <br></br>
              <div>
                <img style={{ width: "624", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={R9}></img>
              </div>
              <li>Go to SafeDNS <b>Dashboard  Settings - Devices</b>  and copy your DynDNS hostname to the <b>"IP addresses / DynDNS"</b>  box, choose a policy, and click<b>"Add".</b> </li>
              <br></br>
              <div>
                <img style={{ width: "100%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={R10}></img>
              </div>
              <br></br>
              Once added, you will see it in the DynDNS section of the Dashboard.
              <br></br>
              <div>
                <img style={{ width: "100%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={R11}></img>
              </div>
            </ol>
            <b>
              You have successfully configured your router. <br></br>
              !Please note that settings take 5-7 minutes to apply.<br></br>
              !Stats and filtering status update hourly.
            </b>


          </div>
        </div>

        <br></br>
        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Configuring the filter using the DDclient.</h2>
          <br></br>


          <div className="fontStyles">

            In rare cases when your router does not have a DynDNS/DDNS setting and your IP is Dynamic,  you need to use third-party software <b>- DDclient -</b>  on the PC connected to the network:
            <ol>
              <li>
                Set up DNS servers in your router:
              </li>
              <div>
                <img style={{ width: "100%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={R12}></img>
              </div>
              <br></br>
              <li>Install DDclient on a PC connected to the network. Once DDclient is installed, all devices connected to the same network will be filtered by the same rules.</li>


            </ol>

          </div>
        </div>

      </div>

    </div>
  );
}
