import {
  ADD_DEVICE,
  ADD_DEVICE_SUCCESS,
  ADD_DEVICE_FAILED,
  REMOVE_DEVICE,
  REMOVE_DEVICE_SUCCESS,
  REMOVE_DEVICE_FAILED,
  DEIVCE_DYNAMIC_DATA,
  DEVICE_STATE_RESET_COMPLETED,
} from '../types';

export const initialState = {
  loading: false,
  addDevice: null,
  selectedDeviceDynamicInfo: null,
  removeDevice : null,
  errMessage: '',
};

// ==============================|| DEVICE REDUCER ||============================== //

const deviceReducer = (state = initialState, action) => {

  switch (action.type) {
      case ADD_DEVICE:
          return {
              ...state,
              loading: true
          };
      case ADD_DEVICE_SUCCESS:
          return {
              ...state,
              loading: false,
              addDevice: action.payload
          };
      case ADD_DEVICE_FAILED:
          return {
              ...state,
              loading: false,
              errMessage: action.payload
          };
      case REMOVE_DEVICE:
        return {
            ...state,
            loading: true
        };
      case REMOVE_DEVICE_SUCCESS:
          return {
              ...state,
              loading: false,
              removeDevice: action.payload
          };
      case REMOVE_DEVICE_FAILED:
          return {
              ...state,
              loading: false
          };
      case DEIVCE_DYNAMIC_DATA:
          return {
            ...state,
            selectedDeviceDynamicInfo: action.payload
          };

          case DEVICE_STATE_RESET_COMPLETED:
            return { ...state, removeDevice : '' , addDevice: ''};
      default:
          return state;
  }
};

export default deviceReducer;
