import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import {RequireAuthDashboard} from "utils/helpers"

// login option 3 routing
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
const AuthForgotPassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/forgotPassword')));
const AuthVerification = Loadable(lazy(() => import('views/pages/authentication/authentication3/RegisterVerification')));
const AuthUpdatePassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/updatePassword')));


// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element:<RequireAuthDashboard><MinimalLayout /></RequireAuthDashboard> ,
    children: [
        {   index:true,
            element:  <AuthLogin3 />
        },
        {
            path: 'register',
            element: <AuthRegister3 />
        },
        {
            path: 'forgot-password',
            element: <AuthForgotPassword />
        },
        {
            path: 'verification',
            element: <AuthVerification />
        },
        {
            path: 'update-password',
            element: <AuthUpdatePassword />
        },
        
    ]
};

export default AuthenticationRoutes;
