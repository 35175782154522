import * as React from 'react';
import LoadingButton, { loadingButtonClasses } from '@mui/lab/LoadingButton';
import {
    FormControl,
    Grid,
    Box,
    InputLabel,
    Typography,
    Stack,
    Modal,
    styled,
    OutlinedInput,
    FormHelperText,
    IconButton, 
    InputAdornment
} from "@mui/material";
import "./styles.scss"
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Fragment } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
};

const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
    [`&.${loadingButtonClasses.loadingIndicator}`]: {
        color: 'white !important',
    },
}));


export default function ConfirmInputModal(props) {
    const [open, setOpen] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClose = () => {
        setShowPassword(false)
        props?.handleCloseModal(false)
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    React.useEffect(() => {
        setOpen(props?.open)
    }, [props?.open])

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Formik
                        initialValues={{
                            password: '',
                        }}
                        validationSchema={Yup.object().shape({
                            password: Yup.string().max(255).min(8, 'Password should be atleast 8 char long.').required('Password is required')
                        })}
                        onSubmit={(values) => {
                            props?.handleDeleteSubmit(values)
                        }}
                    >
                        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                            <Fragment>
                                <Typography id="modal-modal-title" variant="h2" component="h2" sx={{ textAlign: 'center', }}>
                                    {props?.mainTitle}
                                </Typography>
                                <Typography id="modal-modal-description" sx={{ mt: 2.5, textAlign: 'center', color: 'black' }}>
                                    {props?.description}
                                </Typography>
                                <Box sx={{ mt: "1.2rem", mb: "1.7rem", width: "100%", display: 'flex', justifyContent: 'center' }}>
                                    <FormControl variant="outlined">
                                        <InputLabel htmlFor="outlined-adornment-password">
                                            Password
                                        </InputLabel>
                                        <OutlinedInput
                                            placeholder="Enter your password"
                                            value={values.password}
                                            type={showPassword ? 'text' : 'password'}
                                            name="password"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            sx={{ width: 300, borderRadius: 1 }}
                                            fullWidth
                                            label="Password"
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                        size="large"
                                                    >
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                        {touched.password && errors.password && (
                                            <FormHelperText error id="standard-weight-helper-text-password-login">
                                                {errors.password}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Box>
                                <Stack direction="row" spacing={2} justifyContent="center" sx={{ mt: 2.5 }}>
                                    {props?.buttons && props?.buttons?.map((btn) => (
                                        <StyledLoadingButton loading={btn?.loading} style={btn?.style} onClick={() =>{setShowPassword(false); btn?.handleClick ? btn?.handleClick() : handleSubmit()}} >{btn?.loading ? '' : btn?.title}</StyledLoadingButton >
                                    ))}
                                </Stack>
                            </Fragment>
                        )}

                    </Formik>
                </Box>

            </Modal>
        </div>
    );
}