import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, useMediaQuery } from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BrowserView, MobileView } from 'react-device-detect';
import { connect } from 'react-redux';
// project imports
import MenuList from './MenuList';
import LogoSection from '../LogoSection';
import MenuCard from './MenuCard';
import { drawerWidth } from 'store/constant';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import { resetChildData } from 'store/actions';
import BasicModal from 'ui-component/Modal/confirmModal';
import { useState } from 'react';

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = ({ drawerOpen, drawerToggle, window, resetChildData }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const matchUpMd = useMediaQuery('(min-width:915px)');
    
    const [openModal, closeModal] = useState(false)
    const handleLogout = async () => {
        localStorage.clear()
        resetChildData()
        navigate('/');
    };

    const drawer = (
        <>
            <Box sx={{ display: { xs: 'block', msFlexDirection: 'block', ls: 'none' } }}>
                <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
                    <LogoSection />
                </Box>
            </Box>
            <BrowserView className='mbleView'>
                <PerfectScrollbar
                    component="div"
                    style={{
                        height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 200px)',
                        paddingLeft: '16px',
                        paddingRight: '16px',
                        marginTop: 7
                    }}
                >
                    <MenuList />

                </PerfectScrollbar>
                <Box className='logoutContainer' onClick={() => closeModal(true)} sx={{ display: 'flex', p: 5.5, mx: 'auto', alignItems: 'center' }} >
                    <LogoutIcon onClick={() => closeModal(true)} sx={{ color: '#d3d3d3', cursor: 'pointer' }} />
                    <div  style={{ marginLeft: 10, cursor: 'pointer', }} className='logoutTxt'>Log Out</div>
                </Box>
            </BrowserView>
            <MobileView>
                <Box sx={{ px: 2 }} >
                    <MenuList />

                </Box>
            </MobileView>
        </>
    );

    const container = window !== undefined ? () => window.document.body : undefined;


    return (
        <Box component="nav" sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }} aria-label="mailbox folders">
            <BasicModal open={openModal} handleCloseModal={() => closeModal(false)} mainTitle={'Log Out'} description={`Are you sure you want to log out?`} buttons={[{ title: 'Yes', style: { color: 'white', backgroundColor: 'red' }, handleClick: handleLogout }, { title: 'Cancel', style: { color: 'green', backgroundColor: 'white' }, handleClick: () => closeModal(false) }]} />
            <Drawer
                container={container}
                variant={matchUpMd ? 'persistent' : 'temporary'}
                anchor="left"
                open={drawerOpen}
                onClose={drawerToggle}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        background: "#0e56a6",
                        color: theme.palette.text.primary,
                        borderRight: 'none',
                        [theme.breakpoints.up('min-width:915px')]: {
                            top: '88px'
                        }
                    }
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                {drawer}
            </Drawer>
        </Box>
    );
};

const mapStateToProps = ({ childrenReducer }) => ({
    childern: childrenReducer.children,
});

const mapActionsToProps = {
    resetChildData
}

Sidebar.propTypes = {
    drawerOpen: PropTypes.bool,
    drawerToggle: PropTypes.func,
    window: PropTypes.object
};

export default connect(mapStateToProps, mapActionsToProps)(Sidebar);
