import React, { useState, useRef, useEffect } from "react";
import "./style.scss";

import Mobile1 from "assets/windows-manual/androidChild.jpg";
import Mobile2 from "assets/windows-manual/androidParent.jpg";
import SamsungParent from "assets/windows-manual/galaxy-parent.jpg";
import SamsungChild from "assets/windows-manual/galaxy-child.jpg";

import { Helmet } from "react-helmet";

export default function Androidmanual() {

  return (
    <div className="android-container container-fluid">
      <Helmet>
        <title>Cyber Safe Family - Android Installation Manual</title>
        <meta name="description" content="Nested component" />
      </Helmet>
      <div>
        <h1 style={{ color: "rgb(14, 86, 166)", textAlign: 'center' }}>CyberSafeFamily Android Devices Setup Guide</h1>
        
        <div>
          <div className="fontStyles">
          <h3 style={{ color: "rgb(14, 86, 166)" }}>Parental App</h3>
            <ol>
              <li>Download the parental app.  </li>
              <br></br>
            
              <li> If your device is a Samsung brand phone, download it from the Samsung Galaxy store. </li>
              <br></br>
              <div>
                <img style={{ width: "30%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={SamsungParent}></img>
              </div>
              <br></br>
              <li> Otherwise, download it from the Google Play Store.</li>
              <br></br>
              <div>
                <img style={{ width: "30%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile2}></img>
              </div>
              <br></br>
              <li>There are two ways to register, with your smartphone or through the web portal - https://portal.cybersafefamily.com/</li>
             
            </ol>
          </div>
        </div>
      
        <div>
          <div className="fontStyles">
          <h3 style={{ color: "rgb(14, 86, 166)" }}>Child App</h3>
            <ol>
              <li>Download the Child app</li>
              <br></br>
            
              <li> If your device is a Samsung brand phone, download it from the Samsung Galaxy store. </li>
              <br></br>
              <div>
                <img style={{ width: "30%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={SamsungChild}></img>
              </div>
              <br></br>
              <li> Otherwise, download it from the Google Play Store.</li>
              <br></br>
              <div>
                <img style={{ width: "30%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Mobile1}></img>
              </div>
              <br></br>
            </ol>
          </div>
        </div>


      </div>

    </div>
  );
}
