import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import Help from 'views/pages/help-manual';
import Windows from 'views/pages/windows-manual';
import Router from 'views/pages/router-manual';
import Linux from 'views/pages/linux-manual';
import MacOs from 'views/pages/mac-os-manual';
import Android from 'views/pages/android-manual';
import Ios from 'views/pages/ios-manual';
import Mobile from 'views/pages/mobile-manual';
import MobileIos from 'views/pages/mobile-manual-ios';
import HelpMac from 'views/pages/help-manual-ios';
import RegistrationManual from 'views/pages/registration-android-manual';
import RegistrationIosManual from 'views/pages/registration-ios-manual';
import RegistrationWindowsManual from 'views/pages/windows-registration-manual';

const ExternalRoutes = {
    children: [
        {
            path: '/view/manual',
            element: <Help />
        },
        {
            path: '/view/manual/mac',
            element: <HelpMac/>
        },
        {
            path: '/view/windows/manual',
            element: <Windows />
        },
        {
            path: '/view/router/manual',
            element: <Router />
        },
        {
            path: '/view/linux/manual',
            element: <Linux />
        },
        {
            path: '/view/mac/manual',
            element: <MacOs />
        },
        {
            path: '/view/android/manual',
            element: <Android />
        },
        {
            path: '/view/ios/manual',
            element: <Ios />
        },
        {
            path: '/view/mobile/manual',
            element: <Mobile />  
        },
        {
            path: '/view/mobile/manual/ios',
            element: <MobileIos />  
        },
        {
            path: '/view/register/android/manual',
            element: <RegistrationManual />  
        },
        {
            path: '/view/register/ios/manual',
            element: <RegistrationIosManual />  
        },
        {
            path: '/view/register/windows/manual',
            element: <RegistrationWindowsManual />  
        }


    ]
};

export default ExternalRoutes;
