import {
    GEO_LOCATION_LOG_COMPLETED,
    GEO_LOCATION_LOG_INPROGRESS,
    GEO_LOCATION_LOG_FAILED,

    LOAD_GEOFENCE,
    LOAD_GEOFENCE_SUCCESS,
    LOAD_GEOFENCE_FAILURE,

    ADD_FENCE,
    ADD_FENCE_SUCCESS,
    ADD_FENCE_FAILED,

    GET_FAMILY_DETAILS,
    GET_FAMILY_DETAILS_FAILED,
    GET_FAMILY_DETAILS_SUCCESS,

    REMOVE_FENCE,
    REMOVE_FENCE_SUCCESS,
    REMOVE_FENCE_FAILED,

    LOC_STATE_RESET_COMPLETED,

    ALL_DEVICE_DYNAMIC_DATA

} from '../types';
import { getCall, postCall, deleteCall, putCall } from './api'
import { database } from '../../firebase'
import moment from 'moment';

export const geoLocationLog = (payload) => (dispatch) => {
    dispatch({ type: GEO_LOCATION_LOG_INPROGRESS });
    getCall(`/location?deviceId=${payload}`, true).then((res) => {
        dispatch({ type: GEO_LOCATION_LOG_COMPLETED, payload: res.data.data }); 
    })
    dispatch({ type: GEO_LOCATION_LOG_FAILED });
};

export const loadGeoFenceListAction = (deviceId) => (dispatch) => {
    dispatch({ type: LOAD_GEOFENCE });
    getCall(`/geofence?${deviceId}`, true).then(res => {
        dispatch({ type: LOAD_GEOFENCE_SUCCESS, payload: res.data.data });
    }).catch(err => {
        dispatch({ type: LOAD_GEOFENCE_FAILURE });
    })
};

export const getFamilyDetails = (payload) => (dispatch) => {
    dispatch({ type: GET_FAMILY_DETAILS });
    getCall('/geofence', true).then((res) => {
        dispatch({ type: GET_FAMILY_DETAILS_SUCCESS, payload: res.data  }); 
    }).catch(err => {
        dispatch({ type: GET_FAMILY_DETAILS_FAILED, payload:err?.response?.data?.message });
    })
};

export const addGeoFenceAction = (payload) => (dispatch) => {
    dispatch({ type: ADD_FENCE });
    postCall('/geofence', payload, true).then((res) => {
        dispatch({ type: ADD_FENCE_SUCCESS, payload: res.data  }); 
    }).catch(err => {
        dispatch({ type: ADD_FENCE_FAILED, payload:err?.response?.data?.message });
    })
};


export const deleteFenceAction = (deviceId) => (dispatch) => {
    dispatch({ type: REMOVE_FENCE });
    deleteCall(`/geofence/${deviceId}`, true).then((del) => {
        dispatch({ type: REMOVE_FENCE_SUCCESS , payload: del?.data}); 
    })
    dispatch({ type: REMOVE_FENCE_FAILED });
  };
    

  export const resetLocationState = () => async (dispatch) => {
    dispatch({ type: LOC_STATE_RESET_COMPLETED });
  };

export const updateGeoFenceAction = (deviceId, payload) => (dispatch) => {
    dispatch({ type: ADD_FENCE });
    putCall(`/geofence/${deviceId}`, payload, true).then((res) => {
        dispatch({ type: ADD_FENCE_SUCCESS, payload: res.data  }); 
    }).catch(err => {
        dispatch({ type: ADD_FENCE_FAILED, payload:err?.response?.data?.message });
    })
};

export const fbGetAllDeviceLocation = (deviceId,details) => (dispatch) => {
    let ref = database.ref(`/${deviceId}`);
    var m = moment();
  
    var ms = m.milliseconds() + 1000 * (m.seconds() + 60 * (m.minutes() + 60 * m.hours()));
  
    ref.child('getlocation').set(ms.toString())
    ref.on('value', snapshot => {
        console.log('local', snapshot.val().lastlocation)
      dispatch({ type: ALL_DEVICE_DYNAMIC_DATA, payload: [{location:snapshot.val().lastlocation,deviceId:deviceId,details:details}]})
    });
  };
  