import {
    LOAD_CHILDREN,
    LOAD_CHILDREN_SUCCESS,
    LOAD_CHILDREN_FAILURE,
    SET_SELECTED_CHILD,
    SET_SELECTED_DEVICE,
    ADD_CHILD,
    ADD_CHILD_SUCCESS,
    ADD_CHILD_FAILED,
    UPDATE_CHILD,
    UPDATE_CHILD_SUCCESS,
    UPDATE_CHILD_FAILED,

    DELETE_CHILD,
    DELETE_CHILD_SUCCESS,
    DELETE_CHILD_FAILED,

    CLEAR_CHILD,
    CLEAR_CHILD_SUCCESS,
    CLEAR_CHILD_FAILED,

    ADD_CHILD_IMAGE,
    ADD_CHILD_IMAGE_SUCCESS,
    ADD_CHILD_IMAGE_FAILED,
    ADD_PARENT_IMAGE,
    ADD_PARENT_IMAGE_SUCCESS,
    ADD_PARENT_IMAGE_FAILED,

    CHILDREN_STATE_RESET_COMPLETED,
    CHILDREN_DATA_RESET,

    LOAD_PLAN,
    LOAD_PLAN_SUCCESS,
    LOAD_PLAN_FAILURE,

    REAUTH_PSW,
    REAUTH_PSW_SUCCESS,
    REAUTH_PSW_FAILURE,

} from '../types';

export const initialState = {
    loading: false,
    addChildLoading: false,
    children: [],
    selectedChild: null,
    selectedDevice: null,
    updateChildLoading: false,
    userData: localStorage.getItem('user'),
    childAdded: false,
    isDeleted: false,
    isChildUpdate: false,
    isClear: false,
    imageAdded: false,
    addedChildID: '',
    errMessage: '',
    planDetails: [],
    reAuthUpdate: false
};

// ==============================|| CHILDREN REDUCER ||============================== //

const objectsEqual = (o1, o2) =>
    typeof o1 === 'object' && Object.keys(o1).length > 0
        ? Object.keys(o1).length === Object.keys(o2).length
        && Object.keys(o1).every(p => objectsEqual(o1[p], o2[p]))
        : o1 === o2;



const customizationReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_CHILDREN:
            return {
                ...state,
                loading: true
            };
        case LOAD_CHILDREN_SUCCESS:
            let filtered = action.payload.filter((e) => e.childId === state.selectedChild?.childId);

            return {
                ...state,
                children: action.payload,
                selectedChild: state.selectedChild ? !filtered?.length ? action.payload[0] : objectsEqual(state.selectedChild, filtered) ? state.selectedChild : filtered[0] : action.payload[0],
                selectedDevice: state.selectedDevice ? state.selectedDevice : action.payload[0].devices.length ? action.payload[0].devices[0] : null,
                loading: false
            };
        case LOAD_CHILDREN_FAILURE:

            return {
                ...state,
                loading: false,
                children: [],
                selectedChild: null,
                selectedDevice: []
            };

        case SET_SELECTED_CHILD:
            return {
                ...state,
                selectedChild: action.payload,
                selectedDevice: action.payload.devices.length ? action.payload.devices[0] : null,
            };
        case SET_SELECTED_DEVICE:
            return {
                ...state,
                selectedDevice: action.payload
            };

        case ADD_CHILD:
            return {
                ...state,
                addChildLoading: true
            };
        case ADD_CHILD_SUCCESS:
            return {
                ...state,
                addChildLoading: false,
                childAdded: true,
                addedChildID: action.payload
            };
        case ADD_CHILD_FAILED:
            return {
                ...state,
                addChildLoading: false,
                errMessage: action.payload
            };

        case UPDATE_CHILD:
            return {
                ...state,
                updateChildLoading: true
            };
        case UPDATE_CHILD_SUCCESS:
            return {
                ...state,
                isChildUpdate: true,
                // selectedChild: action.payload,
                updateChildLoading: false,

            };
        case UPDATE_CHILD_FAILED:
            return {
                ...state,
                updateChildLoading: false
            };

        case DELETE_CHILD:
            return { ...state, loading: true };
        case DELETE_CHILD_SUCCESS:
            return { ...state, loading: false, isDeleted: true };
        case DELETE_CHILD_FAILED:
            return { ...state, loading: false, errMessage: action.payload };

        case CLEAR_CHILD:
            return { ...state, loading: true };
        case CLEAR_CHILD_SUCCESS:
            return { ...state, loading: false, isClear: true };
        case CLEAR_CHILD_FAILED:
            return { ...state, loading: false, errMessage: action.payload };

        case ADD_CHILD_IMAGE:
            return { ...state, loading: true };
        case ADD_CHILD_IMAGE_SUCCESS:
            return { ...state, loading: false, imageAdded: true };
        case ADD_CHILD_IMAGE_FAILED:
            return { ...state, loading: false, errMessage: action.payload };

        case ADD_PARENT_IMAGE:
            return { ...state, loading: true };
        case ADD_PARENT_IMAGE_SUCCESS:
            return { ...state, loading: false, imageAdded: true };
        case ADD_PARENT_IMAGE_FAILED:
            return { ...state, loading: false, errMessage: action.payload };

        case CHILDREN_STATE_RESET_COMPLETED:
            return { ...state, childAdded: false, isDeleted: false, isClear: false, isChildUpdate: false, imageAdded: false, addedChildID: '', errMessage: '', reAuthUpdate: false };
        case CHILDREN_DATA_RESET:
            return { ...state, children: [], selectedChild: null, selectedDevice: null };




        case LOAD_PLAN:
            return {
                ...state,
                loading: true
            };
        case LOAD_PLAN_SUCCESS:


            return {
                ...state,
                planDetails: action.payload,
                loading: false
            };
        case LOAD_PLAN_FAILURE:

            return {
                ...state,
                loading: false,
            };


        case REAUTH_PSW:
            return { ...state, loading: true };
        case REAUTH_PSW_SUCCESS:
            return { ...state, loading: false, reAuthUpdate: true };
        case REAUTH_PSW_FAILURE:
            return { ...state, loading: false, errMessage: action.payload };

        default:
            return state;




    }
};

export default customizationReducer;
