import { useFormik } from "formik";
import * as yup from "yup";
import {
  TextField,
  Button,
  FormControl,
  Grid,
  Box,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Typography,
  Stack,
} from "@mui/material";

import FormData from "form-data";
import Avatar from "@mui/material/Avatar";
import EditIcon from "@mui/icons-material/Edit";
import { styled } from "@mui/material/styles";
import { useComponentWillUnmount } from "utils/helpers";
import "./style.scss";
import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";

const validation = yup.object({
 
  password: yup
    .string()
    .required("Password is required")
    .nullable(),
  // age: yup.string().required("Age is required").nullable(),
});

export default function AuthForm(props) {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validation),
  });

  const onSubmit = (data) => {
    console.log("submitdata", data);
    props?.onFormSubmit(data);
    setValues({
      ...values,
      password: "",
    });
  };

  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  return (
    <Box >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ mt: ".8rem", mb: "1rem", width: "100%" }}>
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              {...register("password")}
              id="outlined-adornment-password"
              type={values.showPassword ? "text" : "password"}
              value={values.password}
              onChange={handleChange("password")}
              placeholder = "Enter your password"
              size="small"
              sx={{height : '3rem' }}
              fullWidth
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
                {<div className="field-error">{errors.password?.message}</div>}
          </FormControl>
        </Box>

        <Grid>
          <div className="btn-form">
            <Stack direction="row" spacing={2} justifyContent="flex-end">
              {
                <Button
                  variant={"outlined"}
                  size="small"
                  onClick={() => {
                    setValues({
                      ...values,
                      password: "",
                    });
                    props.handleClose();
                  }}
                  type={"button"}
                  sx={{ borderColor: "#0e56a6", color: "#0e56a6" }}
                  color={"secondary"}
                >
                  Cancel
                </Button>
              }

              <Button
                size="small"
                variant={"contained"}
                sx={{
                  color: "white",
                  backgroundColor: "#0e56a6",
                }}
                type={"submit"}
                color={"primary"}
              >
                Update
              </Button>
            </Stack>
          </div>
        </Grid>
      </form>
    </Box>
  );
}
