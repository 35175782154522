
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  TextField, Button,
  FormControl, Grid, Box, InputLabel, Select,
  MenuItem, FormHelperText, Typography, Stack
} from '@mui/material';

import FormData from 'form-data'
import Avatar from '@mui/material/Avatar';
import EditIcon from '@mui/icons-material/Edit';
import { styled } from '@mui/material/styles';
import { useComponentWillUnmount } from "utils/helpers"
import './style.scss';
import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from 'react-router-dom';



const validation = yup.object({
  deviceType: yup.object().required("Device Type is required").nullable(),
  firstName: yup.string().required("First Name is required").nullable().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
  // age: yup.string().required("Age is required").nullable(),
});



export default function ChildForm(props) {



  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      validation
    ),
  });

  const navigate = useNavigate();

  const status = [
    { label: "Android", value: "Android" },
    { label: "iOS", value: "iOS" },
    { label: "Tablet", value: "Tablet" },
    { label: "iPad", value: "iPad" },
    { label: "Windows Desktop", value: "Windows Desktop" },
    { label: "macOS", value: "macOS" },
  ];

  const proType = [
    { label: "Android", value: "Android" },
    { label: "iOS", value: "iOS" },
    { label: "Tablet", value: "Tablet" },
    { label: "iPad", value: "iPad" }
  ];

  const Input = styled("input")({
    display: "none",
  });

  useComponentWillUnmount(() => {
    console.log('unmount')
    setSelectedImage(null)
    setValue('deviceType', []);
  });


  useEffect(() => {
    if (props) {
      setValue("firstName", props?.childData?.firstName);
      setValue("age", props?.childData?.age)
      setValue("idss", props?.childData?.childId)

    }
  }, [props?.childData]);

  useEffect(() => {
    if (props?.childData?.deviceType) {
      filterObject("deviceType", status, props?.childData?.deviceType ? props?.childData?.deviceType : "");
    }
  }, [props?.childData?.deviceType]);

  const handleImgChange = async (event) => {

    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = async () => {
      const image = event.target.files[0]
      props?.handleAddImageData(image)

    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    if (props?.childData?.profileUrl) {
      setSelectedImage(props?.childData?.profileUrl)
    }

  }, [props?.childData?.profileUrl])


  const onSubmit = (data) => {
    props?.onFormSubmit(data);
    setSelectedImage(null)
    reset({
      deviceType: '',
      firstName: '',
      age: ''
    });
  }

  const filterObject = (title, options, value) => {
    if (value === "" || value === undefined) return;
    let filtered =
      options &&
      options.filter(
        (options) => options.value.toString() === value.toString()
      );
    setValue(title, filtered[0]);
  }

  const onChange = (e) => {
    console.log(e.target.files);
    setSelectedImage(URL.createObjectURL(e.target.files[0]));
    handleImgChange(e)
  };

  return (
    <Box sx={{ minWidth: '300px' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ mt: '.8rem', mb: '1rem' }}>
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
            {/* <ChildImageUpload imageUrl={props?.childData?.profileUrl ? props?.childData?.profileUrl : ""} handleImgChange={handleImgChange} /> */}

            <Box sx={{ minWidth: '300px' }} className="avatar-image-upload">
              <div style={{ position: 'relative' }}>
                <Avatar alt="C" src={selectedImage} sx={{ height: 130, width: 130 }} />
                <label htmlFor="contained-button-file" className='child-addImage-btn'>
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={onChange}

                  />
                  <EditIcon fontSize="small" sx={{ color: 'white' }} />
                </label>
              </div>
            </Box>
          </div>
        </Box>

        <Box sx={{ mt: '.8rem', mb: '1rem', width: "100%" }}>
          <TextField

            {...register("firstName")}
            id="firstName"
            name="firstName"
            disabled={props?.deviceEdit ? true : false}
            placeholder="First Name / Nick Name"
            variant="outlined"
            size="small"
            fullWidth
            type="text"
          />
          {<div className="field-error">{errors.firstName?.message}</div>}
        </Box>

        <Grid sx={{ mt: '.8rem', mb: '1rem', width: "100%" }}>
          <TextField

            {...register("age")}
            id="age"
            name='age'
            disabled={props?.deviceEdit ? true : false}
            placeholder="Age"
            inputProps={{ min: 1 }}
            min={1}
            variant="outlined"
            size="small"
            fullWidth
            type="number"

          />
          {<div className="field-error">{errors.age?.message}</div>}
        </Grid>

        <Grid sx={{ mt: '.8rem', mb: '1rem', width: "100%", display: 'none' }}>
          <TextField size="small" {...register("idss")} variant="outlined" id="outlined-basic" placeholder="Ticket Title" sx={{ width: "100%" }} />
          {<div className="field-error">{errors.idss?.message}</div>}
        </Grid>

        <Grid >
          <Controller
            control={control}
            name="deviceType"
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, item) => {
                  onChange(item);
                }}
                disabled={props?.childData?.childId ? props?.deviceEdit ? false : true : false}
                value={value || ""}
                options={props?.planDetails?.includes('Parents') ? proType : status}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                getOptionLabel={(item) =>
                  item.label ? `${item.label}` : ""
                }

                renderInput={(params) => (
                  <TextField {...params} size="small" placeholder="Select Device Type" />
                )}
              />
            )}
          />
          {<div className="field-error">{errors.deviceType?.message}</div>}
        </Grid>
        <Grid >

          <div className="btn-form" >


            <Stack direction="row" spacing={2} justifyContent="flex-end">
              {
                props?.childData?.childId ? (
                  <Button variant={'outlined'} size="small" onClick={() => {

                    props.handleClose()

                  }} type={'button'} sx={{ borderColor: '#0e56a6', color: '#0e56a6' }} color={'secondary'}>Cancel</Button>
                ) : (
                  <Button variant={'outlined'} size="small" onClick={() => {
                    reset({
                      deviceType: '',
                      firstName: '',
                      age: ''
                    });
                    setSelectedImage(null)
                    props.handleClose()

                  }} type={'button'} sx={{ borderColor: '#0e56a6', color: '#0e56a6' }} color={'secondary'}>Cancel</Button>
                )
              }
              {
                props?.planDetails?.includes('Parents') && props?.totalDevices < 5 || props?.planDetails?.includes('Premium') && props?.totalDevices < 10 ? (
                  <Button size="small" variant={'contained'} sx={{
                    color: 'white', backgroundColor
                      : '#0e56a6'
                  }} type={'submit'} color={'primary'}>{props?.childData?.childId ? props?.deviceEdit ? "Add Device" : "Update" : "Add Device"}</Button>

                ) : <Button size="small" variant={'contained'} onClick={(event) => {
                  event.stopPropagation();
                  navigate('/user/upgrade-plan');
                }} sx={{
                  color: 'white', backgroundColor
                    : '#0e56a6'
                }} color={'primary'}>{props?.childData?.childId ? props?.deviceEdit ? "Add Device" : "Update" : "Add Device"}</Button>

              }


            </Stack>
          </div>
          <div className="padding-title-addticket"></div>
          <div className="padding-title-addticket"></div>
        </Grid>
      </form>
    </Box>
  );
}
