import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import ProfRoutes from './ProfileRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import ExternalRoutes from './ExternalRoutes';
import config from 'config';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([MainRoutes, AuthenticationRoutes, ExternalRoutes, ProfRoutes ], config.basename);
}
