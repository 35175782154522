import {
    GET_WEB_LOGS,
    GET_WEB_LOGS_SUCCESS,
    GET_WEB_LOGS_FAILED,

    GET_CALL_LOGS,
    GET_CALL_LOGS_SUCCESS,
    GET_CALL_LOGS_FAILED,

    GET_BLOCKED_WEBSITES,
    GET_BLOCKED_WEBSITES_SUCCESS,
    GET_BLOCKED_WEBSITES_FAILED,

    GET_APPS,
    GET_APPS_SUCCESS,
    GET_APPS_FAILED,

    POST_BLOCK_WEBSITES,
    POST_BLOCK_WEBSITES_SUCCESS,
    POST_BLOCK_WEBSITES_FAILED,

    POST_BLOCK_APPS,
    POST_BLOCK_APPS_SUCCESS,
    POST_BLOCK_APPS_FAILED,

    FILTER_STATE_RESET_COMPLETED,

    FB_WEB_BLOCK,

    POST_WEBSITE_TO_BLOCK,
    POST_WEBSITE_TO_BLOCK_SUCCESS,
    POST_WEBSITE_TO_BLOCK_FAILED,

    CLEAR_APP_FILTER,
    CLEAR_APP_FILTER_SUCCESS,
    CLEAR_APP_FILTER_FAILED,

} from '../types';

export const initialState = {
    loading: false,
    apploading: false,
    getLoading: false,
    clearloading : false,
    webLogs: [],
    callLogs: [],
    blockedSites: [],
    allApps: [],
    sucessMessage: '',
    webBlockDynamicInfo: '',
    websiteAdded: '',
    isClearApp: false,
    appsuccess: false,
};


const filterReducer = (state = initialState, action) => {

    switch (action.type) {
        case GET_WEB_LOGS:
            return {
                ...state,
                loading: true
            };
        case GET_WEB_LOGS_SUCCESS:
            console.log(action.payload?.data, "payloadData")
            return {
                ...state,
                loading: false,
                webLogs: action.payload?.data
            };
        case GET_WEB_LOGS_FAILED:
            return {
                ...state,
                loading: false,
                webLogs: []
            };

        case GET_CALL_LOGS:
            return {
                ...state,
                loading: true
            };
        case GET_CALL_LOGS_SUCCESS:
            return {
                ...state,
                loading: false,
                callLogs: action.payload
            };
        case GET_CALL_LOGS_FAILED:
            return {
                ...state,
                loading: false
            };
        case GET_BLOCKED_WEBSITES:
            return {
                ...state,
                loading: true
            };
        case GET_BLOCKED_WEBSITES_SUCCESS:
            return {
                ...state,
                loading: false,
                blockedSites: action.payload?.data
            };
        case GET_BLOCKED_WEBSITES_FAILED:
            return {
                ...state,
                loading: false
            };

        case GET_APPS:
            return {
                ...state,
                loading: true,
                getLoading: true,
                appsuccess: false,
            };
        case GET_APPS_SUCCESS:
            return {
                ...state,
                loading: false,
                appsuccess: true,
                getLoading : false,
                allApps: action.payload?.data
            };
        case GET_APPS_FAILED:
            return {
                ...state,
                loading: false,
                getLoading : false,
                appsuccess: false,
            };
        case POST_BLOCK_WEBSITES:
            return {
                ...state,
                loading: true
            };
        case POST_BLOCK_WEBSITES_SUCCESS:
            return {
                ...state,
                loading: false,
                sucessMessage: action.payload?.message
            };
        case POST_BLOCK_WEBSITES_FAILED:
            return {
                ...state,
                loading: false
            };
        case POST_BLOCK_APPS:
            return {
                ...state,
                loading: true,
                apploading : true,
                getLoading: true,
                appsuccess: false,
            };
        case POST_BLOCK_APPS_SUCCESS:
            return {
                ...state,
                loading: false,
                sucessMessage: action.payload?.message,
                apploading : false,
                getLoading : false,
                appsuccess: false,
            };
        case POST_BLOCK_APPS_FAILED:
            return {
                ...state,
                loading: false,
                apploading : false,
                getLoading : false,
            };
        case FB_WEB_BLOCK:
            return {
                ...state,
                webBlockDynamicInfo: action.payload
            };

        case POST_WEBSITE_TO_BLOCK:
            return {
                ...state,
                loading: true
            };
        case POST_WEBSITE_TO_BLOCK_SUCCESS:
            return {
                ...state,
                loading: false,
                websiteAdded: action.payload?.message
            };
        case POST_WEBSITE_TO_BLOCK_FAILED:
            return {
                ...state,
                loading: false
            };

        case CLEAR_APP_FILTER:
            return { ...state, clearloading: true };
        case CLEAR_APP_FILTER_SUCCESS:
            return { ...state, clearloading: false, isClearApp: true };
        case CLEAR_APP_FILTER_FAILED:
            return { ...state, clearloading: false, errMessage: action.payload };

        case FILTER_STATE_RESET_COMPLETED:
            return { ...state, sucessMessage: '', websiteAdded: '',isClearApp:false };

        default:
            return state;
    }
};

export default filterReducer;
