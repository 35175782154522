import React, { useState, useRef, useEffect } from "react";
import "./style.scss";
import { Helmet } from "react-helmet";
import Agent1 from "assets/windows-manual/agent1.png";
import Agent2 from "assets/windows-manual/agent2.png";
import Agent3 from "assets/windows-manual/agent3.png";
import Agent4 from "assets/windows-manual/agent4.png";

export default function Windowmanual() {

  return (
    <div className="windows-container container-fluid">
      <Helmet>
        <title>Cyber Safe Family - Windows Installation Manual</title>
        <meta name="description" content="Nested component" />
      </Helmet>
      <div>
        <h1 style={{ color: "rgb(14, 86, 166)", textAlign: 'center' }}>Cyber Safe Family Windows Setup Guide</h1>
       

        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Windows Agent Installation</h2>

          <div className="fontStyles">
            <ol>
              <li>Log into your filter.cybersafefamily.com account using your email/login and password.
              </li>
             
              <br></br>
            
              <li>Make sure your current Windows user account has Administrator rights. <a  href="https://storage.googleapis.com/parental-control-325012.appspot.com/en-generic-agent-3.4.1.0-setup.exe">https://storage.googleapis.com/parental-control-325012.appspot.com/en-generic-agent-3.4.1.0-setup.exe</a> Run the <b>.exe</b> file and install the Agent on your computer following the program prompts.
                <br></br>  If you see a Windows SmartScreen alert, click <b>More options - Run anyway</b> to start the installation.</li>
              <br></br>
              <div>
                <img style={{ width: "499", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Agent1}></img>
              </div>
              <br></br>
              <div>
                <img style={{ width: "499", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Agent2}></img>
              </div>
              <br></br>
              <div>
                <img style={{ width: "499", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Agent3}></img>
              </div>
              <br></br>
              <div>
                <img style={{ width: "499", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Agent4}></img>
              </div>

            </ol>
            After the successful installation, the Agent will be launched automatically.
          </div>
        </div>
       

      </div>

    </div>
  );
}
