import React, { useState, useRef, useEffect } from "react";
import "./style.scss";
import M1 from "assets/windows-manual/m1.png";
import M2 from "assets/windows-manual/m2.png";
import M3 from "assets/windows-manual/m3.png";
import M4 from "assets/windows-manual/m4.png";
import M5 from "assets/windows-manual/m5.png";
import M6 from "assets/windows-manual/m6.png";
import M7 from "assets/windows-manual/M7.png";
import M8 from "assets/windows-manual/m8.png";
import M9 from "assets/windows-manual/m9.png";
import M10 from "assets/windows-manual/m10.png";
import M11 from "assets/windows-manual/m11.png";
import M12 from "assets/windows-manual/m12.png";
import M13 from "assets/windows-manual/m13.png";
import M14 from "assets/windows-manual/m14.png";
import M15 from "assets/windows-manual/m15.png";
import M16 from "assets/windows-manual/m16.png";
import { Helmet } from "react-helmet";

export default function Macmanual() {

  return (
    <div className="mac-container container-fluid">
            <Helmet>
        <title>Cyber Safe Family -macOs Instalation Manual</title>
        <meta name="description" content="Nested component" />
      </Helmet>
      <div>
        <h1 style={{ color: "rgb(14, 86, 166)", textAlign: 'center' }}>Cyber Safe Family macOS Setup Guide</h1>
        <div>
          <br></br>
          <p className="fontStyles">
            Installation requirements: macOS 12 (Monterey) or higher.
          </p>


        </div>
        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Getting Started</h2>

          <div className="fontStyles">

            <ol>
              <li>Log in to your SafeDNS account with your registration email and password.</li>
              <li>Navigate to the <b>Getting Started</b> widget on the main page of the<b> Dashboard</b>  and select the macOS button. </li>
              <li>Click on the download link below and wait for the Agent to download.</li>
              <br></br>
              Alternatively, you can use the direct link to download the Agent from the SafeDNS website - SafeDNS macOS Agent.
              <br></br>
              <div>
                <img style={{ width: "80%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={M1}></img>
              </div>
              <br></br>

            </ol>
          </div>
        </div>

        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Installation</h2>
          <div className="fontStyles">

            <ol>
              <li>After downloading, open the Downloads directory and double-click on the downloaded package to start the installation process.</li>

              <div>
                <img style={{ width: "80%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={M2}></img>
              </div>
              <br></br>
              <li>If you see a message before installing that says “macOS can’t verify the developer of AgentSafeDNS.pkg…”, please hold the Control button, right-click on AgentSafeDNS.pkg, and click Open in the opened context menu.</li>
              <li>In the appeared dialogue, click Open and follow the instructions of the installer. During the installation process you will be asked to enter the administrator password.</li>
              <br></br>
              <div>
                <img style={{ width: "80%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={M3}></img>
              </div>
            </ol>
          </div>
        </div>
        <br></br>




        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>First launch</h2>
          <div className="fontStyles">

            <ol>
              <li>
                After installation, launch the SafeDNS Agent from the Launchpad.
                The Agent will be accessible by clicking on the icon in the system tray after the launch.
              </li>
              <br></br>
              <div>
                <img style={{ width: "80%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={M4}></img>
              </div>
              <br></br>
              <li>Enter your account credentials in the opened window. If you don’t have an account yet, please choose a suitable plan and register using the registration page.</li>
              <br></br>
              <div>
                <img style={{ width: "80%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={M5}></img>
              </div>
              <br></br>



              <li>Set up the security PIN that will be used later to restrict access to the Agent.</li>
              <br></br>
              <div>
                <img style={{ width: "80%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={M6}></img>
              </div>

              <br></br>
              <li>Enter the PIN once again to sign in to the Agent:</li>
              <br></br>
              <div>
                <img style={{ width: "80%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={M7}></img>
              </div>
            </ol>
          </div>
        </div>

        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Agent overview</h2>
          <div className="fontStyles">
            <ol><li>In the main window of the Agent you can view your account information, current IP address, your Billing Plan, and the Subscription expiration date.
              All filtering rules changes can be made in the SafeDNS Dashboard.</li>
            <p>Use the <b>Policy</b> menu to view and change the current filtering Policy.</p>
            <br></br>
            <div>
              <img style={{ width: "100%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={M8}></img>
            </div>
            <br></br>
            <li>The <b>system information</b> menu shows brief information about the Agent, current filtering policies, and network interfaces. The information in this menu can be copied to the clipboard by clicking the "Copy to clipboard" button.</li>
           
            <br></br>
            <div>
              <img style={{ width: "100%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={M9}></img>
            </div>
            <br></br>
            <li>The <b>Debug</b>  menu displays the results of the diagnostic commands that are required in case of troubleshooting. To send the debug information, click the "Send report" button.</li>
            <br></br>
            <div>
              <img style={{ width: "100%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={M10}></img>
            </div>
            </ol>
          </div>
        </div>

        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Additional settings</h2>
          <div className="fontStyles">
            <p>To make sure the Agent was installed correctly, please navigate to the "Settings" tab in the Dashboard and scroll to the bottom.</p>
            <p>If you see the record with the Device name and your IP address, it means that the filtering is working.</p>
            <br></br>
            <div>
              <img style={{ width: "80%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={M11}></img>
            </div>
            <br></br>
            After that, you can adjust the filtering Policy according to your needs. You can select categories to block in the Dashboard under the Categories tab.
            <br></br>
          
            <div>
              <img style={{ width: "80%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={M12}></img>
            </div>
            <br></br>
            <p>Don't forget to click the "Save changes" button.</p>
            <br></br>
            <p>The setup is complete!</p>
            <p>A blocked website will display an error message that the Access is closed.</p>
            <br></br>
            <div>
              <img style={{ width: "80%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={M13}></img>
            </div>
            <br></br>
            <p>If the filtering doesn't work according to your policy settings, clear the cache of your browser using this guide.</p> <br></br>
            <b>!Please note, that settings take 5-7 minutes to apply.</b> <br></br>
            <b>!Stats and filtering status update hourly.</b>
          </div>
        </div>

        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Uninstallation</h2>
          <div className="fontStyles">

            <p>To uninstall the Agent please quit it first.</p>
            <div>
              <img style={{ width: "80%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={M14}></img>
            </div>
            <p>Then find Agent in the Applications folder:</p>
            <div>
              <img style={{ width: "80%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={M15}></img>
            </div>
            <p>Open the folder AgentSafeDNS and run the packet "Uninstall-AgentSafeDNS".</p>
            <p>After that, enter the administrator password in the Terminal window, and the Agent will be deleted.</p>
            <div>
              <img style={{ width: "80%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={M16}></img>
            </div>
        
          </div>
        </div>

      </div>

    </div>
  );
}
