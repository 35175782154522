import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import childrenReducer from './childrenReducer';
import deviceReducer from './deviceReducer';
import authReducer from "./authReducer"
import locationReducer from "./locationReducer"
import filterReducer from "./filterReducer"
import generalReducer from "./generalReducer"
import schedularReducer from './schedularReducer';
import appusageReducer from './appusageReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    auth:authReducer,
    childrenReducer,
    deviceReducer,
    locationReducer,
    filterReducer,
    generalReducer,
    schedularReducer,
    appusageReducer
});

const rootReducer = (state, action) => {
    if (action.type === 'CHILDREN_DATA_RESET') {
      state = undefined;
    }
    return reducer(state, action);
  };

export default rootReducer;
