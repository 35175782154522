import React, { useState, useRef, useEffect } from "react";
import { Document, Page } from "react-pdf/dist/umd/entry.webpack";
import { useParams } from "react-router-dom";
import "./style.scss";
import Addchildbtn from "assets/manual/addchildbtn.png";
import ChildPopup from "assets/manual/childpopup.jpg";
import LogCode from "assets/manual/logcode.jpg";
import AddDevice from "assets/manual/adddevice.png";
import ChildEdit from "assets/manual/childedit.png";
import EditPopup from "assets/manual/editpop.jpg";
import Trash from "assets/manual/childdelete.png";
import ClearChild from "assets/manual/clrchild.png";
import Confirm from "assets/manual/confirm.jpg";
import Delete from "assets/manual/devicedelete.png";
import DeviceConfirm from "assets/manual/deviceconfirm.jpg";
import ClearAll from "assets/manual/clearall.jpg";
import TimeSchedule from "assets/manual/timeschedule.jpg";
import TimescheduleApp from "assets/manual/timeapp.jpg";
import TimeScheduleBlock from "assets/manual/timescheduleblock.jpg";
import Time from "assets/manual/time.jpg";
import BlockTime from "assets/manual/blocktme.jpg";
import Schedule from "assets/manual/shedule.jpg";
import UpdateSchedule from "assets/manual/updatesched.jpg";
import Delpopup from "assets/manual/delpopup.jpg";
import Updatesch from "assets/manual/updatesch.jpg";
import Wall from "assets/manual/wall.jpg";
import TimeSch from "assets/manual/timesch.png";
import ClearTime from "assets/manual/cleartime.png";
import EditTime from "assets/manual/edittime.png";
import UpdateTime from "assets/manual/updateTime.png";
import UpdateWeek from "assets/manual/updateWeek.png";
import DeleteSch from "assets/manual/deletesch.png";
import DeleteSchedule from "assets/manual/deleteschedule.png";
import Location from "assets/manual/location.png";
import LocationDrop from "assets/manual/locationDrop.png";
import GeoLocation from "assets/manual/geoLocation.png";
import CurrentLoc from "assets/manual/currentloc.jpg";
import History from "assets/manual/history.png";
import Geo from "assets/manual/geoFence.png";
import Radius from "assets/manual/radius.jpg";
import Fences from "assets/manual/fence.jpg";
import EditFence from "assets/manual/editfence.png";
import Geoupdate from "assets/manual/geoUpdate.png";
import Afterupdate from "assets/manual/afterupdate.png";
import FenceDelete from "assets/manual/fencedelete.png";
import PlainFence from "assets/manual/plainfence.jpg";
import DriveSafe from "assets/manual/drivesafe.png";
import SetLimit from "assets/manual/setlimit.jpg";
import ChildMap from "assets/manual/childmap.png";
import ChildLocation from "assets/manual/childloc.jpg";
import AppFilter from "assets/manual/appfilter.png";
import AppBlock from "assets/manual/appblock.jpg";
import BlockConfirm from "assets/manual/blockconfirm.jpg";
import WebFilter from "assets/manual/webfilter.png";
import WebUrl from "assets/manual/weburl.jpg";
import WebConfirm from "assets/manual/webconfirm.jpg";
import AppUsage from "assets/manual/appusage.png";
import PieChart from "assets/manual/piechart.jpg";
import Call from "assets/manual/callandsms.png";
import CallAlert from "assets/manual/callalert.png";
import CallList from "assets/manual/calllist.png";
import Register from "assets/manual/register.png";
import RegisterPage from "assets/manual/registerpage.png";
import Verification from "assets/manual/verification.png";
import Login from "assets/manual/login.png";
import ForgotPsw from "assets/manual/forgotpsw.png";
import Forgot from "assets/manual/forgot.png";
import VerifyPsw from "assets/manual/verifypsw.png";
import UpdatePsw from "assets/manual/updatepsw.png";
import Settings from "assets/manual/settings.png";
import EditProfile from "assets/manual/editprofile.png";
import ProfileEdit from "assets/manual/profileedit.png";
import EditVerify from "assets/manual/editverify.png";
import ChangePsw from "assets/manual/changepsw.png";
import ChangePswForm from "assets/manual/changepswform.png";
import Contact from "assets/manual/contactus.png";
import ContactForm from "assets/manual/contactusform.png";
import Help from "assets/manual/help.png";
import HelpDesk from "assets/manual/helpdesk.png";
import Notification from "assets/manual/notification.png";
import NotificationClear from "assets/manual/clearnotify.png";
import Logout from "assets/manual/logout.png";
import LogoutAlert from "assets/manual/logoutalert.png";
import Terms from "assets/manual/terms.png";
import { Helmet } from "react-helmet";

export default function Manual() {

  return (
    <div className="manual-container container-fluid">
      <Helmet>
        <title>Cyber Safe Family - Manual</title>
        <meta name="description" content="Nested component" />
      </Helmet>
      <div>
        <h1 style={{ color: "rgb(14, 86, 166)", textAlign: 'center' }}>Cyber Safe Family Setup Guide</h1>
        <div>
          <br></br>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Register</h2>
          <ul className="fontStyles">
            <li>
              The user will be  able to register themself as a parent using registration by entering their Name, Phone number, Mail id and also a password to login inside the account once created.
            </li>
            <li>
              After Successful verification of OTP from both Mobile and Mail we will be able to login as a parent with the registered credentials.
            </li>
          </ul>
        </div>
        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to register</h2>

          <div className="fontStyles">
            <ol>
              <li>Click Register from the landing page.</li>
              <div>
                <img style={{ width: "100%" }} src={Register}></img>
              </div>

              <li>
                You will be redirected to the registration form page.
              </li>
              <div>
                <img style={{ width: "100%" }} src={RegisterPage}></img>
              </div>
              <li>Once you give the details and click sign up you will be redirected to the OTP verification page.</li>
              <div>
                <img style={{ width: "100%" }} src={Verification}></img>
              </div>
              <li>And when you enter the correct OTP you will be redirected back to the login page.</li>

              <div>
                <img style={{ width: "100%" }} src={Login}></img>
              </div>
            </ol>
          </div>
        </div>
        <div>
          <br></br>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Login</h2>
          <ul className="fontStyles">
            <li>
              If the User is an registered parent, then they can  use their registered credentials to login inside the application.
            </li>
          </ul>
        </div>
        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to Login</h2>

          <div className="fontStyles">
            <ol>
              <li>On entering correct registered credentials, you will be successfully logged in inside the application.</li>
              <div>
                <img style={{ width: "100%" }} src={Login}></img>
              </div>
            </ol>
          </div>
        </div>
        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to Login for non OTP verified accounts</h2>

          <div className="fontStyles">
            <ol>
              <li>Enter the credentials which you have used for registration without verifying the OTP in Login.</li>
              <div>
                <img style={{ width: "100%" }} src={Login}></img>
              </div>
              <li>Once you click login you will be redirected to the OTP screen for verification.</li>
              <div>
                <img style={{ width: "100%" }} src={Verification}></img>
              </div>
              <li>And when you enter the correct OTP you will be redirected back to the login page.</li>
              <li>In the login page you can enter the registered login credentials and login inside the application.</li>
              <div>
                <img style={{ width: "100%" }} src={Login}></img>
              </div>
            </ol>
          </div>
        </div>
        <div>
          <br></br>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Forgot Password</h2>
          <ul className="fontStyles">
            <li>
              If the user forgot his login password this option is the only way to change the password and Login into the account.
            </li>
          </ul>
        </div>
        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps for Forgot Password</h2>

          <div className="fontStyles">
            <ol>
              <li>Click on Forgot password in the login Screen.</li>
              <div>
                <img style={{ width: "100%" }} src={ForgotPsw}></img>
              </div>
              <li>In the Forgot Password page enter the registered Mobile number or Email id</li>
              <div>
                <img style={{ width: "100%" }} src={Forgot}></img>
              </div>
              <li>An otp will be send to the entered number or mail id and you will be redirected to the OTP verification page.</li>
              <div>
                <img style={{ width: "100%" }} src={VerifyPsw}></img>
              </div>
              <li>Enter the received OTP.</li>
              <li>You will be redirected to the change password page.</li>
              <div>
                <img style={{ width: "100%" }} src={UpdatePsw}></img>
              </div>
              <li>Once you update the password you will be redirect to Login page</li>
              <li>There enter the user name and the changed password to login inside the application.</li>
              <div>
                <img style={{ width: "100%" }} src={Login}></img>
              </div>
            </ol>
          </div>
        </div>
        <div id="dashboard">
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Add Child</h2>
          <ul className="fontStyles">
            <li>
              We will be able to create multiple childs with their specific names
              using this option.
            </li>
            <li>
              Using which we will be able to identify and manage each child along
              with their devices separately.
            </li>
          </ul>
        </div>
        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to Add Child</h2>

          <div className="fontStyles">
            <ol>
              <li>Click “Add Child”</li>
              <div>
                <img style={{ width: "100%" }} src={Addchildbtn}></img>
              </div>

              <li>
                In the Add Child popup, Add the information about the child.
              </li>
              <li>Enter the age.</li>
              <li>Select the type of device of the Child.</li>

              <div>
                <img style={{ width: "100%" }} src={ChildPopup}></img>
              </div>
              <li>
                If you click continue you will be able to view a popup displaying
                the log code for child device syncing and the child would have
                been added to the application.
              </li>
              <div>
                <img style={{ width: "100%" }} src={LogCode}></img>
              </div>
            </ol>
          </div>
        </div>
        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to Add Device</h2>

          <div className="fontStyles">
            <ol>
              <li>
                The device of the child can be synced with the parent device by
                using the log code from the parent.
              </li>

              <li>
                One way is to use the log code from the popup which is displayed
                when we add child.
              </li>
              <div>
                <img style={{ width: "100%" }} src={LogCode}></img>
              </div>

              <li>
                The other way is to use the Add device button near to the child’s
                name.
              </li>

              <div>
                <img style={{ width: "100%" }} src={AddDevice}></img>
              </div>
              <li>
                If you click continue you will be able to view a popup displaying
                the log code for child device syncing and the child would have
                been added to the application.
              </li>
              <li>On clicking which the log code popup will be displayed.</li>
              <div>
                <img style={{ width: "100%" }} src={LogCode}></img>
              </div>
            </ol>
          </div>
        </div>
        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Edit / Delete Child</h2>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to edit child</h2>

          <div className="fontStyles">
            <ol>
              <li>
                Click on the edit icon next to the child’s name.

              </li>
              <div>
                <img style={{ width: "100%" }} src={ChildEdit}></img>
              </div>

              <li>
                In the Edit Child screen you will be able to edit the  details of the child and update it.
              </li>

              <div>
                <img style={{ width: "100%" }} src={EditPopup}></img>
              </div>
            </ol>
          </div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to Delete child</h2>
          <div className="fontStyles">
            <ol>
              <li>
                Click on the trash icon next to edit icon.

              </li>
              <div>
                <img style={{ width: "100%" }} src={Trash}></img>
              </div>

              <li>
                On the confirmation popup click yes.
              </li>
              <li>The child will be deleted from the account.
              </li>

              <div>
                <img style={{ width: "100%" }} src={Confirm}></img>
              </div>
            </ol>
          </div>
        </div>
        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Delete Device</h2>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to delete device</h2>
          <div className="fontStyles">
            <ol>
              <li>Click on the delete  icon next to the battery icon</li>
              <div>
                <img style={{ width: "100%" }} src={Delete}></img>
              </div>
              <li>On the displayed confirmation popup, click yes</li>
              <li>The device will be removed from the child.</li>
              <div>
                <img style={{ width: "100%" }} src={DeviceConfirm}></img>
              </div>
            </ol>
          </div>
        </div>
        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Clear All - Dashboard</h2>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to Clear all Child</h2>
          <div className="fontStyles">
            <ol>
              <li>Click on clear all at the top right of the screen.</li>
              <div>
                <img style={{ width: "100%" }} src={ClearChild}></img>
              </div>
              <li>On the confirmation popup, click yes.</li>
              <li>All the child and devices connected / created in the device will be cleared.</li>
              <div>
                <img style={{ width: "100%" }} src={ClearAll}></img>
              </div>
            </ol>
          </div>
        </div>
      </div>

      <div id="timescheduler">

        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Time Scheduler</h2>
          <ul className="fontStyles">
            <li>
              In Time scheduler, We will be able to block the apps for a certain period of time.
            </li>
            <li>
              Once the applications are selected the time limit along with the day / week for which that application need to blocked can be selected.

            </li>
            <li>
              Once saved the selected application will be blocked for that specific time limit.

            </li>
          </ul>
        </div>
        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps for Time Scheduler</h2>

          <div className="fontStyles">
            <ol>
              <li>Click on Time Scheduler.</li>
              <div>
                <img style={{ width: "100%" }} src={TimeSch}></img>
              </div>

              <li>
                In the screen click the + button.
              </li>
              <div>
                <img style={{ width: "100%" }} src={TimescheduleApp}></img>
              </div>
              <li>In the redirected screen select the applications you wish to block.
              </li>
              <li>Click continue.</li>

              <div>
                <img style={{ width: "100%" }} src={TimeScheduleBlock}></img>
              </div>
              <li>
                Select Start time and end time of the blocking time along with the days.
              </li>
              <div>
                <img style={{ width: "100%" }} src={Time}></img>
              </div>
              <li>
                On confirming the blocking, the application will be blocked for the fixed time.
              </li>
              <div>
                <img style={{ width: "100%" }} src={BlockTime}></img>
              </div>
            </ol>
          </div>
        </div>
        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Clear All Time Scheduler</h2>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to clear all Time scheduler</h2>
          <div className="fontStyles">
            <ol>
              <li>Click on clear all at the top right of the screen.</li>
              <div>
                <img style={{ width: "100%" }} src={ClearTime}></img>
              </div>
              <li>On the confirmation popup, click yes.</li>
              <div>
                <img style={{ width: "100%" }} src={Schedule}></img>
              </div>
              <li>All the schedulers scheduled will be removed from the list.</li>
              <div>
                <img style={{ width: "100%" }} src={TimeSchedule}></img>
              </div>
            </ol>
          </div>
        </div>

        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Update Time Scheduler</h2>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to Update Time Scheduler</h2>
          <div className="fontStyles">
            <ol>
              <li>Click on the Scheduled timer</li>
              <div>
                <img style={{ width: "100%" }} src={EditTime}></img>
              </div>
              <li>In the displayed screen make the changes..</li>
              <li>Click continue.
              </li>
              <div>
                <img style={{ width: "100%" }} src={UpdateTime}></img>
              </div>
              <li>Select Start time and end time of the blocking time along with the days.</li>

              <div>
                <img style={{ width: "100%" }} src={UpdateWeek}></img>
              </div>
              <li>The scheduler will be updated accordingly.</li>
              <div>
                <img style={{ width: "100%" }} src={UpdateSchedule}></img>
              </div>
            </ol>
          </div>
        </div>

        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Delete Time Scheduler</h2>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to Delete Time Scheduler</h2>
          <div className="fontStyles">
            <ol>
              <li>Click on the Scheduled timer</li>
              <div>
                <img style={{ width: "100%" }} src={EditTime}></img>
              </div>
              <li>In the displayed screen change all the blocked application to Allow.</li>

              <div>
                <img style={{ width: "100%" }} src={DeleteSch}></img>
              </div>
              <li>Click confirmation.</li>
              <div>
                <img style={{ width: "100%" }} src={Delpopup}></img>
              </div>
              <li>The scheduler will be deleted.</li>
              <div>
                <img style={{ width: "100%" }} src={TimeSchedule}></img>
              </div>
            </ol>
          </div>
        </div>

        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to Delete Time Scheduler</h2>
          <div className="fontStyles">
            <ol>
              <li>Click on the Scheduled timer</li>
              <div>
                <img style={{ width: "100%" }} src={BlockTime}></img>
              </div>
              <li>In the displayed screen click on the trash icon</li>

              <div>
                <img style={{ width: "100%" }} src={DeleteSchedule}></img>
              </div>
              <li>Click confirmation.</li>
              <div>
                <img style={{ width: "100%" }} src={Delpopup}></img>
              </div>
              <li>The scheduler will be deleted.</li>
              <div>
                <img style={{ width: "100%" }} src={TimeSchedule}></img>
              </div>
            </ol>
          </div>
        </div>


      </div>
      <div id="location">
        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Location</h2>
          <ul className="fontStyles">
            <li>
              Location is divided into four Subs.
            </li>
            <li>
              Geo Location
            </li>
            <li>
              Geo Fencing
            </li>
            <li>
              Drive Safe
            </li>
            <li>
              Child Map
            </li>
          </ul>
        </div>
        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to view Location Subs</h2>
          <ol className="fontStyles">
            <li>Click on the arrow to the right of the location.</li>
            <div>
              <img style={{ width: "100%" }} src={Location}></img>
            </div>
            <li>In the dropdown list you will be able to view the sub categories mentioned.</li>
            <div>
              <img style={{ width: "100%" }} src={LocationDrop}></img>
            </div>
          </ol>
        </div>
        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Location → Geo Location</h2>
          <div className="fontStyles">
            <ul>
              Under Geo location, we will be able to view the child’s location, wherever they have traveled and also can see the current location.
            </ul>

          </div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to view Geo Location</h2>
          <ol className="fontStyles">
            <li>Click on Geo location.</li>
            <div>
              <img style={{ width: "100%" }} src={GeoLocation}></img>
            </div>
            <li>You will be able to view the locations of the child along with the current location in the map.</li>
            <div>
              <img style={{ width: "100%" }} src={CurrentLoc}></img>
            </div>
            <li>Also you will be able to view the history of the locations at the right of the map.</li>
            <div>
              <img style={{ width: "100%" }} src={History}></img>
            </div>

          </ol>
        </div>
        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Location → Geo Fencing</h2>
          <div >
            <ul className="fontStyles">
              <li>The places where we want our child to be forbidden to be gone and the area where they are only allowed can be marked here</li>
              <li>On crossing those areas, the parent will be receiving SMS notifications as well as internal notification.</li>
            </ul>

            <div>
              <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to create Fence</h2>
              <ol className="fontStyles">
                <li>Click Add Geo fence</li>
                <div>
                  <img style={{ width: "100%" }} src={Geo}></img>
                </div>
                <li>Enter the name of the fence.</li>
                <li>Enter the name of the place</li>
                <li>Mark the fence area</li>
                <li>Click add.</li>
                <div>
                  <img style={{ width: "100%" }} src={Radius}></img>
                </div>
                <li>The list can be viewed in the respective screens.</li>
                <div>
                  <img style={{ width: "100%" }} src={Fences}></img>
                </div>

              </ol>
            </div>

            <div>
              <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to edit Fence</h2>
              <ol className="fontStyles">
                <li>Click the desired fence.</li>
                <div>
                  <img style={{ width: "100%" }} src={EditFence}></img>
                </div>
                <li>Make the changes</li>
                <div>
                  <img style={{ width: "100%" }} src={Geoupdate}></img>
                </div>
                <li>Click update</li>
                <div>
                  <img style={{ width: "100%" }} src={Afterupdate}></img>
                </div>

              </ol>
            </div>

            <div>
              <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to Delete Fence</h2>
              <ol className="fontStyles">
                <li>Click on the trash icon in the right of the fence.</li>
                <div>
                  <img style={{ width: "100%" }} src={FenceDelete}></img>
                </div>

                <li>The fence will be removed from the list</li>
                <div>
                  <img style={{ width: "100%" }} src={PlainFence}></img>
                </div>

              </ol>
            </div>
          </div>
        </div>

        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Location → Drive Safe</h2>
          <div >
            <ul className="fontStyles">
              <li>Under drive safe, you will be able to set the driving speed limit for the child.</li>
              <li>If the child exceeds the limit, notification will be received to the parent.</li>
            </ul>

            <div>
              <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to set speed limit</h2>
              <ol className="fontStyles">
                <li>Click on drive safe.</li>
                <div>
                  <img style={{ width: "100%" }} src={DriveSafe}></img>
                </div>
                <li>Set the speed limit</li>
                <li>Click Set Limit.</li>
                <div>
                  <img style={{ width: "100%" }} src={SetLimit}></img>
                </div>

              </ol>
            </div>

          </div>
        </div>

        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Location → Child Map</h2>
          <div >
            <ul className="fontStyles">
              <li>We will be able to locate all the child devices linked with that particular parent account. </li>
              <li>Every device will be visible in the map with their profile image.</li>
            </ul>

            <div>
              <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to view Child Map</h2>
              <ol className="fontStyles">
                <li>Click on Child Map.</li>
                <div>
                  <img style={{ width: "100%" }} src={ChildMap}></img>
                </div>
                <li>You will be able to view the children’s location along with their profile image in the Map.</li>
                <div>
                  <img style={{ width: "100%" }} src={ChildLocation}></img>
                </div>
              </ol>
            </div>

          </div>
        </div>

      </div>
      <div id="appfilter">
        <h2 style={{ color: "rgb(14, 86, 166)" }}>App Filtering</h2>
        <ul className="fontStyles">
          <li>
            We will be able to block any app for no desired time limit.
          </li>
          <li>
            Until we allow the application the child won't be able to use the application.
          </li>

        </ul>
        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps for app filtering</h2>
          <ol className="fontStyles">
            <li>Click on App filtering.</li>
            <div>
              <img style={{ width: "100%" }} src={AppFilter}></img>
            </div>
            <li>Select the app you want to block.</li>
            <div>
              <img style={{ width: "100%" }} src={AppBlock}></img>
            </div>
            <li>On confirming the app will be blocked.</li>
            <li>On clicking time scheduler, you will be redirected to time scheduler.</li>
            <div>
              <img style={{ width: "100%" }} src={BlockConfirm}></img>
            </div>
          </ol>
        </div>
      </div>

      <div id="webfilter">
        <h2 style={{ color: "rgb(14, 86, 166)" }}>Web Filtering</h2>
        <ul className="fontStyles">
          <li>
            We will be able to block any URL by either pasting it or from the history list.
          </li>
          <li> Until we allow the URL the child won't be able to use that URL. </li>

        </ul>
        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps for web filtering</h2>
          <ol className="fontStyles">
            <li>Click on Web filtering.</li>
            <div>
              <img style={{ width: "100%" }} src={WebFilter}></img>
            </div>
            <li>Select the URL you want to block / Paste the URL you want to block.</li>
            <div>
              <img style={{ width: "100%" }} src={WebUrl}></img>
            </div>
            <li>On confirming the URL will be blocked.</li>
            <div>
              <img style={{ width: "100%" }} src={WebConfirm}></img>
            </div>

          </ol>
        </div>
      </div>

      <div id="appusage">
        <h2 style={{ color: "rgb(14, 86, 166)" }}>App Usage</h2>
        <ul className="fontStyles">
          <li>We will be able to view the time spend by the child on each application.</li>
          <li>Also the overall summary for per day usage can also be seen.</li>

        </ul>
        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps for App usage</h2>
          <ol className="fontStyles">
            <li>Click on App usage.</li>
            <div>
              <img style={{ width: "100%" }} src={AppUsage}></img>
            </div>
            <li>The summary screen will be seen.</li>
            <div>
              <img style={{ width: "100%" }} src={PieChart}></img>
            </div>
          </ol>
        </div>
      </div>

      {/* <div id="callsms">
        <h2 style={{ color: "rgb(14, 86, 166)" }}>Call & SMS</h2>
        <ul className="fontStyles">
          <li>We will be able to view the calls and SMS received by the child.</li>
        </ul>
        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps for Call & SMS blocking</h2>
          <ol className="fontStyles">
            <li>Click Call & SMS.</li>
            <div>
              <img style={{ width: "100%" }} src={Call}></img>
            </div>
            <li>Click block.</li>
            <div>
              <img style={{ width: "100%" }} src={CallList}></img>
            </div>
            <li>On confirming that number will be blocked for both Call & SMS.</li>
            <div>
              <img style={{ width: "100%" }} src={CallAlert}></img>
            </div>
          </ol>
        </div>
      </div> */}

      <div id="settings">
        <h2 style={{ color: "rgb(14, 86, 166)" }}>Settings</h2>
        <ul className="fontStyles">
          <li>Inside settings we will be able to view the options like editing parent profile, Changing Password etc.</li>
          <li>We would be able to view them and be able to make changes under those options.</li>
          <div>
            <img style={{ width: "100%" }} src={Settings}></img>
          </div>
        </ul>
        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Edit Parent Profile</h2>
          <ul className="fontStyles">
            <li>We will be able to edit  the parents profile details.</li>

          </ul>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps for edit parent profile</h2>
          <ol className="fontStyles">
            <li>Select Edit Parent profile.</li>
            <div>
              <img style={{ width: "100%" }} src={EditProfile}></img>
            </div>
            <li>Make changes.</li>
            <div>
              <img style={{ width: "100%" }} src={ProfileEdit}></img>
            </div>
            <li>Enter the OTP received.</li>
            <li>The changes will be saved.</li>
            <div>
              <img style={{ width: "100%" }} src={EditVerify}></img>
            </div>
          </ol>

        </div>
        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Change Password</h2>
          <ul className="fontStyles">
            <li>The password of the profile can be changed with this option.</li>

          </ul>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to change password</h2>
          <ol className="fontStyles">
            <li>Click on Change password.</li>
            <div>
              <img style={{ width: "100%" }} src={ChangePsw}></img>
            </div>
            <li>Enter the details</li>
            <div>
              <img style={{ width: "100%" }} src={ChangePswForm}></img>
            </div>
            <li>The changes will be saved on confirming.</li>

          </ol>

        </div>
        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Contact us</h2>
          <ul className="fontStyles">
            <li>The Administrator can be contacted using this option.</li>

          </ul>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to Contact with us</h2>
          <ol className="fontStyles">
            <li>Click on Contact us.</li>
            <div>
              <img style={{ width: "100%" }} src={Contact}></img>
            </div>
            <li>Enter the details</li>
            <div>
              <img style={{ width: "100%" }} src={ContactForm}></img>
            </div>
            <li>On confirming a mail will be send to the administrator with your query.</li>

          </ol>

        </div>
        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Terms & Privacy Policy</h2>
          <ul className="fontStyles">
            <li>On Clicking which you will be able to view the terms and policies of the application.</li>
            <div>
              <img style={{ width: "100%" }} src={Terms}></img>
            </div>
          </ul>
        </div>
        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Help</h2>
          <ul className="fontStyles">
            <li>On Clicking which you will be able to view the list of features related to the application.</li>

          </ul>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Steps to Help</h2>
          <ol className="fontStyles">
            <li>Click on Help.</li>
            <div>
              <img style={{ width: "100%" }} src={Help}></img>
            </div>
            <li>On Clicking which you will be able to view the list of features related to the application.</li>
            <div>
              <img style={{ width: "100%" }} src={HelpDesk}></img>
            </div>
            <li>On clicking each, it redirects you to that page of the manual.</li>
            <li>On clicking Others it redirects to contact us.</li>

          </ol>

        </div>
      </div>

      <div id="notification">
        <h2 style={{ color: "rgb(14, 86, 166)" }}>Notification</h2>
        <ol className="fontStyles">
          <li>
            We will be able to view the notifications  received by the child.
          </li>

          <div>
            <img style={{ width: "100%" }} src={Notification}></img>
          </div>
          <li> Also by clicking Clear All at the top, we will be able to select and delete the notifications. </li>
          <div>
            <img style={{ width: "100%" }} src={NotificationClear}></img>
          </div>

        </ol>

      </div>
      <div id="logout">
        <h2 style={{ color: "rgb(14, 86, 166)" }}>Logout</h2>
        <ol className="fontStyles">
          <li>
            Click on Log out.
          </li>

          <div>
            <img style={{ width: "100%" }} src={Logout}></img>
          </div>
          <li> On the popup, confirm the action. </li>
          <li>You will be logged out of the profile.</li>
          <div>
            <img style={{ width: "100%" }} src={LogoutAlert}></img>
          </div>

        </ol>

      </div>
    </div>
  );
}
