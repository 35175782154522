import dashboard from './dashboard';
import pages from './pages';
import utilities from './utilities';
import other from './other';
import timer from './timeSchedule';
import devicemenu from './deviceType';
import child from './noChild';
import prouti from './proUtilities';
import proIosmenu from './prodeviceType';
import proNo from './proNoChild';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    iosItem: [devicemenu],
    items: [utilities],
    childItem : [child],
    proUti : [prouti],
    proiosItem: [proIosmenu],
    proNoItem: [proNo],
};

export default menuItems;
