import React, { useState } from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// @mui/material components
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const ClipBoard = (props) => {
  const [copiedText, setCopiedText] = useState();
  return (
    <>
      <Grid
        item
        lg={3}
        md={6}
        xs={12}
        component={Box}
        paddingLeft="15px"
        paddingRight="15px"
      >
        <CopyToClipboard
          text={props.content}
          onCopy={() => setCopiedText(props.content)}
        >
          <Tooltip
            title={
              copiedText === props.content
                ? "This was Copied!"
                : "Copy To Clipboard"
            }
            placement="top"
          >
            <Box
              component="button"
              fontFamily="inherit"
              fontSize="16px"
              fontWeight="400"
              lineHeight="1.25"
              display="inline-block"
              width="100%"
              backgroundColor={'#ffffc4'}
              margin=".5rem 0"
              padding="15px"
              textAlign="left"
              border="0"
              borderRadius="20px"
              data-clipboard-text="album-2"
              type="button"
            >
              <Grid container>
                <Grid item>
                  <ContentCopyIcon />
                </Grid>
                <Grid item sx={{p: '.2rem', width: '10rem'}}>
                 {props.content}
                </Grid>
              </Grid>
            </Box>
          </Tooltip>
        </CopyToClipboard>
      </Grid>
    </>
  );
};

export default ClipBoard;