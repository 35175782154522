import {
    GET_SCHEDULAR_LIST,
    GET_SCHEDULAR_LIST_SUCCESS,
    GET_SCHEDULAR_LIST_FAILED,

    GET_SCHEDULAR_APP_LIST,
    GET_SCHEDULAR_APP_LIST_SUCCESS,
    GET_SCHEDULAR_APP_LIST_FAILED,

    CREATE_SCHEDULE,
    CREATE_SCHEDULE_SUCCESS,
    CREATE_SCHEDULE_FAILED,

    LOC_STATE_RESET_COMPLETED,

    UPDATE_SCHEDULE,
    UPDATE_SCHEDULE_SUCCESS,
    UPDATE_SCHEDULE_FAILED,

    DELETE_SCHEDULE,
    DELETE_SCHEDULE_SUCCESS,
    DELETE_SCHEDULE_FAILED,

    CLEAR_SCHEDULE,
    CLEAR_SCHEDULE_SUCCESS,
    CLEAR_SCHEDULE_FAILED,

} from '../types';

export const initialState = {
    loading: false,
    dayEvents: [],
    errMessage: '',
    schedularAppsList: [],
    isSuccess: false,
    isUpdated: false,
    isDeleted: false,
    isClear: false,
};

const schedularReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SCHEDULAR_LIST:
            return { ...state, loading: true };
        case GET_SCHEDULAR_LIST_SUCCESS:
            return { ...state, loading: false, dayEvents: action.payload };
        case GET_SCHEDULAR_LIST_FAILED:
            return { ...state, loading: false, errMessage: action.payload };

        case GET_SCHEDULAR_APP_LIST:
            return { ...state, loading: true };
        case GET_SCHEDULAR_APP_LIST_SUCCESS:
            return { ...state, loading: false, schedularAppsList: action.payload };
        case GET_SCHEDULAR_APP_LIST_FAILED:
            return { ...state, loading: false, errMessage: action.payload };

        case CREATE_SCHEDULE:
            return { ...state, loading: true };
        case CREATE_SCHEDULE_SUCCESS:
            return { ...state, loading: false, isSuccess: true };
        case CREATE_SCHEDULE_FAILED:
            return { ...state, loading: false, errMessage: action.payload };

        case UPDATE_SCHEDULE:
            return { ...state, loading: true };
        case UPDATE_SCHEDULE_SUCCESS:
            return { ...state, loading: false, isUpdated: true };
        case UPDATE_SCHEDULE_FAILED:
            return { ...state, loading: false, errMessage: action.payload };

        case DELETE_SCHEDULE:
            return { ...state, loading: true };
        case DELETE_SCHEDULE_SUCCESS:
            return { ...state, loading: false, isDeleted: true };
        case DELETE_SCHEDULE_FAILED:
            return { ...state, loading: false, errMessage: action.payload };

        case LOC_STATE_RESET_COMPLETED:
            return { ...state, errMessage: '', isSuccess: false, isUpdated: false, isDeleted: false, isClear: false };

        case CLEAR_SCHEDULE:
            return { ...state, loading: true };
        case CLEAR_SCHEDULE_SUCCESS:
            return { ...state, loading: false, isClear: true };
        case CLEAR_SCHEDULE_FAILED:
            return { ...state, loading: false, errMessage: action.payload };

        default:
            return state;
    }
};

export default schedularReducer;
