import {
    LOAD_CHILDREN,
    LOAD_CHILDREN_SUCCESS,
    LOAD_CHILDREN_FAILURE,
    SET_SELECTED_CHILD,
    SET_SELECTED_DEVICE,
    ADD_CHILD,
    ADD_CHILD_SUCCESS,
    ADD_CHILD_FAILED,
    UPDATE_CHILD,
    UPDATE_CHILD_SUCCESS,
    UPDATE_CHILD_FAILED,
    DELETE_CHILD,
    DELETE_CHILD_SUCCESS,
    DELETE_CHILD_FAILED,
    CLEAR_CHILD,
    CLEAR_CHILD_SUCCESS,
    CLEAR_CHILD_FAILED,
    CHILDREN_STATE_RESET_COMPLETED,
    ADD_CHILD_IMAGE,
    ADD_CHILD_IMAGE_SUCCESS,
    ADD_CHILD_IMAGE_FAILED,
    ADD_PARENT_IMAGE,
    ADD_PARENT_IMAGE_SUCCESS,
    ADD_PARENT_IMAGE_FAILED,
    CHILDREN_DATA_RESET,
    LOAD_PLAN,
    LOAD_PLAN_SUCCESS,
    LOAD_PLAN_FAILURE,
    REAUTH_PSW,
    REAUTH_PSW_SUCCESS,
    REAUTH_PSW_FAILURE,
} from '../types';
import { postCall, putCall, getCall, deleteCall, postImage } from './api'

export const loadChildrenAction = () => (dispatch) => {
    dispatch({ type: LOAD_CHILDREN });
    getCall('/child', true).then(res => {
        dispatch({ type: LOAD_CHILDREN_SUCCESS, payload: res.data.data });
    }).catch(err => {
        dispatch({ type: LOAD_CHILDREN_FAILURE });
    })
};

export const updateChildAction = (childId, payload, updatedValue, enqueueSnackbar) => (dispatch) => {
    dispatch({ type: UPDATE_CHILD });
    putCall(`/child/${childId}`, payload, true).then(res => {
        enqueueSnackbar('Speed Limit Updated Successfully', {
            variant: "success",
        });
        dispatch({ type: UPDATE_CHILD_SUCCESS, payload: updatedValue });
    }).catch(err => {
        enqueueSnackbar('Failed to Update Speed Limit', {
            variant: "error",
        });
        dispatch({ type: UPDATE_CHILD_FAILED });
    })
};

export const setSelectedChildAction = (selectedChild) => (dispatch) => {
    dispatch({ type: SET_SELECTED_CHILD, payload: selectedChild });
};

export const setSelectedDeviceAction = (selectedDevice) => (dispatch) => {
    dispatch({ type: SET_SELECTED_DEVICE, payload: selectedDevice });
};

// export const addChildAction = (payload) => (dispatch) => {
//     dispatch({ type: ADD_CHILD });
//     postCall('/addChild', payload, true).then((res) => {
//         console.log('iddddd',res)
//         dispatch({ type: ADD_CHILD_SUCCESS,payload:res.data?.childId }); 
//     })
//     dispatch({ type: ADD_CHILD_FAILED });

// };


export const addChildAction = (payload) => async (dispatch) => {
    dispatch({ type: ADD_CHILD });
    postCall('/addChild', payload, true)
        .then((res) => {
            dispatch({ type: ADD_CHILD_SUCCESS, payload: res.data?.childId });
        })
        .catch((err) => {
            console.log(err, "errormsgrec")
            dispatch({ type: ADD_CHILD_FAILED, payload: err?.response?.data });
        });
};



export const deleteChildAction = (deviceId) => (dispatch) => {
    dispatch({ type: DELETE_CHILD });
    deleteCall(`/child/${deviceId}`, true).then((del) => {
        dispatch({ type: DELETE_CHILD_SUCCESS, payload: del?.data });
    })
    dispatch({ type: DELETE_CHILD_FAILED });
};

export const clearChildAction = (deviceId) => (dispatch) => {
    dispatch({ type: CLEAR_CHILD });
    deleteCall(`/child`, true).then((del) => {
        dispatch({ type: CLEAR_CHILD_SUCCESS, payload: del?.data });
    })
    dispatch({ type: CLEAR_CHILD_FAILED });
};


export const updateChildDetails = (childId, payload,e,enqueueSnackbar) => (dispatch) => {
    dispatch({ type: UPDATE_CHILD });
    putCall(`/child/${childId}`, payload, true).then((res) => {
        dispatch({ type: UPDATE_CHILD_SUCCESS, payload: res.data.data });
    }).catch(err => {
        enqueueSnackbar(err?.response?.data?.message, {
            variant: "error",
        });
        dispatch({ type: UPDATE_CHILD_FAILED });
    })
};

export const resetChildState = () => async (dispatch) => {
    dispatch({ type: CHILDREN_STATE_RESET_COMPLETED });
};


export const uploadChildImage = (payload) => (dispatch) => {
    dispatch({ type: ADD_CHILD_IMAGE });
    postImage(`/child/upload?userId=${payload?.id}`, payload?.file, true).then(() => {
        dispatch({ type: ADD_CHILD_IMAGE_SUCCESS });
    })
    dispatch({ type: ADD_CHILD_IMAGE_FAILED });
};

export const uploadParentImage = (payload) => (dispatch) => {
    dispatch({ type: ADD_PARENT_IMAGE });
    postImage(`/upload/profile?userId=${payload?.id}`, payload?.file, true).then(() => {
        dispatch({ type: ADD_PARENT_IMAGE_SUCCESS });
    })
    dispatch({ type: ADD_PARENT_IMAGE_FAILED });
};

export const resetChildData = () => async (dispatch) => {
    dispatch({ type: CHILDREN_DATA_RESET });
};

export const loadPlanAction = () => (dispatch) => {
    dispatch({ type: LOAD_PLAN });
    getCall('/subscription/subscribe', true).then(res => {
        dispatch({ type: LOAD_PLAN_SUCCESS, payload: res.data.data });
    }).catch(err => {
        dispatch({ type: LOAD_PLAN_FAILURE });
    })
};

export const reAuthAction = (payload) => async (dispatch) => {
    dispatch({ type: REAUTH_PSW });
    postCall('/safesns', payload, true)
        .then((res) => {
            dispatch({ type: REAUTH_PSW_SUCCESS, payload: res.data?.childId });
        })
        .catch((err) => {
            console.log(err, "errormsgrec")
            dispatch({ type: REAUTH_PSW_FAILURE, payload: err?.response?.data });
        });
};

