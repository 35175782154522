export const LOAD_CHILDREN = 'LOAD_CHILDREN';
export const LOAD_CHILDREN_SUCCESS = 'LOAD_CHILDREN_SUCCESS';
export const LOAD_CHILDREN_FAILURE = 'LOAD_CHILDREN_FAILURE';

export const SET_SELECTED_CHILD = 'SET_SELECTED_CHILD';
export const SET_SELECTED_DEVICE = 'SET_SELECTED_DEVICE';

export const ADD_CHILD = 'ADD_CHILD';
export const ADD_CHILD_SUCCESS = 'ADD_CHILD_SUCCESS';
export const ADD_CHILD_FAILED = 'ADD_CHILD_FAILED';

export const UPDATE_CHILD = 'UPDATE_CHILD';
export const UPDATE_CHILD_SUCCESS = 'UPDATE_CHILD_SUCCESS';
export const UPDATE_CHILD_FAILED = 'UPDATE_CHILD_FAILED';

export const ADD_DEVICE = 'ADD_DEVICE';
export const ADD_DEVICE_SUCCESS = 'ADD_DEVICE_SUCCESS';
export const ADD_DEVICE_FAILED = 'ADD_DEVICE_FAILED';

export const DEIVCE_DYNAMIC_DATA = 'DEIVCE_DYNAMIC_DATA';

export const REMOVE_DEVICE = 'REMOVE_DEVICE';
export const REMOVE_DEVICE_SUCCESS = 'REMOVE_DEVICE_SUCCESS';
export const REMOVE_DEVICE_FAILED = 'REMOVE_DEVICE_FAILED';

export const LOAD_GEOFENCE = 'LOAD_GEOFENCE';
export const LOAD_GEOFENCE_SUCCESS = 'LOAD_GEOFENCE_SUCCESS';
export const LOAD_GEOFENCE_FAILURE = 'LOAD_GEOFENCE_FAILURE';

export const GEO_LOCATION_LOG_COMPLETED = 'GEO_LOCATION_LOG_COMPLETED';
export const GEO_LOCATION_LOG_INPROGRESS = 'GEO_LOCATION_LOG_INPROGRESS';
export const GEO_LOCATION_LOG_FAILED = 'GEO_LOCATION_LOG_FAILED';

export const ADD_FENCE = 'ADD_FENCE';
export const ADD_FENCE_SUCCESS = 'ADD_FENCE_SUCCESS';
export const ADD_FENCE_FAILED = 'ADD_FENCE_FAILED';

export const GET_WEB_LOGS = 'GET_WEB_LOGS';
export const GET_WEB_LOGS_SUCCESS = 'GET_WEB_LOGS_SUCCESS';
export const GET_WEB_LOGS_FAILED = 'GET_WEB_LOGS_FAILED';

export const GET_CALL_LOGS = 'GET_CALL_LOGS';
export const GET_CALL_LOGS_SUCCESS = 'GET_CALL_LOGS_SUCCESS';
export const GET_CALL_LOGS_FAILED = 'GET_CALL_LOGS_FAILED';

export const GET_FAMILY_DETAILS = 'GET_FAMILY_DETAILS';
export const GET_FAMILY_DETAILS_SUCCESS = 'GET_FAMILY_DETAILS_SUCCESS';
export const GET_FAMILY_DETAILS_FAILED = 'GET_FAMILY_DETAILS_FAILED';

export const REMOVE_FENCE = 'REMOVE_FENCE';
export const REMOVE_FENCE_SUCCESS = 'REMOVE_FENCE_SUCCESS';
export const REMOVE_FENCE_FAILED = 'REMOVE_FENCE_FAILED';

export const LOC_STATE_RESET_COMPLETED = 'LOC_STATE_RESET_COMPLETED';

export const GET_BLOCKED_WEBSITES = 'GET_BLOCKED_WEBSITES';
export const GET_BLOCKED_WEBSITES_SUCCESS = 'GET_BLOCKED_WEBSITES_SUCCESS';
export const GET_BLOCKED_WEBSITES_FAILED = 'GET_BLOCKED_WEBSITES_FAILED';

export const GET_APPS = 'GET_APPS';
export const GET_APPS_SUCCESS = 'GET_APPS_SUCCESS';
export const GET_APPS_FAILED = 'GET_APPS_FAILED';

export const POST_BLOCK_WEBSITES = 'POST_BLOCK_WEBSITES';
export const POST_BLOCK_WEBSITES_SUCCESS = 'POST_BLOCK_WEBSITES_SUCCESS';
export const POST_BLOCK_WEBSITES_FAILED = 'POST_BLOCK_WEBSITES_FAILED';

export const POST_BLOCK_APPS = 'POST_BLOCK_APPS';
export const POST_BLOCK_APPS_SUCCESS = 'POST_BLOCK_APPS_SUCCESS';
export const POST_BLOCK_APPS_FAILED = 'POST_BLOCK_APPS_FAILED';

export const GET_NOTIFICATION = 'GET_NOTIFICATION';
export const GET_NOTIFICATION_SUCCESS = 'GET_NOTIFICATION_SUCCESS';
export const GET_NOTIFICATION_FAILED = 'GET_NOTIFICATION_FAILED';

export const FILTER_STATE_RESET_COMPLETED = 'FILTER_STATE_RESET_COMPLETED';

export const FB_WEB_BLOCK = 'FB_WEB_BLOCK';

export const GET_SCHEDULAR_LIST = 'GET_SCHEDULAR_LIST';
export const GET_SCHEDULAR_LIST_SUCCESS = 'GET_SCHEDULAR_LIST_SUCCESS';
export const GET_SCHEDULAR_LIST_FAILED = 'GET_SCHEDULAR_LIST_FAILED';

export const GET_SCHEDULAR_APP_LIST = 'GET_SCHEDULAR_APP_LIST';
export const GET_SCHEDULAR_APP_LIST_SUCCESS = 'GET_SCHEDULAR_APP_LIST_SUCCESS';
export const GET_SCHEDULAR_APP_LIST_FAILED = 'GET_SCHEDULAR_APP_LIST_FAILED';

export const CREATE_SCHEDULE = 'CREATE_SCHEDULE';
export const CREATE_SCHEDULE_SUCCESS = 'CREATE_SCHEDULE_SUCCESS';
export const CREATE_SCHEDULE_FAILED = 'CREATE_SCHEDULE_FAILED';

export const GET_ALL_APP_USAGE_LIST = 'GET_ALL_APP_USAGE_LIST';
export const GET_ALL_APP_USAGE_LIST_SUCCESS = 'GET_ALL_APP_USAGE_LIST_SUCCESS';
export const GET_ALL_APP_USAGE_LIST_FAILED = 'GET_ALL_APP_USAGE_LIST_FAILED';

export const UPDATE_SCHEDULE = 'UPDATE_SCHEDULE';
export const UPDATE_SCHEDULE_SUCCESS = 'UPDATE_SCHEDULE_SUCCESS';
export const UPDATE_SCHEDULE_FAILED = 'UPDATE_SCHEDULE_FAILED';

export const DELETE_SCHEDULE = 'DELETE_SCHEDULE';
export const DELETE_SCHEDULE_SUCCESS = 'DELETE_SCHEDULE_SUCCESS';
export const DELETE_SCHEDULE_FAILED = 'DELETE_SCHEDULE_FAILED';

export const GET_PROFILE_DETAILS = 'GET_PROFILE_DETAILS';
export const GET_PROFILE_DETAILS_SUCCESS = 'GET_PROFILE_DETAILS_SUCCESS';
export const GET_PROFILE_DETAILS_FAILED = 'GET_PROFILE_DETAILS_FAILED';

export const DEVICE_STATE_RESET_COMPLETED = 'DEVICE_STATE_RESET_COMPLETED';

export const POST_WEBSITE_TO_BLOCK = 'POST_WEBSITE_TO_BLOCK';
export const POST_WEBSITE_TO_BLOCK_SUCCESS = 'POST_WEBSITE_TO_BLOCK_SUCCESS';
export const POST_WEBSITE_TO_BLOCK_FAILED = 'POST_WEBSITE_TO_BLOCK_FAILED';

export const CHILDREN_STATE_RESET_COMPLETED = 'CHILDREN_STATE_RESET_COMPLETED';

export const CLEAR_SCHEDULE = 'CLEAR_SCHEDULE';
export const CLEAR_SCHEDULE_SUCCESS = 'CLEAR_SCHEDULE_SUCCESS';
export const CLEAR_SCHEDULE_FAILED = 'CLEAR_SCHEDULE_FAILED';

export const CLEAR_APP_FILTER = 'CLEAR_APP_FILTER';
export const CLEAR_APP_FILTER_SUCCESS = 'CLEAR_APP_FILTER_SUCCESS';
export const CLEAR_APP_FILTER_FAILED = 'CLEAR_APP_FILTER_FAILED';

export const DELETE_CHILD = 'DELETE_CHILD';
export const DELETE_CHILD_SUCCESS = 'DELETE_CHILD_SUCCESS';
export const DELETE_CHILD_FAILED = 'DELETE_CHILD_FAILED';

export const CLEAR_CHILD = 'CLEAR_CHILD';
export const CLEAR_CHILD_SUCCESS = 'CLEAR_CHILD_SUCCESS';
export const CLEAR_CHILD_FAILED = 'CLEAR_CHILD_FAILED';

export const UPDATE_PROFILE_DETAILS = 'UPDATE_PROFILE_DETAILS';
export const UPDATE_PROFILE_DETAILS_SUCCESS = 'UPDATE_PROFILE_DETAILS_SUCCESS';
export const UPDATE_PROFILE_DETAILS_FAILED = 'UPDATE_PROFILE_DETAILS_FAILED';

export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS';
export const DELETE_NOTIFICATION_FAILED = 'DELETE_NOTIFICATION_FAILED';

export const GENERAL_STATE_RESET_COMPLETED = 'GENERAL_STATE_RESET_COMPLETED';

export const ALL_DEVICE_DYNAMIC_DATA = 'ALL_DEVICE_DYNAMIC_DATA';

export const ADD_CHILD_IMAGE = 'ADD_CHILD_IMAGE';
export const ADD_CHILD_IMAGE_SUCCESS = 'ADD_CHILD_IMAGE_SUCCESS';
export const ADD_CHILD_IMAGE_FAILED = 'ADD_CHILD_IMAGE_FAILED';

export const ADD_PARENT_IMAGE = 'ADD_PARENT_IMAGE';
export const ADD_PARENT_IMAGE_SUCCESS = 'ADD_PARENT_IMAGE_SUCCESS';
export const ADD_PARENT_IMAGE_FAILED = 'ADD_PARENT_IMAGE_FAILED';

export const CHILDREN_DATA_RESET = 'CHILDREN_DATA_RESET';

export const DAILY_APP_USAGE_LIST = 'DAILY_APP_USAGE_LIST';
export const DAILY_APP_USAGE_LIST_SUCCESS = 'DAILY_APP_USAGE_LIST_SUCCESS';
export const DAILY_APP_USAGE_LIST_FAILED = 'DAILY_APP_USAGE_LIST_FAILED';


export const LOAD_PLAN = 'LOAD_PLAN';
export const LOAD_PLAN_SUCCESS = 'LOAD_PLAN_SUCCESS';
export const LOAD_PLAN_FAILURE = 'LOAD_PLAN_FAILURE';

export const REAUTH_PSW  = 'REAUTH_PSW';
export const REAUTH_PSW_SUCCESS = 'REAUTH_PSW_SUCCESS';
export const REAUTH_PSW_FAILURE = 'REAUTH_PSW_FAILURE';

export const GET_PARENT  = 'GET_PARENT';
export const GET_PARENT_SUCCESS = 'GET_PARENT_SUCCESS';
export const GET_PARENT_FAILED = 'GET_PARENT_FAILED ';

export const GET_ALL_NOTIFICATION  = 'GET_ALL_NOTIFICATION';
export const GET_ALL_NOTIFICATION_SUCCESS = 'GET_ALL_NOTIFICATION_SUCCESS';
export const GET_ALL_NOTIFICATION_FAILED = 'GET_ALL_NOTIFICATION_FAILED ';



