import React, { useState, useRef, useEffect } from "react";
import "./style.scss";
import AgentWindows from "assets/windows-manual/agent-windows.png";
import Setup from "assets/windows-manual/a2.png";
import SetupLocal from "assets/windows-manual/a3.png";
import Setupa4 from "assets/windows-manual/a4.png";
import Dashboard from "assets/windows-manual/a5.png";
import A6 from "assets/windows-manual/a6.png";
import A7 from "assets/windows-manual/a7.png";
import A8 from "assets/windows-manual/a8.png";
import A9 from "assets/windows-manual/a9.png";
import A10 from "assets/windows-manual/a10.png";
import A11 from "assets/windows-manual/a11.png";
import A12 from "assets/windows-manual/a12.png";
import A13 from "assets/windows-manual/a13.png";
import A14 from "assets/windows-manual/a14.png";
import A15 from "assets/windows-manual/a15.png";
import A16 from "assets/windows-manual/a16.png";
import A17 from "assets/windows-manual/a17.png";
import A18 from "assets/windows-manual/a18.png";
import A19 from "assets/windows-manual/a19.png";
import A20 from "assets/windows-manual/a20.png";
import A21 from "assets/windows-manual/a21.png";
import A22 from "assets/windows-manual/a22.png";
import A23 from "assets/windows-manual/a23.png";
import A24 from "assets/windows-manual/a24.png";
import { Helmet } from "react-helmet";
import Agent1 from "assets/windows-manual/agent1.png";
import Agent2 from "assets/windows-manual/agent2.png";
import Agent3 from "assets/windows-manual/agent3.png";
import Agent4 from "assets/windows-manual/agent4.png";


export default function Windowmanual() {

  return (
    <div className="windows-container container-fluid">
      <Helmet>
        <title>Cyber Safe Family - Windows Registration Manual</title>
        <meta name="description" content="Nested component" />
      </Helmet>
      <div>
        <h1 style={{ color: "rgb(14, 86, 166)", textAlign: 'center' }}>Cyber Safe Family Windows Setup Guide</h1>
       

        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Windows Agent Installation</h2>

          <div className="fontStyles">
          <ol>
              <li>Log into your filter.cybersafefamily.com account using your email/login and password.
              </li>
             
              <br></br>
            
              <li>Make sure your current Windows user account has Administrator rights. Run the <b>.exe</b> file and install the Agent on your computer following the program prompts.
                <br></br>  If you see a Windows SmartScreen alert, click <b>More options - Run anyway</b> to start the installation.</li>
              <br></br>
              <div>
                <img style={{ width: "499", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Agent1}></img>
              </div>
              <br></br>
              <div>
                <img style={{ width: "499", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Agent2}></img>
              </div>
              <br></br>
              <div>
                <img style={{ width: "499", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Agent3}></img>
              </div>
              <br></br>
              <div>
                <img style={{ width: "499", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Agent4}></img>
              </div>

            </ol>
            After the successful installation, the Agent will be launched automatically.
          </div>
        </div>
        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Agent Unattended Installation</h2>
          <p className="fontStyles">
            You can install the Agent in an unattended automatic way. This allows you to automatically install and launch the service, manage the filtering policies and collect web traffic statistics on several individual computers within your network. Learn how to install the Agent in unattended mode.
          </p>

        </div>
        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Getting Started with Agent</h2>
          <p className="fontStyles">
            Log in to the Agent using your email/login and password. <br></br>
            If you forgot your password, click  <b>"Forgot my password"</b>.
          </p>
          <div>
            <img style={{ width: "80%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={Dashboard}></img>
          </div>
          <br></br>
          <p className="fontStyles">
            After successful login, the <b>Home page</b> of the program will be shown.
            This page contains:
          </p>
          <ul className="fontStyles">
            <li>
              Current status of the filtering service in the center.
            </li>
            <li>
              Active filtering policy at the top.
            </li>
            <li>
              On/Off filtering slider button at the bottom.
            </li>
          </ul>
          <div>
            <img style={{ width: "80%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={A6}></img>
          </div>
          <p className="fontStyles">You can temporarily shut down the filtering by clicking on the slider button at the bottom of the window. You will be asked for a password if the settings are password protected.</p>
          <div>
            <img style={{ width: "80%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={A7}></img>
          </div>
        </div>


        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Content Filtering Settings</h2>
          <p className="fontStyles">
            To set up your filtering rules go to the <b>Content filtering settings</b> section.
          </p>
          <div>
            <img style={{ width: "80%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={A8}></img>
          </div>
          <p className="fontStyles">
            <b>!Please note that settings take 5-7 minutes to apply.</b> <br></br>
            In some cases, you will need to clear the cache of your browser.

          </p>
          <p className="fontStyles">If you have more than one filtering policy, you can select the needed one at the top of the window (paid plans only). Click on the <b>Plus</b>  button to proceed to the <b>Policy</b> section where you can create a new policy.</p>
          <div>
            <img style={{ width: "80%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={A9}></img>
          </div>
          <p className="fontStyles">In the <b>Categories</b>  tab, you can see a full list of content categories of your current filtering policy. In this tab, you can block or allow any of them. The red cross indicates blocked categories and the green checkmark indicates allowed categories. You can also block or allow an entire group of categories by clicking on the group name. The gray cross near a group name means that some categories are blocked and some of them are allowed.</p>
          <div>
            <img style={{ width: "80%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={A10}></img>
          </div>
          <br></br>
          <p className="fontStyles">In the <b>Exceptions </b>tab, you can create Allow/Denylists for individual websites (domains). Domains in Denylist will always be blocked even if the category of these domains is allowed. Domains in Allowlist will always be accessible even if the category of these domains is blocked.
            To block a group of subdomains, add the main domain (e.g. google.com) to the Denylist and all subdomains on this domain will be blocked (drive.google.com, translate.google.com, photos.google.com, etc).</p>
          <div>
            <img style={{ width: "80%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={A11}></img>
          </div>
          <br></br>
          <p className="fontStyles">You can also create Named Allow/Denylists and apply them to one or several different policies (this option is available in <b>Dashboard</b> only). Learn more about how to create named lists.</p>
          <br></br>
          <p className="fontStyles">For the correct performance of your Internet Service Provider’s local resources such as media archives, online TV services, or your company’s local resources such as file share servers, printers, etc., add a local domain and IP address of this domain in the <b>Aliases</b> tab.</p>
          <div>
            <img style={{ width: "80%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={A12}></img>
          </div>
          <p className="fontStyles">You can manage additional settings such as <b> Force Safe Search, Force Restricted Mode for YouTube,</b> and <b>Use the Allowlist only</b>  in the <b>Additional settings</b> tab.</p>
          <div>
            <img style={{ width: "80%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={A13}></img>
          </div>
          <p className="fontStyles">!All domains which are not added to the Allowlist will be blocked when <b>Use the Allow List only</b> option is active.</p>

        </div>

        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Creating Additional Filtering Policies</h2>
          <p className="fontStyles">
            The <b>Default</b> filtering policy cannot be deleted.
            You can also create additional policies (paid plans only) and use them to apply different filtering policies for:
          </p>
          <ul className="fontStyles">
            <li>a different user accounts on the same PC,</li>
            <li>different PCs with the Agent installed.</li>
          </ul>

          <p className="fontStyles">
            To create a new filtering policy follow these steps:

          </p>
          <ol className="fontStyles">
            <li>Go to the <b>Policy</b> section on the <b>Settings</b>  page.</li>

            <br></br>
            <div>
              <img style={{ width: "80%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={A14}></img>
            </div>
            <br></br>
            <li>Type the name of the new policy in the <b>Create policy</b> field. After that, click the <b>Plus</b>  button on the right to create a policy.</li>
          </ol>
          <div>
            <img style={{ width: "80%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={A15}></img>
          </div>
          <br></br>
          <p className="fontStyles">Now you can change settings for a new filtering policy in <b>Settings - Content filtering.</b>  You can use the <b>Cogwheel</b> icon near the policy’s name, to open the <b> Content filtering settings </b>of this policy.</p>

          <p className="fontStyles">To delete a policy, press the <b>Cross</b> icon near the policy’s name.</p>
          <br></br>
          <p className="fontStyles">You can set up one of your policies as active in the <b>Active policies</b>  dropdown menu.</p>
          <div>
            <img style={{ width: "80%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={A16}></img>
          </div>
        </div>


        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Binding Local Users to the Filtering Policies</h2>
          <p className="fontStyles">
            If you have several filtering policies, you can bind local users on your PC to these policies. The selected policy will be applied to the selected Windows user after they log into the system.
          </p>

          <p className="fontStyles">
            To bind a user to a filtering policy, go to the <b>Local users</b> tab in the <b>Policy</b>  section. Check the <b>Bind local users to policies</b>  box to activate binding.

          </p>
          <p className="fontStyles">Choose the necessary Windows user in the <b>User</b>  dropdown menu. Then select the preferred policy from the <b>Policy</b>  dropdown menu and press the<b>Plus</b>  icon.</p>

          <p className="fontStyles">To unbind a Windows user from a policy, press the <b>Cross</b>  icon near the user’s name.</p>
          <p className="fontStyles">To turn off local users’ binding, uncheck the <b>Bind local users to policies</b> box.</p>
          <div>
            <img style={{ width: "80%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={A17}></img>
          </div>

        </div>

        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Filtering Schedule</h2>
          <p className="fontStyles">
            Additional policies can be used for a filtering Schedule. It allows you to apply different settings or limit web access at certain time periods in a single day. Schedule configuration is available in the <b>Dashboard </b>  on the website only. Learn how to set up a filtering schedule.
          </p>

        </div>


        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Password Protection</h2>
          <p className="fontStyles">
            Actions such as changing settings, Agent uninstall, filtering suspension, and policy changing, are by default protected by your password.
          </p>
          <div>
            <img style={{ width: "80%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={A18}></img>
          </div>
          <p className="fontStyles">
            Password protection can be deactivated in <b>Settings - Common settings.</b>  To turn off password protection, uncheck <b>Protect settings with password</b>box. We recommend it only if you're the sole user of your computer. You can also manage additional settings, such as <b>Show Agent icon in the system tray,</b> and <b>Automatically check for updates.</b>
          </p>
          <div>
            <img style={{ width: "80%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={A19}></img>
          </div>

        </div>

        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Agent's Tray Icon Options</h2>
          <p className="fontStyles">
            You can use <b>Agent’s tray icon</b>to stop filtering or change the active policy.
            Right-click on it and choose the necessary action.
          </p>
          <div>
            <img style={{ width: "182", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={A20}></img>
          </div>
          <p className="fontStyles">
            You can temporarily switch the filtering off for 10 minutes, 30 minutes, 1 hour, and until start or reboot.
          </p>
          <div>
            <img style={{ width: "325", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={A21}></img>
          </div>
          <p className="fontStyles">
            You can also change the active filtering policy from the tray icon.
          </p>
          <div>
            <img style={{ width: "325", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={A22}></img>
          </div>
          <p className="fontStyles">
            If your settings are password-protected, you will be asked to enter the password.
          </p>
          <p className="fontStyles">
            To hide Agent's icon in the system tray uncheck the <b>Show Agent icon in the system tray</b> box in <b> Settings - Common settings.</b>
          </p>
          <div>
            <img style={{ width: "80%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={A23}></img>
          </div>

        </div>

        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>If You Have Issues</h2>
          <p className="fontStyles">
            If you have issues with the service or the Agent, feel free to contact our technical support.
            You can find our contacts on the <b>Help page</b> in the Agent.
            The <b>Get support</b> button will redirect you to the support form on the website.
            The <b>Check for updates</b> button allows you to automatically download the most recent version of the Agent.
          </p>
          <div>
            <img style={{ width: "80%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={A24}></img>
          </div>
          <p className="fontStyles">
            If Support asks you to send a system report, you can get it in the Agent. To get the report click the <b> System information</b> button on the <b>Help page.</b>  This operation can take a few minutes. After that, copy the report and send it to the Support team via the feedback form.
          </p>

          <p className="fontStyles"> <b>
            !Please note that settings take 5-7 minutes to apply. <br></br>
            !Stats and filtering status update hourly.
          </b>
          </p>



        </div>
      </div>

    </div>
  );
}
