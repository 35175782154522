import React, { useState, useRef, useEffect } from "react";
import "./style.scss";



import L1 from "assets/windows-manual/L1.png";
import L2 from "assets/windows-manual/L2.png";
import L3 from "assets/windows-manual/L3.png";
import L4 from "assets/windows-manual/L4.png";
import L5 from "assets/windows-manual/L5.png";
import L6 from "assets/windows-manual/L6.png";
import L7 from "assets/windows-manual/L7.png";
import L8 from "assets/windows-manual/L8.png";
import L9 from "assets/windows-manual/L9.png";
import L10 from "assets/windows-manual/L10.png";
import L11 from "assets/windows-manual/L11.png";
import L12 from "assets/windows-manual/L12.png";
import L13 from "assets/windows-manual/L13.png";
import L14 from "assets/windows-manual/L14.png";
import L15 from "assets/windows-manual/L15.png";
import L16 from "assets/windows-manual/L16.png";
import { Helmet } from "react-helmet";

export default function Linuxmanual() {

  return (
    <div className="linux-container container-fluid">
      <Helmet>
        <title>Cyber Safe Family - Linux Installation Manual</title>
        <meta name="description" content="Nested component" />
      </Helmet>
      <div>
        <h1 style={{ color: "rgb(14, 86, 166)", textAlign: 'center' }}>Cyber Safe Family Linux Setup Guide (IPv4)</h1>
        <div>
          <br></br>
          <p className="fontStyles">
            Installation requirements: Debian 10-11, Ubuntu 18–21, PopOS, Fedora, CentOS.
          </p>
          <p className="fontStyles">
            Requires the following billing plans: <b>Safe@Home, Safe@Office.</b>
          </p>

        </div>
        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Getting Started</h2>

          <div className="fontStyles">

            <ol>
              <li>Log in to your account with your registration email and password.</li>
              <li>Navigate to the <b>Getting Started</b> tab on the main page of the<b>Dashboard</b>  and select the Linux button. Choose and download the needed package: <b>.rpm</b> or <b>.deb.</b> </li>
              <br></br>
              <div>
                <img style={{ width: "50%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={L1}></img>
              </div>
            </ol>
          </div>
        </div>

        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>.rpm package</h2>
          <div className="fontStyles">

            <p>Use the following command for the installation from the Terminal app:</p>
            <p>sudo rpm -Uvh /home/user/Downloads/safedns-agent-1.2.1-x86_64.rpm</p>
            <p>Please note, that the path to the file and/or package name might be different.</p>
          </div>
        </div>
        <br></br>
        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>.deb package</h2>
          <div className="fontStyles">

            <p><b>!For experienced users.</b> You can use this command to install from the Terminal app:</p>
            <p>sudo dpkg -i /home/user/Downloads/safedns-agent-1.3.0-x86_64.deb</p>
            <p>Please note, that the path to the file and/or package name might be different.</p>
          </div>
        </div>
        <ol className="fontStyles">

          <li>Open the “Downloads” folder:</li>
          <br></br>
          <div>
            <img style={{ width: "80%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={L2}></img>
          </div>
          <br></br>
          <li>Run the downloaded file and install the Agent. Enter the Admin password if prompted.</li>
          <br></br>
          <div>
            <img style={{ width: "80%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={L3}></img>
          </div>
          <br></br>
          <li>You will see the following window once the installation finishes:</li>
          <br></br>
          <div>
            <img style={{ width: "80%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={L4}></img>
          </div>
        </ol>

        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Agent Setup</h2>
          <div className="fontStyles">

            <ol>
              <li>
                <b>Restart your system.</b>The Agent icon will appear in the system tray.
              </li>
              <li>Open the Agent by clicking on the icon in the system tray.</li>
              <div>
                <img style={{ width: "40%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={L5}></img>
              </div>
              <br></br>
              !On <b>Debian 9,</b>click on the black line in the bottom right corner, if the tray icon is hidden.
              <br>
              </br>
              <div>
                <img style={{ width: "40%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={L6}></img>
              </div>
              <br></br>
              <li>Enter your account credentials in the opened window.</li>
              <br></br>
              <div>
                <img style={{ width: "100%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={L7}></img>
              </div>
              <br></br>
              <li>Set up the security PIN that will be used later to restrict access to the Agent:</li>
              <br></br>
              <div>
                <img style={{ width: "50%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={L8}></img>
              </div>
              <br></br>
              <li>Enter the PIN once again to sign in to the Agent:</li>
              <br></br>
              <div>
                <img style={{ width: "50%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={L9}></img>
              </div>
            </ol>
          </div>
        </div>

        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Agent Overview</h2>
          <div className="fontStyles">
            <p>The main window of the Agent. Here you can view your account information, current IP address, your Billing Plan, and Subscription expiration date.</p>
            <p>Use the Policy menu to view and change the current filtering Policy.</p>
            <br></br>
            <div>
              <img style={{ width: "100%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={L10}></img>
            </div>
            <br></br>
            The system information menu shows brief information about the Agent, current filtering policies, and the network interfaces. The information in this menu can be copied to the clipboard by clicking the "Copy to clipboard" button.
            <br></br>
            <div>
              <img style={{ width: "100%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={L11}></img>
            </div>
            <br></br>
            <p>The Debug menu displays the results of the diagnostic commands that are required in case of troubleshooting. To send the debug information, click the "Send report" button.</p>
            <br></br>
            <div>
              <img style={{ width: "100%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={L15}></img>
            </div>
          </div>
        </div>

        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Additional settings</h2>
          <div className="fontStyles">
            <p>To make sure the Agent was installed correctly, please navigate to the <b>"Settings"</b> tab in the Dashboard and scroll down to the bottom.</p>
            <p>If you see the record with the Device name and your IP address, it means that the filtering is working.</p>
            <br></br>
            <div>
              <img style={{ width: "70%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={L13}></img>
            </div>
            <br></br>
            After that, you can adjust the filtering Policy according to your needs. You can select categories to block
            <br></br>
            <b>Don't forget to click the "Save changes" button.</b>
            <br></br>
            <div>
              <img style={{ width: "70%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={L14}></img>
            </div>
            <br></br>
            <p>The setup is complete!</p>
            <p>A blocked website will display an error message that the Access is closed:</p>
            <br></br>
            <div>
              <img style={{ width: "70%", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={L16}></img>
            </div>
            <br></br>
            <p>If the filtering doesn't work according to your policy settings, clear the cache of your browser.</p> <br></br>
            <b>!Please note, that settings take 5-7 minutes to apply.</b> <br></br>
            <b>!Stats and filtering status update hourly.</b>
          </div>
        </div>

        <div>
          <h2 style={{ color: "rgb(14, 86, 166)" }}>Uninstallation</h2>
          <div className="fontStyles">

            <p>For the <b> .rpm </b>package, use the following command:</p>
            <p>sudo rpm -e agentsafedns</p>
            <p>For the <b>.deb</b> package, use the following command:</p>
            <p>sudo apt-get remove agentsafedns</p>
            <p>Enter<b>"y"</b>  if prompted to confirm the Agent removal.</p>
          </div>
        </div>

      </div>

    </div>
  );
}
